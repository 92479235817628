import React, { useState, } from "react";
import { Box, TextField, RadioGroup, MenuItem, Select, FormHelperText, Radio, FormGroup, FormControl, FormLabel, FormControlLabel, Container, Checkbox, Typography, Card, Divider, Button, } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { updateRegistrationState } from '../../store/reducers/registrationState'
import {
  updateRegistrationAnswers,
  updateRegistrationAnswersOrganizationPhoneNumber, updateRegistrationAnswersOrganizationEmail, updateRegistrationAnswersOrganizationName,
  updateRegistrationAnswersFbTag, updateRegistrationAnswersIgTag, updateRegistrationAnswersOptOut, updateRegistrationAnswersFbHandle,
  updateRegistrationAnswersIgHandle, updateRegistrationAnswersTeamBrag, updateRegistrationAnswersNumOfCoaches, updateRegistrationAnswersNumOfTeams,
  updateRegistrationAnswersCoachFirstName1, updateRegistrationAnswersCoachLastName1, updateRegistrationAnswersCoachFirstName2, updateRegistrationAnswersCoachLastName2
} from '../../store/reducers/registrationAnswers'





const RegistrationFormOrgInfo = () => {


  const registrationState = useSelector(state => state.registrationState)
  const registrationAnswers = useSelector(state => state.registrationAnswers)
  const dispatch = useDispatch();


  const totalIndexes = 2;


  const updateNumber = (input) => {
    const phoneNumber = input.target.value
    dispatch(updateRegistrationAnswersOrganizationPhoneNumber(phoneNumber))
  }

  const updateName = (input) => {
    const newName = input.target.value
    dispatch(updateRegistrationAnswersOrganizationName(newName))
  }
  const updateEmail = (input) => {
    const newEmail = input.target.value
    dispatch(updateRegistrationAnswersOrganizationEmail(newEmail))
  }

  const [phoneNumber, setPhoneNumber] = useState(registrationAnswers.organizationPhoneNumber)

  const nextHandler = () => {
    dispatch(updateRegistrationState(registrationState.value + 1))
  }

  const previousHandler = () => {
    dispatch(updateRegistrationState(registrationState.value - 1))
  }


  const conditionalFieldRender = () => {
    if (registrationState.value === 0) {
      return (
        <Box sx={{  display: 'flex',  flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant="h6" component="h2" gutterBottom>Name of Basketball Club</Typography>
          <TextField required label='Organization Name' variant='outlined' margin={'normal'} value={registrationAnswers.orginizationName} onChange={(event) => updateName(event)} />
        </Box>
      )
    } else if (registrationState.value === 1) {
      return (
        <Box sx={{  display: 'flex',  flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant="h6" component="h2" gutterBottom>Email Address for Contact</Typography>
          <TextField required label='Organization Email' variant='outlined' margin={'normal'} fullWidth={false} value={registrationAnswers.organizationEmail} onChange={(event) => updateEmail(event)} />
        </Box>
      )
    } else if (registrationState.value === 2) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column',  flex: 1, alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant="h6" component="h2" gutterBottom>Phone Number for Contact</Typography>
          <TextField required label='Phone Number' variant='outlined' margin={'normal'} type='tel' value={registrationAnswers.organizationPhoneNumber} onChange={(event) => updateNumber(event)} />
        </Box>
      )
    }
  }

  return ( 
    <Box sx={{ display: 'flex',   height: '100%',
      width: '100%', boxSizing: 'border-box',
    }}>
      <Container maxWidth='sm' sx={{  
        display: 'flex', flexDirection: 'column', width: '100%',
        height: '100%', alignItems: 'center',  justifyContent: 'space-between', 
      }} >
        {conditionalFieldRender()}


        <FormGroup sx={{  width: '100%', marginTop: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} row>
          {registrationState.value !== 0 ? <Button onClick={previousHandler} variant="outlined" sx={{ marginRight: 1, }} >Previous</Button> : null}
          <Button onClick={nextHandler} variant="contained" sx={{ marginLeft: 1 }} >Next</Button>
        </FormGroup>


      </Container>
    </Box>
  )
}


export default RegistrationFormOrgInfo;
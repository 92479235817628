import { createSlice } from '@reduxjs/toolkit'

export const team10PlayerInfoSlice = createSlice({
  name: 'team10PlayerInfo',
  initialState: {
    team10Player1FirstName: '',
    team10Player1LastName: '',
    team10Player2FirstName: '',
    team10Player2LastName: '',
    team10Player3FirstName: '',
    team10Player3LastName: '',
    team10Player4FirstName: '',
    team10Player4LastName: '',
    team10Player5FirstName: '',
    team10Player5LastName: '',
    team10Player6FirstName: '',
    team10Player6LastName: '',
    team10Player7FirstName: '',
    team10Player7LastName: '',
    team10Player8FirstName: '',
    team10Player8LastName: '',
    team10Player9FirstName: '',
    team10Player9LastName: '',
    team10Player10FirstName: '',
    team10Player10LastName: '',
    team10Player11FirstName: '',
    team10Player11LastName: '',
    team10Player12FirstName: '',
    team10Player12LastName: '',
    team10Player13FirstName: '',
    team10Player13LastName: '',
    team10Player14FirstName: '',
    team10Player14LastName: '',
    team10Player15FirstName: '',
    team10Player15LastName: '',
    team9Player16FirstName: '',
    team9Player16LastName: '',
    team9Player17FirstName: '',
    team9Player17LastName: '',
    team9Player18FirstName: '',
    team9Player18LastName: '',
    team9Player19FirstName: '',
    team9Player19LastName: '',
    team9Player20FirstName: '',
    team9Player20LastName: '',


  },
  reducers: {
    updateTeam10PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam10Player1FirstName: (state, action) => {
      state.team10Player1FirstName = action.payload
    },
    updateTeam10Player1LastName: (state, action) => {
      state.team10Player1LastName = action.payload
    },
    updateTeam10Player2FirstName: (state, action) => {
      state.team10Player2FirstName = action.payload
    },
    updateTeam10Player2LastName: (state, action) => {
      state.team10Player2LastName = action.payload
    },
    updateTeam10Player3FirstName: (state, action) => {
      state.team10Player3FirstName = action.payload
    },
    updateTeam10Player3LastName: (state, action) => {
      state.team10Player3LastName = action.payload
    },
    updateTeam10Player4FirstName: (state, action) => {
      state.team10Player4FirstName = action.payload
    },
    updateTeam10Player4LastName: (state, action) => {
      state.team10Player4LastName = action.payload
    },
    updateTeam10Player5FirstName: (state, action) => {
      state.team10Player5FirstName = action.payload
    },
    updateTeam10Player5LastName: (state, action) => {
      state.team10Player5LastName = action.payload
    },
    updateTeam10Player6FirstName: (state, action) => {
      state.team10Player6FirstName = action.payload
    },
    updateTeam10Player6LastName: (state, action) => {
      state.team10Player6LastName = action.payload
    },
    updateTeam10Player7FirstName: (state, action) => {
      state.team10Player7FirstName = action.payload
    },
    updateTeam10Player7LastName: (state, action) => {
      state.team10Player7LastName = action.payload
    },
    updateTeam10Player8FirstName: (state, action) => {
      state.team10Player8FirstName = action.payload
    },
    updateTeam10Player8LastName: (state, action) => {
      state.team10Player8LastName = action.payload
    },
    updateTeam10Player9FirstName: (state, action) => {
      state.team10Player9FirstName = action.payload
    },
    updateTeam10Player9LastName: (state, action) => {
      state.team10Player9LastName = action.payload
    },
    updateTeam10Player10FirstName: (state, action) => {
      state.team10Player10FirstName = action.payload
    },
    updateTeam10Player10LastName: (state, action) => {
      state.team10Player10LastName = action.payload
    },
    updateTeam10Player11FirstName: (state, action) => {
      state.team10Player11FirstName = action.payload
    },
    updateTeam10Player11LastName: (state, action) => {
      state.team10Player11LastName = action.payload
    },
    updateTeam10Player12FirstName: (state, action) => {
      state.team10Player12FirstName = action.payload
    },
    updateTeam10Player12LastName: (state, action) => {
      state.team10Player12LastName = action.payload
    },
    updateTeam10Player13FirstName: (state, action) => {
      state.team10Player13FirstName = action.payload
    },
    updateTeam10Player13LastName: (state, action) => {
      state.team10Player13LastName = action.payload
    },
    updateTeam10Player14FirstName: (state, action) => {
      state.team10Player14FirstName = action.payload
    },
    updateTeam10Player14LastName: (state, action) => {
      state.team10Player14LastName = action.payload
    },
    updateTeam10Player15FirstName: (state, action) => {
      state.team10Player15FirstName = action.payload
    },
    updateTeam10Player15LastName: (state, action) => {
      state.team10Player15LastName = action.payload
    },
    updateTeam10Player16FirstName: (state, action) => {
      state.team10Player16FirstName = action.payload
    },
    updateTeam10Player16LastName: (state, action) => {
      state.team10Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam10Team1PlayerInfo,
  updateTeam10Player1FirstName,
  updateTeam10Player1LastName,
  updateTeam10Player2FirstName,
  updateTeam10Player2LastName,
  updateTeam10Player3FirstName,
  updateTeam10Player3LastName,
  updateTeam10Player4FirstName,
  updateTeam10Player4LastName,
  updateTeam10Player5FirstName,
  updateTeam10Player5LastName,
  updateTeam10Player6FirstName,
  updateTeam10Player6LastName,
  updateTeam10Player7FirstName,
  updateTeam10Player7LastName,
  updateTeam10Player8FirstName,
  updateTeam10Player8LastName,
  updateTeam10Player9FirstName,
  updateTeam10Player9LastName,
  updateTeam10Player10FirstName,
  updateTeam10Player10LastName,
  updateTeam10Player11FirstName,
  updateTeam10Player11LastName,
  updateTeam10Player12FirstName,
  updateTeam10Player12LastName,
  updateTeam10Player13FirstName,
  updateTeam10Player13LastName,
  updateTeam10Player14FirstName,
  updateTeam10Player14LastName,
  updateTeam10Player15FirstName,
  updateTeam10Player15LastName,
  updateTeam10Player16FirstName,
  updateTeam10Player16LastName,

} = team10PlayerInfoSlice.actions
export default team10PlayerInfoSlice.reducer
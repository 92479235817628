import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    displayName: '',
    email: '',
    phoneNumber: '',
    photoUrl: '',
    emailVerified: false,
    uid: '',
  },
  reducers: {
    updateDisplayName: (state, action) => {
      state.displayName = action.payload
    },
    updateEmail: (state, action) => {
      state.email = action.payload
    },
    updatePhoneNumber: (state, action) => {
      state.phoneNumber = action.payload
    },
    updatePhotoUrl: (state, action) => {
      state.photoUrl = action.payload
    },
    updateEmailVerified: (state, action) => {
      state.emailVerified = action.payload
    },
    updateUid: (state, action) => {
      state.uid = action.payload
    },

    signOutUser: (state, action) => {
      state.displayName = null
      state.email = null
      state.phoneNumber = null
      state.photoUrl = null
      state.emailVerified = null
      state.uid = null
    }

  }

})


export const {
  updateDisplayName, updateEmail, updatePhoneNumber, updatePhotoUrl, updateEmailVerified, updateUid,  signOutUser,
} = userSlice.actions

export default userSlice.reducer
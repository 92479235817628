/** @jsxImportSource @emotion/react */
import './Dashboard.css'
import React, { useState, } from 'react';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { css, } from '@emotion/react';
import { Bracket, RoundProps, Seed, SingleLineSeed, SeedItem, SeedTeam, RenderSeedProps } from 'react-brackets';
import BracketComponent from '../components/BracketComponent';
import Box from '@mui/material/Box';
import DrawerComponent from '../components/DrawerComponent';
import CustomizedTables from '../components/TeamTableComponent';
import { useSelector, useDispatch } from 'react-redux'
import { change } from '../store/reducers/dashboardState'
import Tournaments from './Tournaments';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';

const Dashboard = () => {

  const dashboardState = useSelector(state => state.dashboardState)
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'))
  const [mobileOpen, setMobileOpen] = useState(false)


  console.log('dashboardState', dashboardState)
  const [conditionalDisplay, setConditionalDisplay] = useState(0);

  const conditionalDisplayHandler = (index) => {
    if (index === 1) {
      return (
        <Tournaments />
      )
    } else if (index === 2) {
      return (
        <CustomizedTables />
      )
    } else if (index === 3) {
      return (
        <BracketComponent isMobile drawerWidth width />
      )
    }
  }


  //       {conditionalDisplayHandler(dashboardState.value)
  // }

  const drawerWidth = 200;



  const mobileMenuIcon = () => {

    if (isMobile) {
      return (
        <Box sx={{ position: 'absolute', top: 16, left: 0, right: 16, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
          <MenuIcon onClick={() => setMobileOpen(!mobileOpen)} sx={{ height: 36, width: 36 }} />
        </Box>
      )
    }
  }



  return (
    <div css={{ display: 'flex', height: '100vh', width: '100%', backgroundColor: '#f0f2f5', justifyContent: 'flex-end', }}>
      {mobileMenuIcon()}

      <DrawerComponent mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} drawerWidth={200} />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: isMobile ? '100vw' : width - drawerWidth, height: '100vh', boxSizing: 'border-box', }}>
        <Typography>We'll send you and email when your dashboard is ready for you!</Typography>
      </Box>

    </div>

  )
}


export default Dashboard;
import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import RegistrationForm from '../components/RegistrationForm';
import RegistrationFormCoaches from '../components/registration/RegistrationFormCoaches';
import RegistrationFormOrgInfo from '../components/registration/RegistrationFormOrgInfo';
import RegistrationFormSocial from '../components/registration/RegistrationFormSocial';
import RegistrationSection from '../components/RegistrationSection';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';


const Register = () => {
  return (
    <Container maxWidth={'lg'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%', boxSizing: 'border-box', }} >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', alignItems: 'center',}}>
        <Typography variant='h2' align='center' fontWeight={700} sx={{ marginTop: 4, }}  >Register For Tournament</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '70%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <RegistrationForm />
        </Box>
      </Box>
    </Container>
  )
}


export default Register; 
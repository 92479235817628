import { createSlice } from '@reduxjs/toolkit'

export const team2PlayerInfoSlice = createSlice({
  name: 'team2PlayerInfo',
  initialState: {
    team2Player1FirstName: '',
    team2Player1LastName: '',
    team2Player2FirstName: '',
    team2Player2LastName: '',
    team2Player3FirstName: '',
    team2Player3LastName: '',
    team2Player4FirstName: '',
    team2Player4LastName: '',
    team2Player5FirstName: '',
    team2Player5LastName: '',
    team2Player6FirstName: '',
    team2Player6LastName: '',
    team2Player7FirstName: '',
    team2Player7LastName: '',
    team2Player8FirstName: '',
    team2Player8LastName: '',
    team2Player9FirstName: '',
    team2Player9LastName: '',
    team2Player10FirstName: '',
    team2Player10LastName: '',
    team2Player11FirstName: '',
    team2Player11LastName: '',
    team2Player12FirstName: '',
    team2Player12LastName: '',
    team2Player13FirstName: '',
    team2Player13LastName: '',
    team2Player14FirstName: '',
    team2Player14LastName: '',
    team2Player15FirstName: '',
    team2Player15LastName: '',
    team2Player16FirstName: '',
    team2Player16LastName: '',
    team2Player17FirstName: '',
    team2Player17LastName: '',
    team2Player18FirstName: '',
    team2Player18LastName: '',
    team2Player19FirstName: '',
    team2Player19LastName: '',
    team2Player20FirstName: '',
    team2Player20LastName: '',

  },
  reducers: {
    updateTeam2PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam2Player1FirstName: (state, action) => {
      state.team2Player1FirstName = action.payload
    },
    updateTeam2Player1LastName: (state, action) => {
      state.team2Player1LastName = action.payload
    },
    updateTeam2Player2FirstName: (state, action) => {
      state.team2Player2FirstName = action.payload
    },
    updateTeam2Player2LastName: (state, action) => {
      state.team2Player2LastName = action.payload
    },
    updateTeam2Player3FirstName: (state, action) => {
      state.team2Player3FirstName = action.payload
    },
    updateTeam2Player3LastName: (state, action) => {
      state.team2Player3LastName = action.payload
    },
    updateTeam2Player4FirstName: (state, action) => {
      state.team2Player4FirstName = action.payload
    },
    updateTeam2Player4LastName: (state, action) => {
      state.team2Player4LastName = action.payload
    },
    updateTeam2Player5FirstName: (state, action) => {
      state.team2Player5FirstName = action.payload
    },
    updateTeam2Player5LastName: (state, action) => {
      state.team2Player5LastName = action.payload
    },
    updateTeam2Player6FirstName: (state, action) => {
      state.team2Player6FirstName = action.payload
    },
    updateTeam2Player6LastName: (state, action) => {
      state.team2Player6LastName = action.payload
    },
    updateTeam2Player7FirstName: (state, action) => {
      state.team2Player7FirstName = action.payload
    },
    updateTeam2Player7LastName: (state, action) => {
      state.team2Player7LastName = action.payload
    },
    updateTeam2Player8FirstName: (state, action) => {
      state.team2Player8FirstName = action.payload
    },
    updateTeam2Player8LastName: (state, action) => {
      state.team2Player8LastName = action.payload
    },
    updateTeam2Player9FirstName: (state, action) => {
      state.team2Player9FirstName = action.payload
    },
    updateTeam2Player9LastName: (state, action) => {
      state.team2Player9LastName = action.payload
    },
    updateTeam2Player10FirstName: (state, action) => {
      state.team2Player10FirstName = action.payload
    },
    updateTeam2Player10LastName: (state, action) => {
      state.team2Player10LastName = action.payload
    },
    updateTeam2Player11FirstName: (state, action) => {
      state.team2Player11FirstName = action.payload
    },
    updateTeam2Player11LastName: (state, action) => {
      state.team2Player11LastName = action.payload
    },
    updateTeam2Player12FirstName: (state, action) => {
      state.team2Player12FirstName = action.payload
    },
    updateTeam2Player12LastName: (state, action) => {
      state.team2Player12LastName = action.payload
    },
    updateTeam2Player13FirstName: (state, action) => {
      state.team2Player13FirstName = action.payload
    },
    updateTeam2Player13LastName: (state, action) => {
      state.team2Player13LastName = action.payload
    },
    updateTeam2Player14FirstName: (state, action) => {
      state.team2Player14FirstName = action.payload
    },
    updateTeam2Player14LastName: (state, action) => {
      state.team2Player14LastName = action.payload
    },
    updateTeam2Player15FirstName: (state, action) => {
      state.team2Player15FirstName = action.payload
    },
    updateTeam2Player15LastName: (state, action) => {
      state.team2Player15LastName = action.payload
    },
    updateTeam2Player16FirstName: (state, action) => {
      state.team2Player16FirstName = action.payload
    },
    updateTeam2Player16LastName: (state, action) => {
      state.team2Player16LastName = action.payloadTeam2
    },






  }
})

export const {
  updateTeam2Team1PlayerInfo,
  updateTeam2Player1FirstName,
  updateTeam2Player1LastName,
  updateTeam2Player2FirstName,
  updateTeam2Player2LastName,
  updateTeam2Player3FirstName,
  updateTeam2Player3LastName,
  updateTeam2Player4FirstName,
  updateTeam2Player4LastName,
  updateTeam2Player5FirstName,
  updateTeam2Player5LastName,
  updateTeam2Player6FirstName,
  updateTeam2Player6LastName,
  updateTeam2Player7FirstName,
  updateTeam2Player7LastName,
  updateTeam2Player8FirstName,
  updateTeam2Player8LastName,
  updateTeam2Player9FirstName,
  updateTeam2Player9LastName,
  updateTeam2Player10FirstName,
  updateTeam2Player10LastName,
  updateTeam2Player11FirstName,
  updateTeam2Player11LastName,
  updateTeam2Player12FirstName,
  updateTeam2Player12LastName,
  updateTeam2Player13FirstName,
  updateTeam2Player13LastName,
  updateTeam2Player14FirstName,
  updateTeam2Player14LastName,
  updateTeam2Player15FirstName,
  updateTeam2Player15LastName,
  updateTeam2Player16FirstName,
  updateTeam2Player16LastName,

} = team2PlayerInfoSlice.actions

export default team2PlayerInfoSlice.reducer
import { createSlice } from '@reduxjs/toolkit'

export const team7PlayerInfoSlice = createSlice({
  name: 'team7PlayerInfo',
  initialState: {
    team7Player1FirstName: '',
    team7Player1LastName: '',
    team7Player2FirstName: '',
    team7Player2LastName: '',
    team7Player3FirstName: '',
    team7Player3LastName: '',
    team7Player4FirstName: '',
    team7Player4LastName: '',
    team7Player5FirstName: '',
    team7Player5LastName: '',
    team7Player6FirstName: '',
    team7Player6LastName: '',
    team7Player7FirstName: '',
    team7Player7LastName: '',
    team7Player8FirstName: '',
    team7Player8LastName: '',
    team7Player9FirstName: '',
    team7Player9LastName: '',
    team7Player10FirstName: '',
    team7Player10LastName: '',
    team7Player11FirstName: '',
    team7Player11LastName: '',
    team7Player12FirstName: '',
    team7Player12LastName: '',
    team7Player13FirstName: '',
    team7Player13LastName: '',
    team7Player14FirstName: '',
    team7Player14LastName: '',
    team7Player15FirstName: '',
    team7Player15LastName: '',
    team7Player16FirstName: '',
    team7Player16LastName: '',
    team7Player17FirstName: '',
    team7Player17LastName: '',
    team7Player18FirstName: '',
    team7Player18LastName: '',
    team7Player19FirstName: '',
    team7Player19LastName: '',
    team7Player20FirstName: '',
    team7Player20LastName: '',


  },
  reducers: {
    updateTeam7PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam7Player1FirstName: (state, action) => {
      state.team7Player1FirstName = action.payload
    },
    updateTeam7Player1LastName: (state, action) => {
      state.team7Player1LastName = action.payload
    },
    updateTeam7Player2FirstName: (state, action) => {
      state.team7Player2FirstName = action.payload
    },
    updateTeam7Player2LastName: (state, action) => {
      state.team7Player2LastName = action.payload
    },
    updateTeam7Player3FirstName: (state, action) => {
      state.team7Player3FirstName = action.payload
    },
    updateTeam7Player3LastName: (state, action) => {
      state.team7Player3LastName = action.payload
    },
    updateTeam7Player4FirstName: (state, action) => {
      state.team7Player4FirstName = action.payload
    },
    updateTeam7Player4LastName: (state, action) => {
      state.team7Player4LastName = action.payload
    },
    updateTeam7Player5FirstName: (state, action) => {
      state.team7Player5FirstName = action.payload
    },
    updateTeam7Player5LastName: (state, action) => {
      state.team7Player5LastName = action.payload
    },
    updateTeam7Player6FirstName: (state, action) => {
      state.team7Player6FirstName = action.payload
    },
    updateTeam7Player6LastName: (state, action) => {
      state.team7Player6LastName = action.payload
    },
    updateTeam7Player7FirstName: (state, action) => {
      state.team7Player7FirstName = action.payload
    },
    updateTeam7Player7LastName: (state, action) => {
      state.team7Player7LastName = action.payload
    },
    updateTeam7Player8FirstName: (state, action) => {
      state.team7Player8FirstName = action.payload
    },
    updateTeam7Player8LastName: (state, action) => {
      state.team7Player8LastName = action.payload
    },
    updateTeam7Player9FirstName: (state, action) => {
      state.team7Player9FirstName = action.payload
    },
    updateTeam7Player9LastName: (state, action) => {
      state.team7Player9LastName = action.payload
    },
    updateTeam7Player10FirstName: (state, action) => {
      state.team7Player10FirstName = action.payload
    },
    updateTeam7Player10LastName: (state, action) => {
      state.team7Player10LastName = action.payload
    },
    updateTeam7Player11FirstName: (state, action) => {
      state.team7Player11FirstName = action.payload
    },
    updateTeam7Player11LastName: (state, action) => {
      state.team7Player11LastName = action.payload
    },
    updateTeam7Player12FirstName: (state, action) => {
      state.team7Player12FirstName = action.payload
    },
    updateTeam7Player12LastName: (state, action) => {
      state.team7Player12LastName = action.payload
    },
    updateTeam7Player13FirstName: (state, action) => {
      state.team7Player13FirstName = action.payload
    },
    updateTeam7Player13LastName: (state, action) => {
      state.team7Player13LastName = action.payload
    },
    updateTeam7Player14FirstName: (state, action) => {
      state.team7Player14FirstName = action.payload
    },
    updateTeam7Player14LastName: (state, action) => {
      state.team7Player14LastName = action.payload
    },
    updateTeam7Player15FirstName: (state, action) => {
      state.team7Player15FirstName = action.payload
    },
    updateTeam7Player15LastName: (state, action) => {
      state.team7Player15LastName = action.payload
    },
    updateTeam7Player16FirstName: (state, action) => {
      state.team7Player16FirstName = action.payload
    },
    updateTeam7Player16LastName: (state, action) => {
      state.team7Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam7Team1PlayerInfo,
  updateTeam7Player1FirstName,
  updateTeam7Player1LastName,
  updateTeam7Player2FirstName,
  updateTeam7Player2LastName,
  updateTeam7Player3FirstName,
  updateTeam7Player3LastName,
  updateTeam7Player4FirstName,
  updateTeam7Player4LastName,
  updateTeam7Player5FirstName,
  updateTeam7Player5LastName,
  updateTeam7Player6FirstName,
  updateTeam7Player6LastName,
  updateTeam7Player7FirstName,
  updateTeam7Player7LastName,
  updateTeam7Player8FirstName,
  updateTeam7Player8LastName,
  updateTeam7Player9FirstName,
  updateTeam7Player9LastName,
  updateTeam7Player10FirstName,
  updateTeam7Player10LastName,
  updateTeam7Player11FirstName,
  updateTeam7Player11LastName,
  updateTeam7Player12FirstName,
  updateTeam7Player12LastName,
  updateTeam7Player13FirstName,
  updateTeam7Player13LastName,
  updateTeam7Player14FirstName,
  updateTeam7Player14LastName,
  updateTeam7Player15FirstName,
  updateTeam7Player15LastName,
  updateTeam7Player16FirstName,
  updateTeam7Player16LastName,

} = team7PlayerInfoSlice.actions
export default team7PlayerInfoSlice.reducer
import React, { useState, } from "react";
import { Box, TextField, RadioGroup, MenuItem, Select, FormHelperText, Radio, FormGroup, FormControl, FormLabel, FormControlLabel, Container, Checkbox, Typography, Card, Divider, Button, } from '@mui/material';
import RegistrationFormOrgInfo from "./registration/RegistrationFormOrgInfo";
import RegistrationFormSocial from "./registration/RegistrationFormSocial";
import { useSelector, useDispatch } from 'react-redux'
import { updateRegistrationState } from '../store/reducers/registrationState'
import RegistrationFormCoaches from "./registration/RegistrationFormCoaches";
import RegistrationFormTeams from "./registration/RegistrationFormTeams";
import Payment from "./registration/Payment";
import { db } from "./registration/firebaseConfig";
import { doc, collection, setDoc, updateDoc, addDoc, getFirestore, serverTimestamp } from "firebase/firestore";
import { FirestoreProvider, useFirestoreDocData, useFirestore, useFirebaseApp } from 'reactfire';
import { auth } from '../components/registration/firebaseConfig';
import { onAuthStateChanged } from "firebase/auth";
import { updateDisplayName, updateEmail, updatePhoneNumber, updatePhotoUrl, updateEmailVerified, updateUid, } from '../store/reducers/user';




const RegistrationForm = () => {

  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();



  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(updateDisplayName(user.displayName));
      dispatch(updateEmail(user.email));
      dispatch(updatePhoneNumber(user.phoneNumber));
      dispatch(updatePhotoUrl(user.photoURL));
      dispatch(updateEmailVerified(user.emailVerified));
      dispatch(updateUid(user.uid));
      console.log('user is signed in')
    } else {


      console.log('user is signed out')
    }
  })


  const firestore = useFirestore();

  const registrationState = useSelector(state => state.registrationState)
  const registrationAnswers = useSelector(state => state.registrationAnswers)
  const team1PlayerInfo = useSelector(state => state.team1PlayerInfo)
  const team2PlayerInfo = useSelector(state => state.team2PlayerInfo)
  const team3PlayerInfo = useSelector(state => state.team3PlayerInfo)
  const team4PlayerInfo = useSelector(state => state.team4PlayerInfo)
  const team5PlayerInfo = useSelector(state => state.team5PlayerInfo)
  const team6PlayerInfo = useSelector(state => state.team6PlayerInfo)
  const team7PlayerInfo = useSelector(state => state.team7PlayerInfo)
  const team8PlayerInfo = useSelector(state => state.team8PlayerInfo)
  const team9PlayerInfo = useSelector(state => state.team9PlayerInfo)
  const team10PlayerInfo = useSelector(state => state.team10PlayerInfo)
  const team11PlayerInfo = useSelector(state => state.team11PlayerInfo)
  const team12PlayerInfo = useSelector(state => state.team12PlayerInfo)
  const team13PlayerInfo = useSelector(state => state.team13PlayerInfo)
  const team14PlayerInfo = useSelector(state => state.team14PlayerInfo)
  const team15PlayerInfo = useSelector(state => state.team15PlayerInfo)
  const team16PlayerInfo = useSelector(state => state.team16PlayerInfo)


  console.log('user info', user)



  const [numOfPlayers, setNumOfPlayers] = useState(1);
  const [numOfTeams, setNumOfTeams] = useState(1);


  const playerInputFields = () => {
    return (
      <FormGroup sx={{ paddingTop: 1.5, }} >
        <FormLabel>Player Name</FormLabel>
        <FormGroup row>
          <TextField label='First Name' variant='outlined' margin={'dense'} />
          <TextField label='Last Name' variant='outlined' margin={'dense'} />
        </FormGroup>
      </FormGroup>
    )
  }

  const conditionallyRenderTeamFields = () => {
    const teams = []
    for (let i = 0; i < numOfTeams; i++) {
      teams.push(
        teamInfoSection(i)
      )
    } return teams;
  }

  const conditionallyRenderPlayerFields = () => {
    console.log('ran condition');
    const items = []
    for (let i = 0; i < numOfPlayers; i++) {
      items.push(
        <FormGroup sx={{ paddingTop: 1.5, }} >
          <FormLabel>Player Name</FormLabel>
          <FormGroup row>
            <TextField label='First Name' variant='outlined' margin={'dense'} />
            <TextField label='Last Name' variant='outlined' margin={'dense'} />
          </FormGroup>
        </FormGroup>
      )

    } return items
  }


  const playerNumberHandler = (event) => {
    setNumOfPlayers(event.target.value)
  }
  const teamNumberHandler = (event) => {
    setNumOfTeams(event.target.value)
  }

  const teamInfoSection = (index) => {
    return (

      <FormGroup sx={{}}>
        <FormLabel>Team {index + 1}</FormLabel>
        <FormControl>
          <FormLabel >What Gender is this Team?</FormLabel>
          <RadioGroup
            defaultValue="Boys"
          >
            <FormControlLabel value="Boys" control={<Radio />} label="Boys" />
            <FormControlLabel value="Girls" control={<Radio />} label="Girls" />
          </RadioGroup>
        </FormControl>
        <FormLabel >Team Division / Grade</FormLabel>
        <Select variant='outlined'>
          <MenuItem value={'2nd Grade - 8U'}>2nd Grade - 8U</MenuItem>
          <MenuItem value={'3rd Grade - 9U'}>3rd Grade - 9U</MenuItem>
          <MenuItem value={'4th Grade - 10U'}>4th Grade - 10U</MenuItem>
          <MenuItem value={'5th Grade - 11U'}>5th Grade - 11U</MenuItem>
          <MenuItem value={'6th Grade - 12U'}>6th Grade - 12U</MenuItem>
          <MenuItem value={'7th Grade - 13U'}>7th Grade - 13U</MenuItem>
          <MenuItem value={'8th Grade - 14U'}>8th Grade - 14U</MenuItem>
          <MenuItem value={'9th Grade - 15U'}>9th Grade - 15U</MenuItem>
        </Select>

        <FormGroup sx={{ paddingTop: 1.5, }}>
          <FormLabel margin={'normal'}>How Many Players For This Team?</FormLabel>
          <TextField onChange={playerNumberHandler} value={numOfPlayers} margin={'dense'} label="Number of Players" type={'number'} />
        </FormGroup>

        {conditionallyRenderPlayerFields()}


      </FormGroup>
    )
  }

  const registrationComplete = () => {

    const uploadData = async () => {
      const docData = {
        registrationAnswers: registrationAnswers,
        registrationState: registrationState,
        team1PlayerInfo: team1PlayerInfo,
        team2PlayerInfo: team2PlayerInfo,
        team3PlayerInfo: team3PlayerInfo,
        team4PlayerInfo: team4PlayerInfo,
        team5PlayerInfo: team5PlayerInfo,
        team6PlayerInfo: team6PlayerInfo,
        team7PlayerInfo: team7PlayerInfo,
        team8PlayerInfo: team8PlayerInfo,
        team9PlayerInfo: team9PlayerInfo,
        team10PlayerInfo: team10PlayerInfo,
        team11PlayerInfo: team11PlayerInfo,
        team12PlayerInfo: team12PlayerInfo,
        team13PlayerInfo: team13PlayerInfo,
        team14PlayerInfo: team14PlayerInfo,
        team15PlayerInfo: team15PlayerInfo,
        team16PlayerInfo: team16PlayerInfo,
        user: user,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }
      const clubCollection = collection(firestore, 'clubs')
      addDoc(clubCollection, docData)
    }

    // uploadData()


    return (
      <Container maxWidth='md' sx={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', }}>
        <Container sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'center', }}>
          <Typography variant='h4' align='center' sx={{ marginBottom: 2, }} >Registration Complete!</Typography>
          <Typography variant='body1' align='center' >We'll see you on the court!</Typography>
        </Container>
        <Button variant='contained' href='/' sx={{ display: 'flex', marginTop: 4 }} >Back To HomePage</Button>
      </Container>
    )
  }

  const dataToUpdate = {
    registrationData: {
      registrationAnswers: registrationAnswers,
      registrationState: registrationState,
      team1PlayerInfo: team1PlayerInfo,
      team2PlayerInfo: team2PlayerInfo,
      team3PlayerInfo: team3PlayerInfo,
      team4PlayerInfo: team4PlayerInfo,
      team5PlayerInfo: team5PlayerInfo,
      team6PlayerInfo: team6PlayerInfo,
      team7PlayerInfo: team7PlayerInfo,
      team8PlayerInfo: team8PlayerInfo,
      team9PlayerInfo: team9PlayerInfo,
      team10PlayerInfo: team10PlayerInfo,
      team11PlayerInfo: team11PlayerInfo,
      team12PlayerInfo: team12PlayerInfo,
      team13PlayerInfo: team13PlayerInfo,
      team14PlayerInfo: team14PlayerInfo,
      team15PlayerInfo: team15PlayerInfo,
      team16PlayerInfo: team16PlayerInfo,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }
  }

  const updateUserDoc = async (data) => {
    const userDocRef = doc(db, 'users', user.uid)
    await updateDoc(userDocRef, dataToUpdate)
    console.log('updated user doc')
  }

  const conditionallyRenderFormSections = () => {

    const numOfTeamPages = registrationAnswers.numOfTeams * 2;
    const pageNumPassedTeams = 10 + numOfTeamPages + 1;

    if (registrationState.value <= 2) {
      updateUserDoc({ registrationData: registrationState.value })
      return (
        <RegistrationFormOrgInfo />
      )
    } else if (registrationState.value <= 5) {
      updateUserDoc({ registrationData: registrationState.value })

      return (
        <RegistrationFormCoaches firstStateNumber={3} />
      )
    } else if (registrationState.value <= 9) {
      updateUserDoc({ registrationData: registrationState.value })

      return (
        <RegistrationFormSocial firstStateNumber={6} />
      )
    } else if (registrationState.value <= (10 + (registrationAnswers.numOfTeams * 2))) {
      updateUserDoc({ registrationData: registrationState.value })

      console.log('displaying teams')
      return (
        <RegistrationFormTeams />
      )
    } else {
      return (
        registrationComplete()
      )
    }
  }

  console.log('registration state', registrationState.value, 10 + (registrationAnswers.numOfTeams * 2));

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', width: '100%', height: 700,
      boxSizing: 'border-box', boxShadow: 4, borderRadius: 3, padding: 2, margin: 2,
      backgroundColor: 'white', overflowY: 'scroll',

    }}>

      {conditionallyRenderFormSections()}


    </Box>
  )
}


export default RegistrationForm;
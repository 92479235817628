import React from 'react';
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement } from '@stripe/react-stripe-js';
import { getApp } from "@firebase/app";
import { getStripePayments, getProducts, createCheckoutSession } from "@stripe/firestore-stripe-payments";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import StyledFirebaseAuth from '../../components/StyledFirebaseAuth.tsx'
import firebase from 'firebase/compat/app';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { app, auth, payments, db } from '../../firebaseConfig';
import { useNavigate } from "react-router-dom";
import { stripePromise } from '../..';
import { useSelector } from 'react-redux';


const Payment = () => {

  const registrationAnswers = useSelector((state) => state.registrationAnswers);

  const navigate = useNavigate();

  const handleClick = async () => {

    const testPrice250 = 'price_1MB0DcHPZDnf3WXbiC5NnI9S'
    const testPrice275 = 'price_1MAetZHPZDnf3WXbqlTOgMWW'

    
    const singleUnitPrice = 'price_1NIc2WHPZDnf3WXbfmm0FkS4'
    const multipleUnitPrice = 'price_1NIc2gHPZDnf3WXb4etjt3eW'


    const price275 = 'price_1MB08zHPZDnf3WXbaRVnmwB5'
    const price250 = 'price_1MAzdFHPZDnf3WXb6PRzH95Q'

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        price: registrationAnswers.numOfTeams > 1 ? multipleUnitPrice : singleUnitPrice, // Replace with the ID of your price
        quantity: registrationAnswers.numOfTeams,
      }],
      mode: 'payment',
      successUrl: 'https://bigstagetournaments.com/success',
      cancelUrl: 'https://bigstagetournaments.com/',
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.

  }

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/signedIn',
    // We will display Google and Email as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        console.log(authResult)
        // this is a new user, add them to the firestore users collection!
        if (authResult.additionalUserInfo.isNewUser) {
          db.collection("users")
            .doc(authResult.user.uid)
            .set({
              displayName: authResult.user.displayName,
              photoURL: authResult.user.photoURL,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              console.log("User document successfully written!");
            })
            .catch((error) => {
              console.error("Error writing user document: ", error);
            });
        }
        return navigate('/main')
      },
    },
  };

  const products = async () => {

    console.log('ran products');

    const result = await getProducts(payments, {
      includePrices: true,
      activeOnly: true,
    });
    return result
  }


  const runStripe = async () => {
    const result = await products()
    console.log(result)






  }

  runStripe()





  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', }}>
      <Button variant='contained' onClick={handleClick}>Checkout</Button>

    </Box>
  )
}


export default Payment;
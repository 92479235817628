import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, FormControl, FormGroup, RadioGroup, FormLabel, Select, MenuItem, FormControlLabel, Radio } from '@mui/material';
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';

import {
  updateTeam1Team1PlayerInfo, updateTeam1Player1FirstName, updateTeam1Player1LastName, updateTeam1Player2FirstName, updateTeam1Player2LastName, updateTeam1Player3FirstName,
  updateTeam1Player3LastName, updateTeam1Player4FirstName, updateTeam1Player4LastName, updateTeam1Player5FirstName, updateTeam1Player5LastName, updateTeam1Player6FirstName,
  updateTeam1Player6LastName, updateTeam1Player7FirstName, updateTeam1Player7LastName, updateTeam1Player8FirstName, updateTeam1Player8LastName,
  updateTeam1Player9FirstName, updateTeam1Player9LastName, updateTeam1Player10FirstName, updateTeam1Player10LastName, updateTeam1Player11FirstName, updateTeam1Player11LastName,
  updateTeam1Player12FirstName, updateTeam1Player12LastName, updateTeam1Player13FirstName, updateTeam1Player13LastName, updateTeam1Player14FirstName, updateTeam1Player14LastName,
  updateTeam1Player15FirstName, updateTeam1Player15LastName, updateTeam1Player16FirstName, updateTeam1Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team1PlayerInfo';

import {
  updateTeam2Player1FirstName, updateTeam2Player1LastName, updateTeam2Player2FirstName, updateTeam2Player2LastName, updateTeam2Player3FirstName,
  updateTeam2Player3LastName, updateTeam2Player4FirstName, updateTeam2Player4LastName, updateTeam2Player5FirstName, updateTeam2Player5LastName, updateTeam2Player6FirstName,
  updateTeam2Player6LastName, updateTeam2Player7FirstName, updateTeam2Player7LastName, updateTeam2Player8FirstName, updateTeam2Player8LastName,
  updateTeam2Player9FirstName, updateTeam2Player9LastName, updateTeam2Player10FirstName, updateTeam2Player10LastName, updateTeam2Player11FirstName, updateTeam2Player11LastName,
  updateTeam2Player12FirstName, updateTeam2Player12LastName, updateTeam2Player13FirstName, updateTeam2Player13LastName, updateTeam2Player14FirstName, updateTeam2Player14LastName,
  updateTeam2Player15FirstName, updateTeam2Player15LastName, updateTeam2Player16FirstName, updateTeam2Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team2PlayerInfo';

// import update actions for all teams from 3 to 16

import {
  updateTeam3Player1FirstName, updateTeam3Player1LastName, updateTeam3Player2FirstName, updateTeam3Player2LastName, updateTeam3Player3FirstName,
  updateTeam3Player3LastName, updateTeam3Player4FirstName, updateTeam3Player4LastName, updateTeam3Player5FirstName, updateTeam3Player5LastName, updateTeam3Player6FirstName,
  updateTeam3Player6LastName, updateTeam3Player7FirstName, updateTeam3Player7LastName, updateTeam3Player8FirstName, updateTeam3Player8LastName,
  updateTeam3Player9FirstName, updateTeam3Player9LastName, updateTeam3Player10FirstName, updateTeam3Player10LastName, updateTeam3Player11FirstName, updateTeam3Player11LastName,
  updateTeam3Player12FirstName, updateTeam3Player12LastName, updateTeam3Player13FirstName, updateTeam3Player13LastName, updateTeam3Player14FirstName, updateTeam3Player14LastName,
  updateTeam3Player15FirstName, updateTeam3Player15LastName, updateTeam3Player16FirstName, updateTeam3Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team3PlayerInfo';

import {
  updateTeam4Player1FirstName, updateTeam4Player1LastName, updateTeam4Player2FirstName, updateTeam4Player2LastName, updateTeam4Player3FirstName,
  updateTeam4Player3LastName, updateTeam4Player4FirstName, updateTeam4Player4LastName, updateTeam4Player5FirstName, updateTeam4Player5LastName, updateTeam4Player6FirstName,
  updateTeam4Player6LastName, updateTeam4Player7FirstName, updateTeam4Player7LastName, updateTeam4Player8FirstName, updateTeam4Player8LastName,
  updateTeam4Player9FirstName, updateTeam4Player9LastName, updateTeam4Player10FirstName, updateTeam4Player10LastName, updateTeam4Player11FirstName, updateTeam4Player11LastName,
  updateTeam4Player12FirstName, updateTeam4Player12LastName, updateTeam4Player13FirstName, updateTeam4Player13LastName, updateTeam4Player14FirstName, updateTeam4Player14LastName,
  updateTeam4Player15FirstName, updateTeam4Player15LastName, updateTeam4Player16FirstName, updateTeam4Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team4PlayerInfo';

import {
  updateTeam5Player1FirstName, updateTeam5Player1LastName, updateTeam5Player2FirstName, updateTeam5Player2LastName, updateTeam5Player3FirstName,
  updateTeam5Player3LastName, updateTeam5Player4FirstName, updateTeam5Player4LastName, updateTeam5Player5FirstName, updateTeam5Player5LastName, updateTeam5Player6FirstName,
  updateTeam5Player6LastName, updateTeam5Player7FirstName, updateTeam5Player7LastName, updateTeam5Player8FirstName, updateTeam5Player8LastName,
  updateTeam5Player9FirstName, updateTeam5Player9LastName, updateTeam5Player10FirstName, updateTeam5Player10LastName, updateTeam5Player11FirstName, updateTeam5Player11LastName,
  updateTeam5Player12FirstName, updateTeam5Player12LastName, updateTeam5Player13FirstName, updateTeam5Player13LastName, updateTeam5Player14FirstName, updateTeam5Player14LastName,
  updateTeam5Player15FirstName, updateTeam5Player15LastName, updateTeam5Player16FirstName, updateTeam5Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team5PlayerInfo';

import {
  updateTeam6Player1FirstName, updateTeam6Player1LastName, updateTeam6Player2FirstName, updateTeam6Player2LastName, updateTeam6Player3FirstName,
  updateTeam6Player3LastName, updateTeam6Player4FirstName, updateTeam6Player4LastName, updateTeam6Player5FirstName, updateTeam6Player5LastName, updateTeam6Player6FirstName,
  updateTeam6Player6LastName, updateTeam6Player7FirstName, updateTeam6Player7LastName, updateTeam6Player8FirstName, updateTeam6Player8LastName,
  updateTeam6Player9FirstName, updateTeam6Player9LastName, updateTeam6Player10FirstName, updateTeam6Player10LastName, updateTeam6Player11FirstName, updateTeam6Player11LastName,
  updateTeam6Player12FirstName, updateTeam6Player12LastName, updateTeam6Player13FirstName, updateTeam6Player13LastName, updateTeam6Player14FirstName, updateTeam6Player14LastName,
  updateTeam6Player15FirstName, updateTeam6Player15LastName, updateTeam6Player16FirstName, updateTeam6Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team6PlayerInfo';

import {
  updateTeam7Player1FirstName, updateTeam7Player1LastName, updateTeam7Player2FirstName, updateTeam7Player2LastName, updateTeam7Player3FirstName,
  updateTeam7Player3LastName, updateTeam7Player4FirstName, updateTeam7Player4LastName, updateTeam7Player5FirstName, updateTeam7Player5LastName, updateTeam7Player6FirstName,
  updateTeam7Player6LastName, updateTeam7Player7FirstName, updateTeam7Player7LastName, updateTeam7Player8FirstName, updateTeam7Player8LastName,
  updateTeam7Player9FirstName, updateTeam7Player9LastName, updateTeam7Player10FirstName, updateTeam7Player10LastName, updateTeam7Player11FirstName, updateTeam7Player11LastName,
  updateTeam7Player12FirstName, updateTeam7Player12LastName, updateTeam7Player13FirstName, updateTeam7Player13LastName, updateTeam7Player14FirstName, updateTeam7Player14LastName,
  updateTeam7Player15FirstName, updateTeam7Player15LastName, updateTeam7Player16FirstName, updateTeam7Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team7PlayerInfo';

import {
  updateTeam8Player1FirstName, updateTeam8Player1LastName, updateTeam8Player2FirstName, updateTeam8Player2LastName, updateTeam8Player3FirstName,
  updateTeam8Player3LastName, updateTeam8Player4FirstName, updateTeam8Player4LastName, updateTeam8Player5FirstName, updateTeam8Player5LastName, updateTeam8Player6FirstName,
  updateTeam8Player6LastName, updateTeam8Player7FirstName, updateTeam8Player7LastName, updateTeam8Player8FirstName, updateTeam8Player8LastName,
  updateTeam8Player9FirstName, updateTeam8Player9LastName, updateTeam8Player10FirstName, updateTeam8Player10LastName, updateTeam8Player11FirstName, updateTeam8Player11LastName,
  updateTeam8Player12FirstName, updateTeam8Player12LastName, updateTeam8Player13FirstName, updateTeam8Player13LastName, updateTeam8Player14FirstName, updateTeam8Player14LastName,
  updateTeam8Player15FirstName, updateTeam8Player15LastName, updateTeam8Player16FirstName, updateTeam8Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team8PlayerInfo';

import {
  updateTeam9Player1FirstName, updateTeam9Player1LastName, updateTeam9Player2FirstName, updateTeam9Player2LastName, updateTeam9Player3FirstName,
  updateTeam9Player3LastName, updateTeam9Player4FirstName, updateTeam9Player4LastName, updateTeam9Player5FirstName, updateTeam9Player5LastName, updateTeam9Player6FirstName,
  updateTeam9Player6LastName, updateTeam9Player7FirstName, updateTeam9Player7LastName, updateTeam9Player8FirstName, updateTeam9Player8LastName,
  updateTeam9Player9FirstName, updateTeam9Player9LastName, updateTeam9Player10FirstName, updateTeam9Player10LastName, updateTeam9Player11FirstName, updateTeam9Player11LastName,
  updateTeam9Player12FirstName, updateTeam9Player12LastName, updateTeam9Player13FirstName, updateTeam9Player13LastName, updateTeam9Player14FirstName, updateTeam9Player14LastName,
  updateTeam9Player15FirstName, updateTeam9Player15LastName, updateTeam9Player16FirstName, updateTeam9Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team9PlayerInfo';

import {
  updateTeam10Player1FirstName, updateTeam10Player1LastName, updateTeam10Player2FirstName, updateTeam10Player2LastName, updateTeam10Player3FirstName,
  updateTeam10Player3LastName, updateTeam10Player4FirstName, updateTeam10Player4LastName, updateTeam10Player5FirstName, updateTeam10Player5LastName, updateTeam10Player6FirstName,
  updateTeam10Player6LastName, updateTeam10Player7FirstName, updateTeam10Player7LastName, updateTeam10Player8FirstName, updateTeam10Player8LastName,
  updateTeam10Player9FirstName, updateTeam10Player9LastName, updateTeam10Player10FirstName, updateTeam10Player10LastName, updateTeam10Player11FirstName, updateTeam10Player11LastName,
  updateTeam10Player12FirstName, updateTeam10Player12LastName, updateTeam10Player13FirstName, updateTeam10Player13LastName, updateTeam10Player14FirstName, updateTeam10Player14LastName,
  updateTeam10Player15FirstName, updateTeam10Player15LastName, updateTeam10Player16FirstName, updateTeam10Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team10PlayerInfo';

import {
  updateTeam11Player1FirstName, updateTeam11Player1LastName, updateTeam11Player2FirstName, updateTeam11Player2LastName, updateTeam11Player3FirstName,
  updateTeam11Player3LastName, updateTeam11Player4FirstName, updateTeam11Player4LastName, updateTeam11Player5FirstName, updateTeam11Player5LastName, updateTeam11Player6FirstName,
  updateTeam11Player6LastName, updateTeam11Player7FirstName, updateTeam11Player7LastName, updateTeam11Player8FirstName, updateTeam11Player8LastName,
  updateTeam11Player9FirstName, updateTeam11Player9LastName, updateTeam11Player10FirstName, updateTeam11Player10LastName, updateTeam11Player11FirstName, updateTeam11Player11LastName,
  updateTeam11Player12FirstName, updateTeam11Player12LastName, updateTeam11Player13FirstName, updateTeam11Player13LastName, updateTeam11Player14FirstName, updateTeam11Player14LastName,
  updateTeam11Player15FirstName, updateTeam11Player15LastName, updateTeam11Player16FirstName, updateTeam11Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team11PlayerInfo';

import {
  updateTeam12Player1FirstName, updateTeam12Player1LastName, updateTeam12Player2FirstName, updateTeam12Player2LastName, updateTeam12Player3FirstName,
  updateTeam12Player3LastName, updateTeam12Player4FirstName, updateTeam12Player4LastName, updateTeam12Player5FirstName, updateTeam12Player5LastName, updateTeam12Player6FirstName,
  updateTeam12Player6LastName, updateTeam12Player7FirstName, updateTeam12Player7LastName, updateTeam12Player8FirstName, updateTeam12Player8LastName,
  updateTeam12Player9FirstName, updateTeam12Player9LastName, updateTeam12Player10FirstName, updateTeam12Player10LastName, updateTeam12Player11FirstName, updateTeam12Player11LastName,
  updateTeam12Player12FirstName, updateTeam12Player12LastName, updateTeam12Player13FirstName, updateTeam12Player13LastName, updateTeam12Player14FirstName, updateTeam12Player14LastName,
  updateTeam12Player15FirstName, updateTeam12Player15LastName, updateTeam12Player16FirstName, updateTeam12Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team12PlayerInfo';

import {
  updateTeam13Player1FirstName, updateTeam13Player1LastName, updateTeam13Player2FirstName, updateTeam13Player2LastName, updateTeam13Player3FirstName,
  updateTeam13Player3LastName, updateTeam13Player4FirstName, updateTeam13Player4LastName, updateTeam13Player5FirstName, updateTeam13Player5LastName, updateTeam13Player6FirstName,
  updateTeam13Player6LastName, updateTeam13Player7FirstName, updateTeam13Player7LastName, updateTeam13Player8FirstName, updateTeam13Player8LastName,
  updateTeam13Player9FirstName, updateTeam13Player9LastName, updateTeam13Player10FirstName, updateTeam13Player10LastName, updateTeam13Player11FirstName, updateTeam13Player11LastName,
  updateTeam13Player12FirstName, updateTeam13Player12LastName, updateTeam13Player13FirstName, updateTeam13Player13LastName, updateTeam13Player14FirstName, updateTeam13Player14LastName,
  updateTeam13Player15FirstName, updateTeam13Player15LastName, updateTeam13Player16FirstName, updateTeam13Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team13PlayerInfo';

import {
  updateTeam14Player1FirstName, updateTeam14Player1LastName, updateTeam14Player2FirstName, updateTeam14Player2LastName, updateTeam14Player3FirstName,
  updateTeam14Player3LastName, updateTeam14Player4FirstName, updateTeam14Player4LastName, updateTeam14Player5FirstName, updateTeam14Player5LastName, updateTeam14Player6FirstName,
  updateTeam14Player6LastName, updateTeam14Player7FirstName, updateTeam14Player7LastName, updateTeam14Player8FirstName, updateTeam14Player8LastName,
  updateTeam14Player9FirstName, updateTeam14Player9LastName, updateTeam14Player10FirstName, updateTeam14Player10LastName, updateTeam14Player11FirstName, updateTeam14Player11LastName,
  updateTeam14Player12FirstName, updateTeam14Player12LastName, updateTeam14Player13FirstName, updateTeam14Player13LastName, updateTeam14Player14FirstName, updateTeam14Player14LastName,
  updateTeam14Player15FirstName, updateTeam14Player15LastName, updateTeam14Player16FirstName, updateTeam14Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team14PlayerInfo';

import {
  updateTeam15Player1FirstName, updateTeam15Player1LastName, updateTeam15Player2FirstName, updateTeam15Player2LastName, updateTeam15Player3FirstName,
  updateTeam15Player3LastName, updateTeam15Player4FirstName, updateTeam15Player4LastName, updateTeam15Player5FirstName, updateTeam15Player5LastName, updateTeam15Player6FirstName,
  updateTeam15Player6LastName, updateTeam15Player7FirstName, updateTeam15Player7LastName, updateTeam15Player8FirstName, updateTeam15Player8LastName,
  updateTeam15Player9FirstName, updateTeam15Player9LastName, updateTeam15Player10FirstName, updateTeam15Player10LastName, updateTeam15Player11FirstName, updateTeam15Player11LastName,
  updateTeam15Player12FirstName, updateTeam15Player12LastName, updateTeam15Player13FirstName, updateTeam15Player13LastName, updateTeam15Player14FirstName, updateTeam15Player14LastName,
  updateTeam15Player15FirstName, updateTeam15Player15LastName, updateTeam15Player16FirstName, updateTeam15Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team15PlayerInfo';

import {
  updateTeam16Player1FirstName, updateTeam16Player1LastName, updateTeam16Player2FirstName, updateTeam16Player2LastName, updateTeam16Player3FirstName,
  updateTeam16Player3LastName, updateTeam16Player4FirstName, updateTeam16Player4LastName, updateTeam16Player5FirstName, updateTeam16Player5LastName, updateTeam16Player6FirstName,
  updateTeam16Player6LastName, updateTeam16Player7FirstName, updateTeam16Player7LastName, updateTeam16Player8FirstName, updateTeam16Player8LastName,
  updateTeam16Player9FirstName, updateTeam16Player9LastName, updateTeam16Player10FirstName, updateTeam16Player10LastName, updateTeam16Player11FirstName, updateTeam16Player11LastName,
  updateTeam16Player12FirstName, updateTeam16Player12LastName, updateTeam16Player13FirstName, updateTeam16Player13LastName, updateTeam16Player14FirstName, updateTeam16Player14LastName,
  updateTeam16Player15FirstName, updateTeam16Player15LastName, updateTeam16Player16FirstName, updateTeam16Player16LastName,
} from '../../store/reducers/playerInfoForTeams/team16PlayerInfo';




import { playerFieldNames, playerFieldFunctions } from '../../data/playerFieldData'


const PlayerFields = (props) => {

  const team1PlayerInfo = useSelector(state => state.team1PlayerInfo)
  const team2PlayerInfo = useSelector(state => state.team2PlayerInfo)
  const team3PlayerInfo = useSelector(state => state.team3PlayerInfo)
  const team4PlayerInfo = useSelector(state => state.team4PlayerInfo)
  const team5PlayerInfo = useSelector(state => state.team5PlayerInfo)
  const team6PlayerInfo = useSelector(state => state.team6PlayerInfo)
  const team7PlayerInfo = useSelector(state => state.team7PlayerInfo)
  const team8PlayerInfo = useSelector(state => state.team8PlayerInfo)
  const team9PlayerInfo = useSelector(state => state.team9PlayerInfo)
  const team10PlayerInfo = useSelector(state => state.team10PlayerInfo)
  const team11PlayerInfo = useSelector(state => state.team11PlayerInfo)
  const team12PlayerInfo = useSelector(state => state.team12PlayerInfo)
  const team13PlayerInfo = useSelector(state => state.team13PlayerInfo)
  const team14PlayerInfo = useSelector(state => state.team14PlayerInfo)
  const team15PlayerInfo = useSelector(state => state.team15PlayerInfo)
  const team16PlayerInfo = useSelector(state => state.team16PlayerInfo)



  const teamNum = props.teamNum;
  const playerNum = props.playerNum;
  const teamIndex = teamNum - 1;
  const playerIndex = playerNum - 1;



   const playerFieldFunctions = [
    //team number
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam1Player1FirstName,
        updateTeam1Player1LastName,
  
      ],
      [
        updateTeam1Player2FirstName,
        updateTeam1Player2LastName
  
      ],
      [
        updateTeam1Player3FirstName,
        updateTeam1Player3LastName
      ],
      [
        updateTeam1Player4FirstName,
        updateTeam1Player4LastName
      ],
      [
        updateTeam1Player5FirstName,
        updateTeam1Player5LastName
      ],
      [
        updateTeam1Player6FirstName,
        updateTeam1Player6LastName
      ],
      [
        updateTeam1Player7FirstName,
        updateTeam1Player7LastName
      ],
      [
        updateTeam1Player8FirstName,
        updateTeam1Player8LastName
      ],
      [
        updateTeam1Player9FirstName,
        updateTeam1Player9LastName
      ],
      [
        updateTeam1Player10FirstName,
        updateTeam1Player10LastName
      ],
      [
        updateTeam1Player11FirstName,
        updateTeam1Player11LastName
      ],
      [
        updateTeam1Player12FirstName,
        updateTeam1Player12LastName
      ],
      [
        updateTeam1Player13FirstName,
        updateTeam1Player13LastName
      ],
      [
        updateTeam1Player14FirstName,
        updateTeam1Player14LastName
      ],
      [
        updateTeam1Player15FirstName,
        updateTeam1Player15LastName
      ],
      [
        updateTeam1Player16FirstName,
        updateTeam1Player16LastName
      ]
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam2Player1FirstName,
        updateTeam2Player1LastName
      ],
      [
        updateTeam2Player2FirstName,
        updateTeam2Player2LastName
      ],
      [
        updateTeam2Player3FirstName,
        updateTeam2Player3LastName
      ],
      [
        updateTeam2Player4FirstName,
        updateTeam2Player4LastName
      ],
      [
        updateTeam2Player5FirstName,
        updateTeam2Player5LastName
      ],
      [
        updateTeam2Player6FirstName,
        updateTeam2Player6LastName
      ],
      [
        updateTeam2Player7FirstName,
        updateTeam2Player7LastName
      ],
      [
        updateTeam2Player8FirstName,
        updateTeam2Player8LastName
      ],
      [
        updateTeam2Player9FirstName,
        updateTeam2Player9LastName
      ],
      [
        updateTeam2Player10FirstName,
        updateTeam2Player10LastName
  
      ],
      [
        updateTeam2Player11FirstName,
        updateTeam2Player11LastName
      ],
      [
        updateTeam2Player12FirstName,
        updateTeam2Player12LastName
      ],
      [
        updateTeam2Player13FirstName,
        updateTeam2Player13LastName
      ],
      [
        updateTeam2Player14FirstName,
        updateTeam2Player14LastName
      ],
      [
        updateTeam2Player15FirstName,
        updateTeam2Player15LastName
      ],
      [
        updateTeam2Player16FirstName,
        updateTeam2Player16LastName
      ],
  
  
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam3Player1FirstName,
        updateTeam3Player1LastName
      ],
      [
        updateTeam3Player2FirstName,
        updateTeam3Player2LastName
      ],
      [
        updateTeam3Player3FirstName,
        updateTeam3Player3LastName
      ],
      [
        updateTeam3Player4FirstName,
        updateTeam3Player4LastName
      ],
      [
        updateTeam3Player5FirstName,
        updateTeam3Player5LastName
      ],
      [
        updateTeam3Player6FirstName,
        updateTeam3Player6LastName
      ],
      [
        updateTeam3Player7FirstName,
        updateTeam3Player7LastName
      ],
      [
        updateTeam3Player8FirstName,
        updateTeam3Player8LastName
      ],
      [
        updateTeam3Player9FirstName,
        updateTeam3Player9LastName
      ],
      [
        updateTeam3Player10FirstName,
        updateTeam3Player10LastName
      ],
      [
        updateTeam3Player11FirstName,
        updateTeam3Player11LastName
      ],
      [
        updateTeam3Player12FirstName,
        updateTeam3Player12LastName
      ],
      [
        updateTeam3Player13FirstName,
        updateTeam3Player13LastName
      ],
      [
        updateTeam3Player14FirstName,
        updateTeam3Player14LastName
      ],
      [
        updateTeam3Player15FirstName,
        updateTeam3Player15LastName
      ],
      [
        updateTeam3Player16FirstName,
        updateTeam3Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam4Player1FirstName,
        updateTeam4Player1LastName
      ],
      [
        updateTeam4Player2FirstName,
        updateTeam4Player2LastName
      ],
      [
        updateTeam4Player3FirstName,
        updateTeam4Player3LastName
      ],
      [
        updateTeam4Player4FirstName,
        updateTeam4Player4LastName
      ],
      [
        updateTeam4Player5FirstName,
        updateTeam4Player5LastName
      ],
      [
        updateTeam4Player6FirstName,
        updateTeam4Player6LastName
      ],
      [
        updateTeam4Player7FirstName,
        updateTeam4Player7LastName
      ],
      [
        updateTeam4Player8FirstName,
        updateTeam4Player8LastName
      ],
      [
        updateTeam4Player9FirstName,
        updateTeam4Player9LastName
      ],
      [
        updateTeam4Player10FirstName,
        updateTeam4Player10LastName
      ],
      [
        updateTeam4Player11FirstName,
        updateTeam4Player11LastName
      ],
      [
        updateTeam4Player12FirstName,
        updateTeam4Player12LastName
      ],
      [
        updateTeam4Player13FirstName,
        updateTeam4Player13LastName
      ],
      [
        updateTeam4Player14FirstName,
        updateTeam4Player14LastName
      ],
      [
        updateTeam4Player15FirstName,
        updateTeam4Player15LastName
      ],
      [
        updateTeam4Player16FirstName,
        updateTeam4Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam5Player1FirstName,
        updateTeam5Player1LastName
      ],
      [
        updateTeam5Player2FirstName,
        updateTeam5Player2LastName
      ],
      [
        updateTeam5Player3FirstName,
        updateTeam5Player3LastName
      ],
      [
        updateTeam5Player4FirstName,
        updateTeam5Player4LastName
      ],
      [
        updateTeam5Player5FirstName,
        updateTeam5Player5LastName
      ],
      [
        updateTeam5Player6FirstName,
        updateTeam5Player6LastName
      ],
      [
        updateTeam5Player7FirstName,
        updateTeam5Player7LastName
      ],
      [
        updateTeam5Player8FirstName,
        updateTeam5Player8LastName
      ],
      [
        updateTeam5Player9FirstName,
        updateTeam5Player9LastName
      ],
      [
        updateTeam5Player10FirstName,
        updateTeam5Player10LastName
      ],
      [
        updateTeam5Player11FirstName,
        updateTeam5Player11LastName
      ],
      [
        updateTeam5Player12FirstName,
        updateTeam5Player12LastName
      ],
      [
        updateTeam5Player13FirstName,
        updateTeam5Player13LastName
      ],
      [
        updateTeam5Player14FirstName,
        updateTeam5Player14LastName
      ],
      [
        updateTeam5Player15FirstName,
        updateTeam5Player15LastName
      ],
      [
        updateTeam5Player16FirstName,
        updateTeam5Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam6Player1FirstName,
        updateTeam6Player1LastName
      ],
      [
        updateTeam6Player2FirstName,
        updateTeam6Player2LastName
      ],
      [
        updateTeam6Player3FirstName,
        updateTeam6Player3LastName
      ],
      [
        updateTeam6Player4FirstName,
        updateTeam6Player4LastName
      ],
      [
        updateTeam6Player5FirstName,
        updateTeam6Player5LastName
      ],
      [
        updateTeam6Player6FirstName,
        updateTeam6Player6LastName
      ],
      [
        updateTeam6Player7FirstName,
        updateTeam6Player7LastName
      ],
      [
        updateTeam6Player8FirstName,
        updateTeam6Player8LastName
      ],
      [
        updateTeam6Player9FirstName,
        updateTeam6Player9LastName
      ],
      [
        updateTeam6Player10FirstName,
        updateTeam6Player10LastName
      ],
      [
        updateTeam6Player11FirstName,
        updateTeam6Player11LastName
      ],
      [
        updateTeam6Player12FirstName,
        updateTeam6Player12LastName
      ],
      [
        updateTeam6Player13FirstName,
        updateTeam6Player13LastName
      ],
      [
        updateTeam6Player14FirstName,
        updateTeam6Player14LastName
      ],
      [
        updateTeam6Player15FirstName,
        updateTeam6Player15LastName
      ],
      [
        updateTeam6Player16FirstName,
        updateTeam6Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam7Player1FirstName,
        updateTeam7Player1LastName
      ],
      [
        updateTeam7Player2FirstName,
        updateTeam7Player2LastName
      ],
      [
        updateTeam7Player3FirstName,
        updateTeam7Player3LastName
      ],
      [
        updateTeam7Player4FirstName,
        updateTeam7Player4LastName
      ],
      [
        updateTeam7Player5FirstName,
        updateTeam7Player5LastName
      ],
      [
        updateTeam7Player6FirstName,
        updateTeam7Player6LastName
      ],
      [
        updateTeam7Player7FirstName,
        updateTeam7Player7LastName
      ],
      [
        updateTeam7Player8FirstName,
        updateTeam7Player8LastName
      ],
      [
        updateTeam7Player9FirstName,
        updateTeam7Player9LastName
      ],
      [
        updateTeam7Player10FirstName,
        updateTeam7Player10LastName
      ],
      [
        updateTeam7Player11FirstName,
        updateTeam7Player11LastName
      ],
      [
        updateTeam7Player12FirstName,
        updateTeam7Player12LastName
      ],
      [
        updateTeam7Player13FirstName,
        updateTeam7Player13LastName
      ],
      [
        updateTeam7Player14FirstName,
        updateTeam7Player14LastName
      ],
      [
        updateTeam7Player15FirstName,
        updateTeam7Player15LastName
      ],
      [
        updateTeam7Player16FirstName,
        updateTeam7Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam8Player1FirstName,
        updateTeam8Player1LastName
      ],
      [
        updateTeam8Player2FirstName,
        updateTeam8Player2LastName
      ],
      [
        updateTeam8Player3FirstName,
        updateTeam8Player3LastName
      ],
      [
        updateTeam8Player4FirstName,
        updateTeam8Player4LastName
      ],
      [
        updateTeam8Player5FirstName,
        updateTeam8Player5LastName
      ],
      [
        updateTeam8Player6FirstName,
        updateTeam8Player6LastName
      ],
      [
        updateTeam8Player7FirstName,
        updateTeam8Player7LastName
      ],
      [
        updateTeam8Player8FirstName,
        updateTeam8Player8LastName
      ],
      [
        updateTeam8Player9FirstName,
        updateTeam8Player9LastName
      ],
      [
        updateTeam8Player10FirstName,
        updateTeam8Player10LastName
      ],
      [
        updateTeam8Player11FirstName,
        updateTeam8Player11LastName
      ],
      [
        updateTeam8Player12FirstName,
        updateTeam8Player12LastName
      ],
      [
        updateTeam8Player13FirstName,
        updateTeam8Player13LastName
      ],
      [
        updateTeam8Player14FirstName,
        updateTeam8Player14LastName
      ],
      [
        updateTeam8Player15FirstName,
        updateTeam8Player15LastName
      ],
      [
        updateTeam8Player16FirstName,
        updateTeam8Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam9Player1FirstName,
        updateTeam9Player1LastName
      ],
      [
        updateTeam9Player2FirstName,
        updateTeam9Player2LastName
      ],
      [
        updateTeam9Player3FirstName,
        updateTeam9Player3LastName
      ],
      [
        updateTeam9Player4FirstName,
        updateTeam9Player4LastName
      ],
      [
        updateTeam9Player5FirstName,
        updateTeam9Player5LastName
      ],
      [
        updateTeam9Player6FirstName,
        updateTeam9Player6LastName
      ],
      [
        updateTeam9Player7FirstName,
        updateTeam9Player7LastName
      ],
      [
        updateTeam9Player8FirstName,
        updateTeam9Player8LastName
      ],
      [
        updateTeam9Player9FirstName,
        updateTeam9Player9LastName
      ],
      [
        updateTeam9Player10FirstName,
        updateTeam9Player10LastName
      ],
      [
        updateTeam9Player11FirstName,
        updateTeam9Player11LastName
      ],
      [
        updateTeam9Player12FirstName,
        updateTeam9Player12LastName
      ],
      [
        updateTeam9Player13FirstName,
        updateTeam9Player13LastName
      ],
      [
        updateTeam9Player14FirstName,
        updateTeam9Player14LastName
      ],
      [
        updateTeam9Player15FirstName,
        updateTeam9Player15LastName
      ],
      [
        updateTeam9Player16FirstName,
        updateTeam9Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam10Player1FirstName,
        updateTeam10Player1LastName
      ],
      [
        updateTeam10Player2FirstName,
        updateTeam10Player2LastName
      ],
      [
        updateTeam10Player3FirstName,
        updateTeam10Player3LastName
      ],
      [
        updateTeam10Player4FirstName,
        updateTeam10Player4LastName
      ],
      [
        updateTeam10Player5FirstName,
        updateTeam10Player5LastName
      ],
      [
        updateTeam10Player6FirstName,
        updateTeam10Player6LastName
      ],
      [
        updateTeam10Player7FirstName,
        updateTeam10Player7LastName
      ],
      [
        updateTeam10Player8FirstName,
        updateTeam10Player8LastName
      ],
      [
        updateTeam10Player9FirstName,
        updateTeam10Player9LastName
      ],
      [
        updateTeam10Player10FirstName,
        updateTeam10Player10LastName
      ],
      [
        updateTeam10Player11FirstName,
        updateTeam10Player11LastName
      ],
      [
        updateTeam10Player12FirstName,
        updateTeam10Player12LastName
      ],
      [
        updateTeam10Player13FirstName,
        updateTeam10Player13LastName
      ],
      [
        updateTeam10Player14FirstName,
        updateTeam10Player14LastName
      ],
      [
        updateTeam10Player15FirstName,
        updateTeam10Player15LastName
      ],
      [
        updateTeam10Player16FirstName,
        updateTeam10Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam11Player1FirstName,
        updateTeam11Player1LastName
      ],
      [
        updateTeam11Player2FirstName,
        updateTeam11Player2LastName
      ],
      [
        updateTeam11Player3FirstName,
        updateTeam11Player3LastName
      ],
      [
        updateTeam11Player4FirstName,
        updateTeam11Player4LastName
      ],
      [
        updateTeam11Player5FirstName,
        updateTeam11Player5LastName
      ],
      [
        updateTeam11Player6FirstName,
        updateTeam11Player6LastName
      ],
      [
        updateTeam11Player7FirstName,
        updateTeam11Player7LastName
      ],
      [
        updateTeam11Player8FirstName,
        updateTeam11Player8LastName
      ],
      [
        updateTeam11Player9FirstName,
        updateTeam11Player9LastName
      ],
      [
        updateTeam11Player10FirstName,
        updateTeam11Player10LastName
      ],
      [
        updateTeam11Player11FirstName,
        updateTeam11Player11LastName
      ],
      [
        updateTeam11Player12FirstName,
        updateTeam11Player12LastName
      ],
      [
        updateTeam11Player13FirstName,
        updateTeam11Player13LastName
      ],
      [
        updateTeam11Player14FirstName,
        updateTeam11Player14LastName
      ],
      [
        updateTeam11Player15FirstName,
        updateTeam11Player15LastName
      ],
      [
        updateTeam11Player16FirstName,
        updateTeam11Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam12Player1FirstName,
        updateTeam12Player1LastName
      ],
      [
        updateTeam12Player2FirstName,
        updateTeam12Player2LastName
      ],
      [
        updateTeam12Player3FirstName,
        updateTeam12Player3LastName
      ],
      [
        updateTeam12Player4FirstName,
        updateTeam12Player4LastName
      ],
      [
        updateTeam12Player5FirstName,
        updateTeam12Player5LastName
      ],
      [
        updateTeam12Player6FirstName,
        updateTeam12Player6LastName
      ],
      [
        updateTeam12Player7FirstName,
        updateTeam12Player7LastName
      ],
      [
        updateTeam12Player8FirstName,
        updateTeam12Player8LastName
      ],
      [
        updateTeam12Player9FirstName,
        updateTeam12Player9LastName
      ],
      [
        updateTeam12Player10FirstName,
        updateTeam12Player10LastName
      ],
      [
        updateTeam12Player11FirstName,
        updateTeam12Player11LastName
      ],
      [
        updateTeam12Player12FirstName,
        updateTeam12Player12LastName
      ],
      [
        updateTeam12Player13FirstName,
        updateTeam12Player13LastName
      ],
      [
        updateTeam12Player14FirstName,
        updateTeam12Player14LastName
      ],
      [
        updateTeam12Player15FirstName,
        updateTeam12Player15LastName
      ],
      [
        updateTeam12Player16FirstName,
        updateTeam12Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam13Player1FirstName,
        updateTeam13Player1LastName
      ],
      [
        updateTeam13Player2FirstName,
        updateTeam13Player2LastName
      ],
      [
        updateTeam13Player3FirstName,
        updateTeam13Player3LastName
      ],
      [
        updateTeam13Player4FirstName,
        updateTeam13Player4LastName
      ],
      [
        updateTeam13Player5FirstName,
        updateTeam13Player5LastName
      ],
      [
        updateTeam13Player6FirstName,
        updateTeam13Player6LastName
      ],
      [
        updateTeam13Player7FirstName,
        updateTeam13Player7LastName
      ],
      [
        updateTeam13Player8FirstName,
        updateTeam13Player8LastName
      ],
      [
        updateTeam13Player9FirstName,
        updateTeam13Player9LastName
      ],
      [
        updateTeam13Player10FirstName,
        updateTeam13Player10LastName
      ],
      [
        updateTeam13Player11FirstName,
        updateTeam13Player11LastName
      ],
      [
        updateTeam13Player12FirstName,
        updateTeam13Player12LastName
      ],
      [
        updateTeam13Player13FirstName,
        updateTeam13Player13LastName
      ],
      [
        updateTeam13Player14FirstName,
        updateTeam13Player14LastName
      ],
      [
        updateTeam13Player15FirstName,
        updateTeam13Player15LastName
      ],
      [
        updateTeam13Player16FirstName,
        updateTeam13Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam14Player1FirstName,
        updateTeam14Player1LastName
      ],
      [
        updateTeam14Player2FirstName,
        updateTeam14Player2LastName
      ],
      [
        updateTeam14Player3FirstName,
        updateTeam14Player3LastName
      ],
      [
        updateTeam14Player4FirstName,
        updateTeam14Player4LastName
      ],
      [
        updateTeam14Player5FirstName,
        updateTeam14Player5LastName
      ],
      [
        updateTeam14Player6FirstName,
        updateTeam14Player6LastName
      ],
      [
        updateTeam14Player7FirstName,
        updateTeam14Player7LastName
      ],
      [
        updateTeam14Player8FirstName,
        updateTeam14Player8LastName
      ],
      [
        updateTeam14Player9FirstName,
        updateTeam14Player9LastName
      ],
      [
        updateTeam14Player10FirstName,
        updateTeam14Player10LastName
      ],
      [
        updateTeam14Player11FirstName,
        updateTeam14Player11LastName
      ],
      [
        updateTeam14Player12FirstName,
        updateTeam14Player12LastName
      ],
      [
        updateTeam14Player13FirstName,
        updateTeam14Player13LastName
      ],
      [
        updateTeam14Player14FirstName,
        updateTeam14Player14LastName
      ],
      [
        updateTeam14Player15FirstName,
        updateTeam14Player15LastName
      ],
      [
        updateTeam14Player16FirstName,
        updateTeam14Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam15Player1FirstName,
        updateTeam15Player1LastName
      ],
      [
        updateTeam15Player2FirstName,
        updateTeam15Player2LastName
      ],
      [
        updateTeam15Player3FirstName,
        updateTeam15Player3LastName
      ],
      [
        updateTeam15Player4FirstName,
        updateTeam15Player4LastName
      ],
      [
        updateTeam15Player5FirstName,
        updateTeam15Player5LastName
      ],
      [
        updateTeam15Player6FirstName,
        updateTeam15Player6LastName
      ],
      [
        updateTeam15Player7FirstName,
        updateTeam15Player7LastName
      ],
      [
        updateTeam15Player8FirstName,
        updateTeam15Player8LastName
      ],
      [
        updateTeam15Player9FirstName,
        updateTeam15Player9LastName
      ],
      [
        updateTeam15Player10FirstName,
        updateTeam15Player10LastName
      ],
      [
        updateTeam15Player11FirstName,
        updateTeam15Player11LastName
      ],
      [
        updateTeam15Player12FirstName,
        updateTeam15Player12LastName
      ],
      [
        updateTeam15Player13FirstName,
        updateTeam15Player13LastName
      ],
      [
        updateTeam15Player14FirstName,
        updateTeam15Player14LastName
      ],
      [
        updateTeam15Player15FirstName,
        updateTeam15Player15LastName
      ],
      [
        updateTeam15Player16FirstName,
        updateTeam15Player16LastName
      ],
    ],
    [
      //player number
      [
        //first name is 0, last name is 1
        updateTeam16Player1FirstName,
        updateTeam16Player1LastName
      ],
      [
        updateTeam16Player2FirstName,
        updateTeam16Player2LastName
      ],
      [
        updateTeam16Player3FirstName,
        updateTeam16Player3LastName
      ],
      [
        updateTeam16Player4FirstName,
        updateTeam16Player4LastName
      ],
      [
        updateTeam16Player5FirstName,
        updateTeam16Player5LastName
      ],
      [
        updateTeam16Player6FirstName,
        updateTeam16Player6LastName
      ],
      [
        updateTeam16Player7FirstName,
        updateTeam16Player7LastName
      ],
      [
        updateTeam16Player8FirstName,
        updateTeam16Player8LastName
      ],
      [
        updateTeam16Player9FirstName,
        updateTeam16Player9LastName
      ],
      [
        updateTeam16Player10FirstName,
        updateTeam16Player10LastName
      ],
      [
        updateTeam16Player11FirstName,
        updateTeam16Player11LastName
      ],
      [
        updateTeam16Player12FirstName,
        updateTeam16Player12LastName
      ],
      [
        updateTeam16Player13FirstName,
        updateTeam16Player13LastName
      ],
      [
        updateTeam16Player14FirstName,
        updateTeam16Player14LastName
      ],
      [
        updateTeam16Player15FirstName,
        updateTeam16Player15LastName
      ],
      [
        updateTeam16Player16FirstName,
        updateTeam16Player16LastName
      ],
    ],
  ];
  
  
  
  
  
  
   const playerFieldNames = [
    //team number
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team1PlayerInfo.team1Player1FirstName,
        team1PlayerInfo.team1Player1LastName,
  
      ],
      [
        team1PlayerInfo.team1Player2FirstName,
        team1PlayerInfo.team1Player2LastName
      ],
      [
        team1PlayerInfo.team1Player3FirstName,
        team1PlayerInfo.team1Player3LastName
      ],
      [
        team1PlayerInfo.team1Player4FirstName,
        team1PlayerInfo.team1Player4LastName
      ],
      [
        team1PlayerInfo.team1Player5FirstName,
        team1PlayerInfo.team1Player5LastName
      ],
      [
        team1PlayerInfo.team1Player6FirstName,
        team1PlayerInfo.team1Player6LastName
      ],
      [
        team1PlayerInfo.team1Player7FirstName,
        team1PlayerInfo.team1Player7LastName
      ],
      [
        team1PlayerInfo.team1Player8FirstName,
        team1PlayerInfo.team1Player8LastName
      ],
      [
        team1PlayerInfo.team1Player9FirstName,
        team1PlayerInfo.team1Player9LastName
      ],
      [
        team1PlayerInfo.team1Player10FirstName,
        team1PlayerInfo.team1Player10LastName
      ],
      [
        team1PlayerInfo.team1Player11FirstName,
        team1PlayerInfo.team1Player11LastName
      ],
      [
        team1PlayerInfo.team1Player12FirstName,
        team1PlayerInfo.team1Player12LastName
      ],
      [
        team1PlayerInfo.team1Player13FirstName,
        team1PlayerInfo.team1Player13LastName
      ],
      [
        team1PlayerInfo.team1Player14FirstName,
        team1PlayerInfo.team1Player14LastName
      ],
      [
        team1PlayerInfo.team1Player15FirstName,
        team1PlayerInfo.team1Player15LastName
      ],
      [
        team1PlayerInfo.team1Player16FirstName,
        team1PlayerInfo.team1Player16LastName
      ]
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team2PlayerInfo.team2Player1FirstName,
        team2PlayerInfo.team2Player1LastName
      ],
      [
        team2PlayerInfo.team2Player2FirstName,
        team2PlayerInfo.team2Player2LastName
      ],
      [
        team2PlayerInfo.team2Player3FirstName,
        team2PlayerInfo.team2Player3LastName
      ],
      [
        team2PlayerInfo.team2Player4FirstName,
        team2PlayerInfo.team2Player4LastName
      ],
      [
        team2PlayerInfo.team2Player5FirstName,
        team2PlayerInfo.team2Player5LastName
      ],
      [
        team2PlayerInfo.team2Player6FirstName,
        team2PlayerInfo.team2Player6LastName
      ],
      [
        team2PlayerInfo.team2Player7FirstName,
        team2PlayerInfo.team2Player7LastName
      ],
      [
        team2PlayerInfo.team2Player8FirstName,
        team2PlayerInfo.team2Player8LastName
      ],
      [
        team2PlayerInfo.team2Player9FirstName,
        team2PlayerInfo.team2Player9LastName
      ],
      [
        team2PlayerInfo.team2Player10FirstName,
        team2PlayerInfo.team2Player10LastName
      ],
      [
        team2PlayerInfo.team2Player11FirstName,
        team2PlayerInfo.team2Player11LastName
      ],
      [
        team2PlayerInfo.team2Player12FirstName,
        team2PlayerInfo.team2Player12LastName
      ],
      [
        team2PlayerInfo.team2Player13FirstName,
        team2PlayerInfo.team2Player13LastName
      ],
      [
        team2PlayerInfo.team2Player14FirstName,
        team2PlayerInfo.team2Player14LastName
      ],
      [
        team2PlayerInfo.team2Player15FirstName,
        team2PlayerInfo.team2Player15LastName
      ],
      [
        team2PlayerInfo.team2Player16FirstName,
        team2PlayerInfo.team2Player16LastName
      ],
    ],
  
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team3PlayerInfo.team3Player1FirstName,
        team3PlayerInfo.team3Player1LastName
      ],
      [
        team3PlayerInfo.team3Player2FirstName,
        team3PlayerInfo.team3Player2LastName
      ],
      [
        team3PlayerInfo.team3Player3FirstName,
        team3PlayerInfo.team3Player3LastName
      ],
      [
        team3PlayerInfo.team3Player4FirstName,
        team3PlayerInfo.team3Player4LastName
      ],
      [
        team3PlayerInfo.team3Player5FirstName,
        team3PlayerInfo.team3Player5LastName
      ],
      [
        team3PlayerInfo.team3Player6FirstName,
        team3PlayerInfo.team3Player6LastName
      ],
      [
        team3PlayerInfo.team3Player7FirstName,
        team3PlayerInfo.team3Player7LastName
      ],
      [
        team3PlayerInfo.team3Player8FirstName,
        team3PlayerInfo.team3Player8LastName
      ],
      [
        team3PlayerInfo.team3Player9FirstName,
        team3PlayerInfo.team3Player9LastName
      ],
      [
        team3PlayerInfo.team3Player10FirstName,
        team3PlayerInfo.team3Player10LastName
      ],
      [
        team3PlayerInfo.team3Player11FirstName,
        team3PlayerInfo.team3Player11LastName
      ],
      [
        team3PlayerInfo.team3Player12FirstName,
        team3PlayerInfo.team3Player12LastName
      ],
      [
        team3PlayerInfo.team3Player13FirstName,
        team3PlayerInfo.team3Player13LastName
      ],
      [
        team3PlayerInfo.team3Player14FirstName,
        team3PlayerInfo.team3Player14LastName
      ],
      [
        team3PlayerInfo.team3Player15FirstName,
        team3PlayerInfo.team3Player15LastName
      ],
      [
        team3PlayerInfo.team3Player16FirstName,
        team3PlayerInfo.team3Player16LastName
      ],
    ],
  
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team4PlayerInfo.team4Player1FirstName,
        team4PlayerInfo.team4Player1LastName
      ],
  
      [
        team4PlayerInfo.team4Player2FirstName,
        team4PlayerInfo.team4Player2LastName
      ],
      [
        team4PlayerInfo.team4Player3FirstName,
        team4PlayerInfo.team4Player3LastName
      ],
      [
        team4PlayerInfo.team4Player4FirstName,
        team4PlayerInfo.team4Player4LastName
      ],
      [
        team4PlayerInfo.team4Player5FirstName,
        team4PlayerInfo.team4Player5LastName
      ],
      [
        team4PlayerInfo.team4Player6FirstName,
        team4PlayerInfo.team4Player6LastName
      ],
      [
        team4PlayerInfo.team4Player7FirstName,
        team4PlayerInfo.team4Player7LastName
      ],
      [
        team4PlayerInfo.team4Player8FirstName,
        team4PlayerInfo.team4Player8LastName
      ],
      [
        team4PlayerInfo.team4Player9FirstName,
        team4PlayerInfo.team4Player9LastName
      ],
      [
        team4PlayerInfo.team4Player10FirstName,
        team4PlayerInfo.team4Player10LastName
      ],
      [
        team4PlayerInfo.team4Player11FirstName,
        team4PlayerInfo.team4Player11LastName
      ],
      [
        team4PlayerInfo.team4Player12FirstName,
        team4PlayerInfo.team4Player12LastName
      ],
      [
        team4PlayerInfo.team4Player13FirstName,
        team4PlayerInfo.team4Player13LastName
      ],
      [
        team4PlayerInfo.team4Player14FirstName,
        team4PlayerInfo.team4Player14LastName
      ],
      [
        team4PlayerInfo.team4Player15FirstName,
        team4PlayerInfo.team4Player15LastName
      ],
      [
        team4PlayerInfo.team4Player16FirstName,
        team4PlayerInfo.team4Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team5PlayerInfo.team5Player1FirstName,
        team5PlayerInfo.team5Player1LastName
      ],
      [
        team5PlayerInfo.team5Player2FirstName,
        team5PlayerInfo.team5Player2LastName
      ],
      [
        team5PlayerInfo.team5Player3FirstName,
        team5PlayerInfo.team5Player3LastName
      ],
      [
        team5PlayerInfo.team5Player4FirstName,
        team5PlayerInfo.team5Player4LastName
      ],
      [
        team5PlayerInfo.team5Player5FirstName,
        team5PlayerInfo.team5Player5LastName
      ],
      [
        team5PlayerInfo.team5Player6FirstName,
        team5PlayerInfo.team5Player6LastName
      ],
      [
        team5PlayerInfo.team5Player7FirstName,
        team5PlayerInfo.team5Player7LastName
      ],
      [
        team5PlayerInfo.team5Player8FirstName,
        team5PlayerInfo.team5Player8LastName
      ],
      [
        team5PlayerInfo.team5Player9FirstName,
        team5PlayerInfo.team5Player9LastName
      ],
      [
        team5PlayerInfo.team5Player10FirstName,
        team5PlayerInfo.team5Player10LastName
      ],
      [
        team5PlayerInfo.team5Player11FirstName,
        team5PlayerInfo.team5Player11LastName
      ],
      [
        team5PlayerInfo.team5Player12FirstName,
        team5PlayerInfo.team5Player12LastName
      ],
      [
        team5PlayerInfo.team5Player13FirstName,
        team5PlayerInfo.team5Player13LastName
      ],
      [
        team5PlayerInfo.team5Player14FirstName,
        team5PlayerInfo.team5Player14LastName
      ],
      [
        team5PlayerInfo.team5Player15FirstName,
        team5PlayerInfo.team5Player15LastName
      ],
      [
        team5PlayerInfo.team5Player16FirstName,
        team5PlayerInfo.team5Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team6PlayerInfo.team6Player1FirstName,
        team6PlayerInfo.team6Player1LastName
      ],
      [
        team6PlayerInfo.team6Player2FirstName,
        team6PlayerInfo.team6Player2LastName
      ],
      [
        team6PlayerInfo.team6Player3FirstName,
        team6PlayerInfo.team6Player3LastName
      ],
      [
        team6PlayerInfo.team6Player4FirstName,
        team6PlayerInfo.team6Player4LastName
      ],
      [
        team6PlayerInfo.team6Player5FirstName,
        team6PlayerInfo.team6Player5LastName
      ],
      [
        team6PlayerInfo.team6Player6FirstName,
        team6PlayerInfo.team6Player6LastName
      ],
      [
        team6PlayerInfo.team6Player7FirstName,
        team6PlayerInfo.team6Player7LastName
      ],
      [
        team6PlayerInfo.team6Player8FirstName,
        team6PlayerInfo.team6Player8LastName
      ],
      [
        team6PlayerInfo.team6Player9FirstName,
        team6PlayerInfo.team6Player9LastName
      ],
      [
        team6PlayerInfo.team6Player10FirstName,
        team6PlayerInfo.team6Player10LastName
      ],
      [
        team6PlayerInfo.team6Player11FirstName,
        team6PlayerInfo.team6Player11LastName
      ],
      [
        team6PlayerInfo.team6Player12FirstName,
        team6PlayerInfo.team6Player12LastName
      ],
      [
        team6PlayerInfo.team6Player13FirstName,
        team6PlayerInfo.team6Player13LastName
      ],
      [
        team6PlayerInfo.team6Player14FirstName,
        team6PlayerInfo.team6Player14LastName
      ],
      [
        team6PlayerInfo.team6Player15FirstName,
        team6PlayerInfo.team6Player15LastName
      ],
      [
        team6PlayerInfo.team6Player16FirstName,
        team6PlayerInfo.team6Player16LastName
      ],
    ],
  
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team7PlayerInfo.team7Player1FirstName,
        team7PlayerInfo.team7Player1LastName
      ],
      [
        team7PlayerInfo.team7Player2FirstName,
        team7PlayerInfo.team7Player2LastName
      ],
      [
        team7PlayerInfo.team7Player3FirstName,
        team7PlayerInfo.team7Player3LastName
      ],
      [
        team7PlayerInfo.team7Player4FirstName,
        team7PlayerInfo.team7Player4LastName
      ],
      [
        team7PlayerInfo.team7Player5FirstName,
        team7PlayerInfo.team7Player5LastName
      ],
      [
        team7PlayerInfo.team7Player6FirstName,
        team7PlayerInfo.team7Player6LastName
      ],
      [
        team7PlayerInfo.team7Player7FirstName,
        team7PlayerInfo.team7Player7LastName
      ],
      [
        team7PlayerInfo.team7Player8FirstName,
        team7PlayerInfo.team7Player8LastName
      ],
      [
        team7PlayerInfo.team7Player9FirstName,
        team7PlayerInfo.team7Player9LastName
      ],
      [
        team7PlayerInfo.team7Player10FirstName,
        team7PlayerInfo.team7Player10LastName
      ],
      [
        team7PlayerInfo.team7Player11FirstName,
        team7PlayerInfo.team7Player11LastName
      ],
      [
        team7PlayerInfo.team7Player12FirstName,
        team7PlayerInfo.team7Player12LastName
      ],
      [
        team7PlayerInfo.team7Player13FirstName,
        team7PlayerInfo.team7Player13LastName
      ],
      [
        team7PlayerInfo.team7Player14FirstName,
        team7PlayerInfo.team7Player14LastName
      ],
      [
        team7PlayerInfo.team7Player15FirstName,
        team7PlayerInfo.team7Player15LastName
      ],
      [
        team7PlayerInfo.team7Player16FirstName,
        team7PlayerInfo.team7Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team8PlayerInfo.team8Player1FirstName,
        team8PlayerInfo.team8Player1LastName
      ],
      [
        team8PlayerInfo.team8Player2FirstName,
        team8PlayerInfo.team8Player2LastName
      ],
      [
        team8PlayerInfo.team8Player3FirstName,
        team8PlayerInfo.team8Player3LastName
      ],
      [
        team8PlayerInfo.team8Player4FirstName,
        team8PlayerInfo.team8Player4LastName
      ],
      [
        team8PlayerInfo.team8Player5FirstName,
        team8PlayerInfo.team8Player5LastName
      ],
      [
        team8PlayerInfo.team8Player6FirstName,
        team8PlayerInfo.team8Player6LastName
      ],
      [
        team8PlayerInfo.team8Player7FirstName,
        team8PlayerInfo.team8Player7LastName
      ],
      [
        team8PlayerInfo.team8Player8FirstName,
        team8PlayerInfo.team8Player8LastName
      ],
      [
        team8PlayerInfo.team8Player9FirstName,
        team8PlayerInfo.team8Player9LastName
      ],
      [
        team8PlayerInfo.team8Player10FirstName,
        team8PlayerInfo.team8Player10LastName
      ],
      [
        team8PlayerInfo.team8Player11FirstName,
        team8PlayerInfo.team8Player11LastName
      ],
      [
        team8PlayerInfo.team8Player12FirstName,
        team8PlayerInfo.team8Player12LastName
      ],
      [
        team8PlayerInfo.team8Player13FirstName,
        team8PlayerInfo.team8Player13LastName
      ],
      [
        team8PlayerInfo.team8Player14FirstName,
        team8PlayerInfo.team8Player14LastName
      ],
      [
        team8PlayerInfo.team8Player15FirstName,
        team8PlayerInfo.team8Player15LastName
      ],
      [
        team8PlayerInfo.team8Player16FirstName,
        team8PlayerInfo.team8Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team9PlayerInfo.team9Player1FirstName,
        team9PlayerInfo.team9Player1LastName
      ],
      [
        team9PlayerInfo.team9Player2FirstName,
        team9PlayerInfo.team9Player2LastName
      ],
      [
        team9PlayerInfo.team9Player3FirstName,
        team9PlayerInfo.team9Player3LastName
      ],
      [
        team9PlayerInfo.team9Player4FirstName,
        team9PlayerInfo.team9Player4LastName
      ],
      [
        team9PlayerInfo.team9Player5FirstName,
        team9PlayerInfo.team9Player5LastName
      ],
      [
        team9PlayerInfo.team9Player6FirstName,
        team9PlayerInfo.team9Player6LastName
      ],
      [
        team9PlayerInfo.team9Player7FirstName,
        team9PlayerInfo.team9Player7LastName
      ],
      [
        team9PlayerInfo.team9Player8FirstName,
        team9PlayerInfo.team9Player8LastName
      ],
      [
        team9PlayerInfo.team9Player9FirstName,
        team9PlayerInfo.team9Player9LastName
      ],
      [
        team9PlayerInfo.team9Player10FirstName,
        team9PlayerInfo.team9Player10LastName
      ],
      [
        team9PlayerInfo.team9Player11FirstName,
        team9PlayerInfo.team9Player11LastName
      ],
      [
        team9PlayerInfo.team9Player12FirstName,
        team9PlayerInfo.team9Player12LastName
      ],
      [
        team9PlayerInfo.team9Player13FirstName,
        team9PlayerInfo.team9Player13LastName
      ],
      [
        team9PlayerInfo.team9Player14FirstName,
        team9PlayerInfo.team9Player14LastName
      ],
      [
        team9PlayerInfo.team9Player15FirstName,
        team9PlayerInfo.team9Player15LastName
      ],
      [
        team9PlayerInfo.team9Player16FirstName,
        team9PlayerInfo.team9Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team10PlayerInfo.team10Player1FirstName,
        team10PlayerInfo.team10Player1LastName
      ],
      [
        team10PlayerInfo.team10Player2FirstName,
        team10PlayerInfo.team10Player2LastName
      ],
      [
        team10PlayerInfo.team10Player3FirstName,
        team10PlayerInfo.team10Player3LastName
      ],
      [
        team10PlayerInfo.team10Player4FirstName,
        team10PlayerInfo.team10Player4LastName
      ],
      [
        team10PlayerInfo.team10Player5FirstName,
        team10PlayerInfo.team10Player5LastName
      ],
      [
        team10PlayerInfo.team10Player6FirstName,
        team10PlayerInfo.team10Player6LastName
      ],
      [
        team10PlayerInfo.team10Player7FirstName,
        team10PlayerInfo.team10Player7LastName
      ],
      [
        team10PlayerInfo.team10Player8FirstName,
        team10PlayerInfo.team10Player8LastName
      ],
      [
        team10PlayerInfo.team10Player9FirstName,
        team10PlayerInfo.team10Player9LastName
      ],
      [
        team10PlayerInfo.team10Player10FirstName,
        team10PlayerInfo.team10Player10LastName
      ],
      [
        team10PlayerInfo.team10Player11FirstName,
        team10PlayerInfo.team10Player11LastName
      ],
      [
        team10PlayerInfo.team10Player12FirstName,
        team10PlayerInfo.team10Player12LastName
      ],
      [
        team10PlayerInfo.team10Player13FirstName,
        team10PlayerInfo.team10Player13LastName
      ],
      [
        team10PlayerInfo.team10Player14FirstName,
        team10PlayerInfo.team10Player14LastName
      ],
      [
        team10PlayerInfo.team10Player15FirstName,
        team10PlayerInfo.team10Player15LastName
      ],
      [
        team10PlayerInfo.team10Player16FirstName,
        team10PlayerInfo.team10Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team11PlayerInfo.team11Player1FirstName,
        team11PlayerInfo.team11Player1LastName
      ],
      [
        team11PlayerInfo.team11Player2FirstName,
        team11PlayerInfo.team11Player2LastName
      ],
      [
        team11PlayerInfo.team11Player3FirstName,
        team11PlayerInfo.team11Player3LastName
      ],
      [
        team11PlayerInfo.team11Player4FirstName,
        team11PlayerInfo.team11Player4LastName
      ],
      [
        team11PlayerInfo.team11Player5FirstName,
        team11PlayerInfo.team11Player5LastName
      ],
      [
        team11PlayerInfo.team11Player6FirstName,
        team11PlayerInfo.team11Player6LastName
      ],
      [
        team11PlayerInfo.team11Player7FirstName,
        team11PlayerInfo.team11Player7LastName
      ],
      [
        team11PlayerInfo.team11Player8FirstName,
        team11PlayerInfo.team11Player8LastName
      ],
      [
        team11PlayerInfo.team11Player9FirstName,
        team11PlayerInfo.team11Player9LastName
      ],
      [
        team11PlayerInfo.team11Player10FirstName,
        team11PlayerInfo.team11Player10LastName
      ],
      [
        team11PlayerInfo.team11Player11FirstName,
        team11PlayerInfo.team11Player11LastName
      ],
      [
        team11PlayerInfo.team11Player12FirstName,
        team11PlayerInfo.team11Player12LastName
      ],
      [
        team11PlayerInfo.team11Player13FirstName,
        team11PlayerInfo.team11Player13LastName
      ],
      [
        team11PlayerInfo.team11Player14FirstName,
        team11PlayerInfo.team11Player14LastName
      ],
      [
        team11PlayerInfo.team11Player15FirstName,
        team11PlayerInfo.team11Player15LastName
      ],
      [
        team11PlayerInfo.team11Player16FirstName,
        team11PlayerInfo.team11Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team12PlayerInfo.team12Player1FirstName,
        team12PlayerInfo.team12Player1LastName
      ],
      [
        team12PlayerInfo.team12Player2FirstName,
        team12PlayerInfo.team12Player2LastName
      ],
      [
        team12PlayerInfo.team12Player3FirstName,
        team12PlayerInfo.team12Player3LastName
      ],
      [
        team12PlayerInfo.team12Player4FirstName,
        team12PlayerInfo.team12Player4LastName
      ],
      [
        team12PlayerInfo.team12Player5FirstName,
        team12PlayerInfo.team12Player5LastName
      ],
      [
        team12PlayerInfo.team12Player6FirstName,
        team12PlayerInfo.team12Player6LastName
      ],
      [
        team12PlayerInfo.team12Player7FirstName,
        team12PlayerInfo.team12Player7LastName
      ],
      [
        team12PlayerInfo.team12Player8FirstName,
        team12PlayerInfo.team12Player8LastName
      ],
      [
        team12PlayerInfo.team12Player9FirstName,
        team12PlayerInfo.team12Player9LastName
      ],
      [
        team12PlayerInfo.team12Player10FirstName,
        team12PlayerInfo.team12Player10LastName
      ],
      [
        team12PlayerInfo.team12Player11FirstName,
        team12PlayerInfo.team12Player11LastName
      ],
      [
        team12PlayerInfo.team12Player12FirstName,
        team12PlayerInfo.team12Player12LastName
      ],
      [
        team12PlayerInfo.team12Player13FirstName,
        team12PlayerInfo.team12Player13LastName
      ],
      [
        team12PlayerInfo.team12Player14FirstName,
        team12PlayerInfo.team12Player14LastName
      ],
      [
        team12PlayerInfo.team12Player15FirstName,
        team12PlayerInfo.team12Player15LastName
      ],
      [
        team12PlayerInfo.team12Player16FirstName,
        team12PlayerInfo.team12Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team13PlayerInfo.team13Player1FirstName,
        team13PlayerInfo.team13Player1LastName
      ],
      [
        team13PlayerInfo.team13Player2FirstName,
        team13PlayerInfo.team13Player2LastName
      ],
      [
        team13PlayerInfo.team13Player3FirstName,
        team13PlayerInfo.team13Player3LastName
      ],
      [
        team13PlayerInfo.team13Player4FirstName,
        team13PlayerInfo.team13Player4LastName
      ],
      [
        team13PlayerInfo.team13Player5FirstName,
        team13PlayerInfo.team13Player5LastName
      ],
      [
        team13PlayerInfo.team13Player6FirstName,
        team13PlayerInfo.team13Player6LastName
      ],
      [
        team13PlayerInfo.team13Player7FirstName,
        team13PlayerInfo.team13Player7LastName
      ],
      [
        team13PlayerInfo.team13Player8FirstName,
        team13PlayerInfo.team13Player8LastName
      ],
      [
        team13PlayerInfo.team13Player9FirstName,
        team13PlayerInfo.team13Player9LastName
      ],
      [
        team13PlayerInfo.team13Player10FirstName,
        team13PlayerInfo.team13Player10LastName
      ],
      [
        team13PlayerInfo.team13Player11FirstName,
        team13PlayerInfo.team13Player11LastName
      ],
      [
        team13PlayerInfo.team13Player12FirstName,
        team13PlayerInfo.team13Player12LastName
      ],
      [
        team13PlayerInfo.team13Player13FirstName,
        team13PlayerInfo.team13Player13LastName
      ],
      [
        team13PlayerInfo.team13Player14FirstName,
        team13PlayerInfo.team13Player14LastName
      ],
      [
        team13PlayerInfo.team13Player15FirstName,
        team13PlayerInfo.team13Player15LastName
      ],
      [
        team13PlayerInfo.team13Player16FirstName,
        team13PlayerInfo.team13Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team14PlayerInfo.team14Player1FirstName,
        team14PlayerInfo.team14Player1LastName
      ],
      [
        team14PlayerInfo.team14Player2FirstName,
        team14PlayerInfo.team14Player2LastName
      ],
      [
        team14PlayerInfo.team14Player3FirstName,
        team14PlayerInfo.team14Player3LastName
      ],
      [
        team14PlayerInfo.team14Player4FirstName,
        team14PlayerInfo.team14Player4LastName
      ],
      [
        team14PlayerInfo.team14Player5FirstName,
        team14PlayerInfo.team14Player5LastName
      ],
      [
        team14PlayerInfo.team14Player6FirstName,
        team14PlayerInfo.team14Player6LastName
      ],
      [
        team14PlayerInfo.team14Player7FirstName,
        team14PlayerInfo.team14Player7LastName
      ],
      [
        team14PlayerInfo.team14Player8FirstName,
        team14PlayerInfo.team14Player8LastName
      ],
      [
        team14PlayerInfo.team14Player9FirstName,
        team14PlayerInfo.team14Player9LastName
      ],
      [
        team14PlayerInfo.team14Player10FirstName,
        team14PlayerInfo.team14Player10LastName
      ],
      [
        team14PlayerInfo.team14Player11FirstName,
        team14PlayerInfo.team14Player11LastName
      ],
      [
        team14PlayerInfo.team14Player12FirstName,
        team14PlayerInfo.team14Player12LastName
      ],
      [
        team14PlayerInfo.team14Player13FirstName,
        team14PlayerInfo.team14Player13LastName
      ],
      [
        team14PlayerInfo.team14Player14FirstName,
        team14PlayerInfo.team14Player14LastName
      ],
      [
        team14PlayerInfo.team14Player15FirstName,
        team14PlayerInfo.team14Player15LastName
      ],
      [
        team14PlayerInfo.team14Player16FirstName,
        team14PlayerInfo.team14Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team15PlayerInfo.team15Player1FirstName,
        team15PlayerInfo.team15Player1LastName
      ],
      [
        team15PlayerInfo.team15Player2FirstName,
        team15PlayerInfo.team15Player2LastName
      ],
      [
        team15PlayerInfo.team15Player3FirstName,
        team15PlayerInfo.team15Player3LastName
      ],
      [
        team15PlayerInfo.team15Player4FirstName,
        team15PlayerInfo.team15Player4LastName
      ],
      [
        team15PlayerInfo.team15Player5FirstName,
        team15PlayerInfo.team15Player5LastName
      ],
      [
        team15PlayerInfo.team15Player6FirstName,
        team15PlayerInfo.team15Player6LastName
      ],
      [
        team15PlayerInfo.team15Player7FirstName,
        team15PlayerInfo.team15Player7LastName
      ],
      [
        team15PlayerInfo.team15Player8FirstName,
        team15PlayerInfo.team15Player8LastName
      ],
      [
        team15PlayerInfo.team15Player9FirstName,
        team15PlayerInfo.team15Player9LastName
      ],
      [
        team15PlayerInfo.team15Player10FirstName,
        team15PlayerInfo.team15Player10LastName
      ],
      [
        team15PlayerInfo.team15Player11FirstName,
        team15PlayerInfo.team15Player11LastName
      ],
      [
        team15PlayerInfo.team15Player12FirstName,
        team15PlayerInfo.team15Player12LastName
      ],
      [
        team15PlayerInfo.team15Player13FirstName,
        team15PlayerInfo.team15Player13LastName
      ],
      [
        team15PlayerInfo.team15Player14FirstName,
        team15PlayerInfo.team15Player14LastName
      ],
      [
        team15PlayerInfo.team15Player15FirstName,
        team15PlayerInfo.team15Player15LastName
      ],
      [
        team15PlayerInfo.team15Player16FirstName,
        team15PlayerInfo.team15Player16LastName
      ],
    ],
    [
      //player number
      [
        // values are 0 for first name, 1 for last name
        team16PlayerInfo.team16Player1FirstName,
        team16PlayerInfo.team16Player1LastName
      ],
      [
        team16PlayerInfo.team16Player2FirstName,
        team16PlayerInfo.team16Player2LastName
      ],
      [
        team16PlayerInfo.team16Player3FirstName,
        team16PlayerInfo.team16Player3LastName
      ],
      [
        team16PlayerInfo.team16Player4FirstName,
        team16PlayerInfo.team16Player4LastName
      ],
      [
        team16PlayerInfo.team16Player5FirstName,
        team16PlayerInfo.team16Player5LastName
      ],
      [
        team16PlayerInfo.team16Player6FirstName,
        team16PlayerInfo.team16Player6LastName
      ],
      [
        team16PlayerInfo.team16Player7FirstName,
        team16PlayerInfo.team16Player7LastName
      ],
      [
        team16PlayerInfo.team16Player8FirstName,
        team16PlayerInfo.team16Player8LastName
      ],
      [
        team16PlayerInfo.team16Player9FirstName,
        team16PlayerInfo.team16Player9LastName
      ],
      [
        team16PlayerInfo.team16Player10FirstName,
        team16PlayerInfo.team16Player10LastName
      ],
      [
        team16PlayerInfo.team16Player11FirstName,
        team16PlayerInfo.team16Player11LastName
      ],
      [
        team16PlayerInfo.team16Player12FirstName,
        team16PlayerInfo.team16Player12LastName
      ],
      [
        team16PlayerInfo.team16Player13FirstName,
        team16PlayerInfo.team16Player13LastName
      ],
      [
        team16PlayerInfo.team16Player14FirstName,
        team16PlayerInfo.team16Player14LastName
      ],
      [
        team16PlayerInfo.team16Player15FirstName,
        team16PlayerInfo.team16Player15LastName
      ],
      [
        team16PlayerInfo.team16Player16FirstName,
        team16PlayerInfo.team16Player16LastName
      ],
    ]
  ];
  
  
  
  


  console.log(teamNum)





  const dispatch = useDispatch();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));


  const firstNameFunction = playerFieldFunctions[teamNum - 1][playerNum - 1][0];
  const lastNameFunction = playerFieldFunctions[teamNum - 1][playerNum - 1][1];
  const firstNameValue = playerFieldNames[teamNum - 1][playerNum - 1][0];
  const lastNameValue = playerFieldNames[teamNum - 1][playerNum - 1][1];


  return (

    <FormGroup sx={{ paddingTop: 1.5, }} >
      <FormLabel sx={{ fontWeight: 600 }}>Player #{props.playerNum}</FormLabel>
      <FormGroup row={isMobile ? false : true} >
        <TextField onChange={(event) => dispatch(firstNameFunction(event.target.value))} value={firstNameValue} label='First Name' variant='outlined' margin={'dense'} />
        <TextField onChange={(event) => dispatch(lastNameFunction(event.target.value))} value={lastNameValue} label='Last Name' variant='outlined' margin={'dense'} />
      </FormGroup>
    </FormGroup>
  )
}

export default PlayerFields;
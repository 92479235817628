import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Dashboard from './screens/Dashboard';
import Homepage from './screens/Homepage';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import ErrorPage from "./screens/ErrorPage";
import store from './store/store';
import { Provider } from 'react-redux';
import Main from './screens/Main';
import Tournaments from './screens/Tournaments';
import BracketComponent from './components/BracketComponent';
import Register from './screens/Register';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './components/registration/Payment';
import { app } from './firebaseConfig'
import SignIn from './screens/SignIn';
import RegisterNumOfTeams from './components/registration/RegisterNumOfTeams';
import { firebaseConfig } from './firebaseConfig';
import { doc, getFirestore } from 'firebase/firestore';
import { FirebaseAppProvider, FirestoreProvider, AuthProvider, useFirestoreDocData, useFirestore, useFirebaseApp } from 'reactfire';
import { getAuth } from 'firebase/auth';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import TournamentDetails from './screens/TournamentDetails';
import AdminDashboard from './screens/AdminDashboard';




const App = () => {



  const firestoreInstance = getFirestore(useFirebaseApp());
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp)


  const router = createBrowserRouter([

    {
      path: "/",
      element: <Main />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/tournaments',
          element: <Tournaments />,

        },

      ]
    },
    {
      path: "/homepage",
      element: <Homepage />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/dashboard-admin',
      element: <AdminDashboard />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
      errorElement: <ErrorPage />,


    },
    {
      path: "/main",
      element: <Main />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/brackets",
      element: <BracketComponent />,
      errorElement: <ErrorPage />,

    },
    {
      path: "/register",
      element: <Register />,
      errorElement: <ErrorPage />,

    },

    {
      path: "/payment",
      element: <Payment />,
      errorElement: <ErrorPage />,

    },

    {
      path: "/signin",
      element: <SignIn />,
    },

    {
      path: "/beginpayment",
      element: <RegisterNumOfTeams />,
    },

    {
      path: '/tournaments/:id',
      element: <TournamentDetails />
    },



  ]);





  return (
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={firestoreInstance}>
        <RouterProvider router={router} />
      </FirestoreProvider>
    </AuthProvider>
  )
}




export default App;


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import React, { useState, } from "react";
import { Box, Container, List, ListItem, ListItemText, Divider, Typography, Card, Button, ButtonGroup } from '@mui/material';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import { motion } from 'framer-motion';



const InformationSection = () => {

  const [signUpVisible, setSignUpVisible] = useState(false);
  const [dateAndTimeVisible, setDateAndTimeVisible] = useState(false);
  const [gameDayVisible, setGameDayVisible] = useState(false);



  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const conditionallyRenderSignUp = () => {
    return (
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
        <Box sx={{ height: '300%', display: 'flex', position: 'absolute', top: 0, right: '5%', }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50, width: 50, position: 'sticky', top: '5%', right: '5%', }}>
            <CloseIcon sx={{ height: 40, width: 40, }} onClick={() => setSignUpVisible(false)} />
          </Box>
        </Box>
        <Typography variant="h5" fontWeight={600} sx={{ display: signUpVisible ? 'flex' : 'none', marginBottom: 2, }} >Heres what you need to know!</Typography>
        <Typography variant='subtitle1' sx={{ display: signUpVisible ? null : 'none', }}>
          Clubs and coaches are able to sign up either single team, or multiple teams! More, the better!
          <br /> <br />
          Each program and team must register first and fill out a waiver for each participating player. There will also be registration fee that will need to be paid. Everything must be completed and paid, to attend the tournament.
          <br /> <br />
          We ask for at least 2 coaches to register, that will be on the courts during the tourney. Every program and team, must have players within age and grade required for their division. Here is how:
          <br /> <br />
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='4th Grade - 17U' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='Girls Only' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='Bronze | Silver | Gold' />
            </ListItem>
          </List>

        </Typography>
      </Container>
    )
  }
  const conditionallyRenderEventDataAndTime = () => {
    return (
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
        <Box sx={{ height: '100%', display: 'flex', position: 'absolute', top: 0, right: '5%', }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50, width: 50, position: 'sticky', top: '5%', right: '5%', }}>
            <CloseIcon sx={{ height: 40, width: 40, }} onClick={() => setDateAndTimeVisible(false)} />
          </Box>
        </Box>
        <Typography variant="h5" fontWeight={600} sx={{ display: setDateAndTimeVisible ? 'flex' : 'none', marginBottom: 2, }} >Important Dates To Remember!</Typography>
        <Typography variant='subtitle1' sx={{ display: setDateAndTimeVisible ? null : 'none', }}>
          <strong>Tournament Dates:</strong> July 22nd - July 23rd
          <br /> <br />
          <strong>Game Times:</strong> Saturday: 8 AM - 9PM | Sunday: 8 AM - 9 PM


        </Typography>
      </Container>
    )
  }

  const conditionallyRenderGameDay = () => {
    return (
      <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
        <Box sx={{ height: '550%', display: 'flex', position: 'absolute', top: 0, right: '5%', }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 50, width: 50, position: 'sticky', top: '5%', right: '5%', }}>
            <CloseIcon sx={{ height: 40, width: 40, }} onClick={() => setGameDayVisible(false)} />
          </Box>
        </Box>
        <Typography variant="h5" fontWeight={600} sx={{ display: gameDayVisible ? 'flex' : 'none', marginBottom: 2, }} >When Arriving & Ranking!</Typography>
        <Typography variant='subtitle1' sx={{ display: gameDayVisible ? null : 'none', }}>
          Check-In will be at front desk of the event location.
          <br /> <br />
          Detailed information will be provide for where, when and who your team will be playing.
          <br /> <br />
          Heres few things to keep in mind:
          <br /> <br />
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='There are no uniform regulations, BUT out of courtesy, please have appropriate uniforms' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='Games will start at 8 AM' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='New games will start every 55 minutes' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='There will be no tiebreakers.' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 600, }} primary='Games will be based on Single elimination' />
            </ListItem>
          </List>

          <Typography variant='subtitle1' sx={{ display: gameDayVisible ? null : 'none', }}>
            Details of the matches will be on ExposureBasketball App.
            <br /> <br />
            <strong>IMPORTANT RANKING STATS:</strong>
            <br />
            Are we qualified to rank<strong>:</strong>
            <br />
            Yes! We are <strong>BBCS NCAA certified</strong>
            <br /> <br />
            Each program, team, and player will be ranked <strong>Nationally</strong>.
            <br /> <br />
            This will be based on:
            <br />
          </Typography>
          <List sx={{ listStyleType: 'disc', pl: 2 }}>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 400, }} primary='How well each one performed' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 400, }} primary='How many tournaments played' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 400, }} primary='How many points scored' />
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              <ListItemText primaryTypographyProps={{ fontWeight: 400, }} primary='How many points scored against each one' />
            </ListItem>
          </List>

          See you on the courts!


        </Typography>
      </Container>
    )
  }

  const cardVariants = {
    hidden: {
      opacity: 0,
      x: '-100%',
      transition: {
        duration: 1,
        staggerChildren: 0.5,
      }
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        staggerChildren: .3,
        staggerDirection: isMobile ? 1 : -1,
      }
    },
  }


  const item = {
    hidden: { opacity: 0, x: -1000 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <Container sx={{ height: 'auto', width: '100%', }} >
      <Container maxWidth='xl' sx={{ display: 'flex', flexDirection: 'column', height: 'auto', width: '100%', }}>
        <Typography variant='h2' align={isMobile ? 'center' : 'left'} fontWeight={700} >Information</Typography>
        <Typography sx={{ marginTop: 1 }} align={isMobile ? 'center' : 'left'} variant='body1' fontWeight={500}>Everyone is welcome to join! We want the best experience for all participating!</Typography>
        <Grid2 container spacing={isMobile ? 2 : 4} sx={{ display: 'flex', height: 'auto', marginTop: 4, }}>
          <Grid2 xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 300, borderRadius: 5, }}>
            <Card raised sx={{ display: signUpVisible ? null : 'flex', position: 'relative', overflow: 'auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', borderRadius: 5, }}>
              <Container sx={{ display: 'flex', padding: 4, alignItems: 'center', flexDirection: 'column', }}>

                {signUpVisible ? conditionallyRenderSignUp() : null}

                <Container sx={{ display: 'flex', overflow: 'hidden', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                  <Typography variant='h4' sx={{ display: signUpVisible ? 'none' : null }} >1</Typography>
                  <Typography variant='h6' sx={{ display: signUpVisible ? 'none' : null }} >Sign Up Eligibility</Typography>
                  <Button sx={{ marginTop: 3, display: signUpVisible ? 'none' : null }} onClick={() => setSignUpVisible(!signUpVisible)} variant='contained'>More</Button>
                </Container>
              </Container>
            </Card>
          </Grid2>
          <Grid2 xs={12} sm={6} md={4} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 300, borderRadius: 5, }}>
            <Card raised sx={{ display: dateAndTimeVisible ? null : 'flex', position: 'relative', overflow: 'auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', borderRadius: 5, }}>
              <Container sx={{ display: 'flex', padding: 4, alignItems: 'center', flexDirection: 'column', }}>

                {dateAndTimeVisible ? conditionallyRenderEventDataAndTime() : null}

                <Container sx={{ display: 'flex', overflow: 'hidden', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                  <Typography variant='h4' sx={{ display: dateAndTimeVisible ? 'none' : null }} >2</Typography>
                  <Typography variant='h6' sx={{ display: dateAndTimeVisible ? 'none' : null }} >Event Date & Time</Typography>
                  <Button sx={{ marginTop: 3, display: dateAndTimeVisible ? 'none' : null }} onClick={() => setDateAndTimeVisible(!dateAndTimeVisible)} variant='contained'>More</Button>
                </Container>
              </Container>
            </Card>
          </Grid2>
          <Grid2 xs={12} sm={12} md={4} sx={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 300, width: '100%', borderRadius: 5, }}>
            <Card raised sx={{ display: gameDayVisible ? null : 'flex', position: 'relative', overflow: 'auto', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', borderRadius: 5, }}>
              <Container sx={{ display: 'flex', padding: 4, alignItems: 'center', flexDirection: 'column', }}>

                {gameDayVisible ? conditionallyRenderGameDay() : null}

                <Container sx={{ display: 'flex', overflow: 'hidden', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                  <Typography variant='h4' sx={{ display: gameDayVisible ? 'none' : null }} >3</Typography>
                  <Typography variant='h6' sx={{ display: gameDayVisible ? 'none' : null }} >Game Day</Typography>
                  <Button sx={{ marginTop: 3, display: gameDayVisible ? 'none' : null }} onClick={() => setGameDayVisible(!gameDayVisible)} variant='contained'>More</Button>
                </Container>
              </Container>
            </Card>
          </Grid2>
        </Grid2>



      </Container>

    </Container >
  )
}


export default InformationSection;
/** @jsxImportSource @emotion/react */
import { css, } from '@emotion/react';
import { Bracket, RoundProps, Seed, SingleLineSeed, SeedItem, SeedTeam, RenderSeedProps } from 'react-brackets';
import { Box } from '@mui/material';



const BracketComponent = (props) => {

  const rounds = [
    {
      title: 'Round one',
      seeds: [
        {
          id: 1,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team B' }],
        },
        {
          id: 2,
          date: new Date().toDateString(),
          teams: [{ name: 'Team C' }, { name: 'Team D' }],
        },
        {
          id: 3,
          date: new Date().toDateString(),
          teams: [{ name: 'Team C' }, { name: 'Team D' }],
        },
        {
          id: 4,
          date: new Date().toDateString(),
          teams: [{ name: 'Team C' }, { name: 'Team D' }],
        },
        {
          id: 5,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team B' }],
        },
        {
          id: 6,
          date: new Date().toDateString(),
          teams: [{ name: 'Team C' }, { name: 'Team D' }],
        },
        {
          id: 7,
          date: new Date().toDateString(),
          teams: [{ name: 'Team C' }, { name: 'Team D' }],
        },
        {
          id: 8,
          date: new Date().toDateString(),
          teams: [{ name: 'Team C' }, { name: 'Team D' }],
        },
      ],
    },

    {
      title: 'Round two',
      seeds: [
        {
          id: 9,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team C' }],
        },
        {
          id: 10,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team C' }],
        },
        {
          id: 11,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team C' }],
        },
        {
          id: 12,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team C' }],
        },
      ],
    },
    {
      title: 'Round three',
      seeds: [

        {
          id: 13,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team C' }],
        },
        {
          id: 14,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team C' }],
        },
      ],
    },
    {
      title: 'Round four',
      seeds: [

        {
          id: 15,
          date: new Date().toDateString(),
          teams: [{ name: 'Team A' }, { name: 'Team C' }],
        },

      ],
    },
  ];


  const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }) => {
    // ------ assuming rounds is the losers brackets rounds ------
    // losers rounds usually got some identical seeds amount like (2 - 2 - 1 - 1)

    const isLineConnector = rounds[roundIndex].seeds.length === rounds[roundIndex + 1]?.seeds.length;

    const Wrapper = isLineConnector ? SingleLineSeed : Seed;


    // mobileBreakpoint is required to be passed down to a seed
    return (
        <Wrapper mobileBreakpoint={breakpoint} css={{ backgroundColor: 'transparent',}} >
          <SeedItem css={{ backgroundColor: '#656565' }}>
            <div>
              <SeedTeam style={{ color: 'white', backgroundColor: '#7b7b7b', }}>{seed.teams[0]?.name || 'NO TEAM '}</SeedTeam>
              <SeedTeam>{seed.teams[1]?.name || 'NO TEAM '}</SeedTeam>
            </div>
          </SeedItem>
        </Wrapper>
    );
  };

  return (
    <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />


  )
}


export default BracketComponent
/** @jsxImportSource @emotion/react */
import './Dashboard.css'
import React, { useEffect, useState, } from 'react';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { css, } from '@emotion/react';
import { Bracket, RoundProps, Seed, SingleLineSeed, SeedItem, SeedTeam, RenderSeedProps } from 'react-brackets';
import BracketComponent from '../components/BracketComponent';
import Box from '@mui/material/Box';
import DrawerComponent from '../components/DrawerComponent';
import CustomizedTables from '../components/TeamTableComponent';
import { useSelector, useDispatch } from 'react-redux'
import { change } from '../store/reducers/dashboardState'
import Tournaments from './Tournaments';
import useWindowDimensions from '../hooks/useWindowDimensions';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import AdminTable from '../components/AdminTable';
import { loadStripe } from '@stripe/stripe-js';



export default function AdminDashboard() {

  const dashboardState = useSelector(state => state.dashboardState)
  const dispatch = useDispatch()
  const { height, width } = useWindowDimensions()
  const [mobileOpen, setMobileOpen] = React.useState(false);
  console.log('mobile open', mobileOpen)



  // * fetch purchases from Stripe


  useEffect(() => {

    dispatch(change(4))
  }, [])

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'))


  console.log('dashboardState', dashboardState)
  const [conditionalDisplay, setConditionalDisplay] = useState(0);

  const conditionalDisplayHandler = (index) => {
    if (index === 1) {
      return (
        <Tournaments />
      )
    } else if (index === 2) {
      return (
        <CustomizedTables />
      )
    } else if (index === 3) {
      return (
        <BracketComponent isMobile drawerWidth width />
      )
    }
  }


  //       {conditionalDisplayHandler(dashboardState.value)
  // }

  const drawerWidth = 200;

  const mobileMenuIcon = () => {

    if (isMobile) {
      return (
        <Box sx={{ position: 'absolute', top: 16, left: 0, right: 16, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
          <MenuIcon onClick={() => setMobileOpen(!mobileOpen)} sx={{ height: 36, width: 36 }} />
        </Box>
      )
    }
  }

  const data = [
    {
      name: 'John Doe',
      email: 'test@gmail.com',
      organizationName: 'Test Organization',
      status: 'Active'
    },
    {
      name: 'John Doe',
      email: 'fmdfkm@gmail.com',
      organizationName: 'Test Organization',
      status: 'Active',
    },
  ]

  const conditionallyRenderSection = () => {

    if (dashboardState.value == 4) {
      console.log('is equal to 4')
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 8, alignItems: 'center', justifyContent: 'center', width: isMobile ? '100vw' : width - drawerWidth, height: '100vh', paddingX: isMobile? 0 : 8, boxSizing: 'border-box', }}>
          <Box sx={{display: 'flex', flexDirection: 'column',width: isMobile? "100%" :  '50%',  paddingX: 2, paddingBottom: 8, gap: 8,}}>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', borderBottom: 2, }}>
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', }}>
                <Typography textAlign={'center'} variant='body1'>Name</Typography>

              </Box>
              <Box sx={{ display: 'flex', flex: 1,  justifyContent: 'center', }}>
                <Typography textAlign={'center'} variant='body1'>Email</Typography>

              </Box>
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center',  }}>
                <Typography textAlign={'center'} variant='body1'>Number Of Teams Registered</Typography>

              </Box>
            </Box>
            <Box sx={{ display: 'flex', width: '100%',  justifyContent: 'space-between', }}>
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center',  }}>
                <Typography textAlign={'center'} variant='body2'>Carlee Dough</Typography>
              </Box>
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center',  }}>
                <Typography textAlign={'center'} variant='body2'>carleelough3@gmail.com</Typography>
              </Box>
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center',  }}>

                <Typography textAlign={'center'} variant='body2'>1</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

      )
    } else if (dashboardState.value == 5) {

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: isMobile ? '100vw' : width - drawerWidth, height: '100vh', paddingX: 8, boxSizing: 'border-box', }}>
          <Typography textAlign={'center'} variant='h4'>There are currently no leads</Typography>
        </Box>

      )


    }

  }

  return (
    <div css={{ display: 'flex', height: '100vh', width: '100%', backgroundColor: '#f0f2f5', justifyContent: 'flex-end', }}>
      {mobileMenuIcon()}
      <DrawerComponent mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} navItems={['Purchases', 'Leads']} drawerWidth={200} />
      {conditionallyRenderSection()}


    </div>

  )
}



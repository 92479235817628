import { createSlice } from '@reduxjs/toolkit'

export const registrationStateSlice = createSlice({
  name: 'registrationState',
  initialState: {
    value: 0,
  },
  reducers: {
    updateRegistrationState: (state, action) => {
      state.value = action.payload
    }
  }
})

export const { updateRegistrationState } = registrationStateSlice.actions

export default registrationStateSlice.reducer
import React, { useState } from "react";
import { Box, Button, Container, Grid, Paper, Typography, TextField, } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { app, auth, payments, db } from '../firebaseConfig';
import { getStripePayments, getProducts, createCheckoutSession } from "@stripe/firestore-stripe-payments";
import StyledFirebaseAuth from '../components/StyledFirebaseAuth.tsx'
import firebase from 'firebase/compat/app';
import { css, } from '@emotion/react';
/** @jsxImportSource @emotion/react */
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import { collection, addDoc } from "firebase/firestore";
import image from '../images/basketball-court.jpg';
import Register__LoginForm from "../components/registration/Register__LoginForm";



const SignIn = () => {

  const navigate = useNavigate();

  const mediumDown = useMediaQuery(useTheme().breakpoints.down('md'));


  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/beginpayment',
    // We will display Google and Email as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with Email',
      },
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with Google',
      },
    ],

  };


  const conditionalDeviceRender = () => {
    if (mediumDown) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100%', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center', }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', }}>
            <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', height: '100%', width: '100%', }} >
              <Register__LoginForm navigate={navigate}  />
            </Container>

          </Box>


        </Box>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100%', boxSizing: 'border-box', alignItems: 'center', justifyContent: 'center', }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '50%', }}>
            <Register__LoginForm navigate={navigate}  />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width: '50%', background: `url(${image})`, backgroundSize: 'cover', }}>

          </Box>

        </Box>
      )
    }
  }


  return (
    conditionalDeviceRender()
  )
}





export default SignIn;
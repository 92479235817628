import { configureStore } from '@reduxjs/toolkit'
import dashboardStateReducer from './reducers/dashboardState'
import registrationStateReducer from './reducers/registrationState'
import registrationAnswers from './reducers/registrationAnswers'
import team1PlayerInfo from './reducers/playerInfoForTeams/team1PlayerInfo'
import team2PlayerInfo from './reducers/playerInfoForTeams/team2PlayerInfo'
import team3PlayerInfo from './reducers/playerInfoForTeams/team3PlayerInfo'
import team4PlayerInfo from './reducers/playerInfoForTeams/team4PlayerInfo'
import team5PlayerInfo from './reducers/playerInfoForTeams/team5PlayerInfo'
import team6PlayerInfo from './reducers/playerInfoForTeams/team6PlayerInfo'
import team7PlayerInfo from './reducers/playerInfoForTeams/team7PlayerInfo'
import team8PlayerInfo from './reducers/playerInfoForTeams/team8PlayerInfo'
import team9PlayerInfo from './reducers/playerInfoForTeams/team9PlayerInfo'
import team10PlayerInfo from './reducers/playerInfoForTeams/team10PlayerInfo'
import team11PlayerInfo from './reducers/playerInfoForTeams/team11PlayerInfo'
import team12PlayerInfo from './reducers/playerInfoForTeams/team12PlayerInfo'
import team13PlayerInfo from './reducers/playerInfoForTeams/team13PlayerInfo'
import team14PlayerInfo from './reducers/playerInfoForTeams/team14PlayerInfo'
import team15PlayerInfo from './reducers/playerInfoForTeams/team15PlayerInfo'
import team16PlayerInfo from './reducers/playerInfoForTeams/team16PlayerInfo'


import user from './reducers/user'






export default configureStore({
  reducer: {
    
    dashboardState: dashboardStateReducer,
    registrationState: registrationStateReducer,
    registrationAnswers: registrationAnswers,
    team1PlayerInfo: team1PlayerInfo,
    team2PlayerInfo: team2PlayerInfo,
    team3PlayerInfo: team3PlayerInfo,
    team4PlayerInfo: team4PlayerInfo,
    team5PlayerInfo: team5PlayerInfo,
    team6PlayerInfo: team6PlayerInfo,
    team7PlayerInfo: team7PlayerInfo,
    team8PlayerInfo: team8PlayerInfo,
    team9PlayerInfo: team9PlayerInfo,
    team10PlayerInfo: team10PlayerInfo,
    team11PlayerInfo: team11PlayerInfo,
    team12PlayerInfo: team12PlayerInfo,
    team13PlayerInfo: team13PlayerInfo,
    team14PlayerInfo: team14PlayerInfo,
    team15PlayerInfo: team15PlayerInfo,
    team16PlayerInfo: team16PlayerInfo,
    



    user: user,

  },
})
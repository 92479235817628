/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, } from 'react';
import { Box, Container, Typography, Card, Button, Divider, } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import TournamentCardDetails from './TournamentCardDetails';


const TournamentCard = (props) => {

  const width = props.width;
  const height = props.height;
  const maxWidth = props.maxWidth;
  const marginTop = props.marginTop;
  const season = props.season
  const date = props.date ? props.date : 'None'
  const time = props.time ? props.time : 'None'
  const location = props.location ? props.location : 'None'

  return (
    <Container maxWidth={'lg'} >
      <Grid2 spacing={4} container sx={{ height: '150%', }}>


        <Grid2 sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 325, width: '30%', marginLeft: 4, marginTop: 10, borderRadius: 3, backgroundColor: 'white', boxShadow: 2 }}>
          <Box sx={{ background: 'linear-gradient(195deg, rgb(73, 163, 241), rgb(26, 115, 232))', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', height: '60%', width: '100%', marginTop: -4, borderRadius: 2, boxShadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(0 187 212 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem', }}>
            <Container sx={{ height: '25%', padding: 2, }}>
              <Typography variant='h4' sx={{ textAlign: 'center', color: 'white', }}>{season}</Typography>
            </Container>
            <Container sx={{ display: ' flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '75%', padding: 1, }}>
              <Divider width='90%' sx={{ background: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.4), rgba(52, 71, 103, 0))', opacity: 0.25, }} />
              <Typography variant='body1' sx={{ textAlign: 'center', color: 'white', }}>{date}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center', color: 'white', }}>{time}</Typography>
              <Typography variant='body1' sx={{ textAlign: 'center', color: 'white', }}>{location}</Typography>
            </Container>
          </Box >
          <Container sx={{ height: '100%', width: '100%', padding: 1, display: 'flex',  justifyContent: 'center', alignItems: 'center',  }}>
            <Container sx={{ display: 'flex', flexDirection: 'row', height: '40%', }}>
              <Button href="/tournaments/1" variant='text' >View Details</Button>
              <Button variant='contained'>Register</Button>
            </Container>
          </Container>
        </Grid2>



      </Grid2>
    </Container>
  )
}


export default TournamentCard;
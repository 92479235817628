/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, } from 'react';
import { Box, Container, Typography, Card, Button, } from '@mui/material';
import { css, } from '@emotion/react';
import TournamentCard from '../components/tournament/TournamentCard';


const Tournaments = () => {
  return (
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  width: '100%', boxSizing: 'border-box', }}>

       

        <TournamentCard season={'Fall 2023'} date='March 18th & 19th' time='TBA' location='Christ The King Academy'  maxWidth={'lg'} height={'100%'}  />

      </Box>

  )
}


export default Tournaments;
import React, { useState, } from "react";
import { Box, TextField, RadioGroup, MenuItem, Select, FormHelperText, Radio, FormGroup, FormControl, FormLabel, FormControlLabel, Container, Checkbox, Typography, Card, Divider, Button, } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { updateRegistrationState } from '../../store/reducers/registrationState'
import {
  updateRegistrationAnswers,
  updateRegistrationAnswersOrganizationPhoneNumber, updateRegistrationAnswersOrganizationEmail, updateRegistrationAnswersOrganizationName,
  updateRegistrationAnswersFbTag, updateRegistrationAnswersIgTag, updateRegistrationAnswersOptOut, updateRegistrationAnswersFbHandle,
  updateRegistrationAnswersIgHandle, updateRegistrationAnswersTeamBrag, updateRegistrationAnswersNumOfCoaches,
  updateRegistrationAnswersCoachFirstName1, updateRegistrationAnswersCoachLastName1, updateRegistrationAnswersCoachFirstName2, updateRegistrationAnswersCoachLastName2
} from '../../store/reducers/registrationAnswers'








const RegistrationFormCoaches = (props) => {

  const firstStateNumber = props.firstStateNumber
  const registrationState = useSelector(state => state.registrationState)
  const registrationAnswers = useSelector(state => state.registrationAnswers)
  const dispatch = useDispatch();

  const [numOfCoaches, setNumOfCoaches] = useState(1)

  const totalIndexes = 2


  const nextHandler = () => {
    if (registrationState.value === firstStateNumber) {
      if (registrationAnswers.numOfCoaches < 2) {
        dispatch(updateRegistrationState(registrationState.value + 2))
      } else dispatch(updateRegistrationState(registrationState.value + 1))
    } else dispatch(updateRegistrationState(registrationState.value + 1))
  }

  const previousHandler = () => {
    if (registrationState.value === firstStateNumber + 2) {
      if (registrationAnswers.numOfCoaches < 2) {
        dispatch(updateRegistrationState(registrationState.value - 2))
      } else dispatch(updateRegistrationState(registrationState.value - 1))
    } else dispatch(updateRegistrationState(registrationState.value - 1))

  }

  const handleChange = (value) => {
    dispatch(updateRegistrationAnswersNumOfCoaches(value))
  };

  const handleCoach1FirstNameChange = (value) => {
    dispatch(updateRegistrationAnswersCoachFirstName1(value))
  };

  const handleCoach1LastNameChange = (value) => {
    dispatch(updateRegistrationAnswersCoachLastName1(value))
  };

  const handleCoach2FirstNameChange = (value) => {
    dispatch(updateRegistrationAnswersCoachFirstName2(value))
  };

  const handleCoach2LastNameChange = (value) => {
    dispatch(updateRegistrationAnswersCoachLastName2(value))
  };

  const conditionalFieldRender = () => {
    if (registrationState.value === firstStateNumber) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant='h6' component='h2' sx={{ paddingBottom: 2, }}>How many coaches will be attending?</Typography>
          <FormControl margin={'normal'}>
            <FormLabel id="demo-radio-buttons-group-label">How Many Coaches?</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={registrationAnswers.numOfCoaches}
              name="radio-buttons-group"
            >
              <FormControlLabel value="1" control={<Radio onChange={() => handleChange(1)} />} label="1" />
              <FormControlLabel value="2" control={<Radio onChange={() => handleChange(2)} />} label="2" />
            </RadioGroup>
          </FormControl>
        </Box>
      )
    } else if (registrationState.value == firstStateNumber + 1) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column',  flex: 1,  alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant='h6' component='h2' sx={{ paddingBottom: 2, }}>What are the names of the coaches?</Typography>
          <FormGroup  >
            <FormLabel> {registrationAnswers.numOfCoaches > 1 ? 'Coach Name #1' : 'Coach Name'}</FormLabel>
            <FormGroup row>
              <TextField label='First Name' variant='outlined' margin={'dense'} value={registrationAnswers.coachFirstName1} onChange={(event) => handleCoach1FirstNameChange(event.target.value)} />
              <TextField label='Last Name' variant='outlined' margin={'dense'} value={registrationAnswers.coachLastName1} onChange={(event) => handleCoach1LastNameChange(event.target.value)} />
            </FormGroup>
          </FormGroup>
        </Box>
      )
    } else if (registrationState.value === firstStateNumber + 2) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column',  flex: 1,  alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant='h6' component='h2' sx={{ paddingBottom: 2, }}>What are the names of the coaches?</Typography>
          <FormGroup >
            <FormLabel>  {registrationAnswers.numOfCoaches > 1 ? 'Coach Name #2' : 'Coach Name'}</FormLabel>
            <FormGroup row>
              <TextField label='First Name' variant='outlined' margin={'dense'} value={registrationAnswers.coachFirstName2} onChange={(event) => handleCoach2FirstNameChange(event.target.value)} />
              <TextField label='Last Name' variant='outlined' margin={'dense'} value={registrationAnswers.coachLastName2} onChange={(event) => handleCoach2LastNameChange(event.target.value)} />
            </FormGroup>
          </FormGroup>
        </Box>
      )
    }
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 1,
      width: '100%', boxSizing: 'border-box', 
    }}>
      <Container maxWidth='lg' sx={{
        display: 'flex', flexDirection: 'column', width: '100%',
        height: '100%', alignItems: 'center', 
      }} >

        {conditionalFieldRender()}

        <FormGroup sx={{ width: '100%', marginTop: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} row>
          <Button onClick={previousHandler} variant="outlined" sx={{ marginRight: 1, }} >Previous</Button>
          <Button onClick={nextHandler} variant="contained" sx={{ marginLeft: 1 }} >Next</Button>
        </FormGroup>



      </Container>
    </Box>
  )
}


export default RegistrationFormCoaches;
import { createSlice } from '@reduxjs/toolkit'

export const team11PlayerInfoSlice = createSlice({
  name: 'team11PlayerInfo',
  initialState: {
    team11Player1FirstName: '',
    team11Player1LastName: '',
    team11Player2FirstName: '',
    team11Player2LastName: '',
    team11Player3FirstName: '',
    team11Player3LastName: '',
    team11Player4FirstName: '',
    team11Player4LastName: '',
    team11Player5FirstName: '',
    team11Player5LastName: '',
    team11Player6FirstName: '',
    team11Player6LastName: '',
    team11Player7FirstName: '',
    team11Player7LastName: '',
    team11Player8FirstName: '',
    team11Player8LastName: '',
    team11Player9FirstName: '',
    team11Player9LastName: '',
    team11Player10FirstName: '',
    team11Player10LastName: '',
    team11Player11FirstName: '',
    team11Player11LastName: '',
    team11Player12FirstName: '',
    team11Player12LastName: '',
    team11Player13FirstName: '',
    team11Player13LastName: '',
    team11Player14FirstName: '',
    team11Player14LastName: '',
    team11Player15FirstName: '',
    team11Player15LastName: '',
    team9Player16FirstName: '',
    team9Player16LastName: '',
    team9Player17FirstName: '',
    team9Player17LastName: '',
    team9Player18FirstName: '',
    team9Player18LastName: '',
    team9Player19FirstName: '',
    team9Player19LastName: '',
    team9Player20FirstName: '',
    team9Player20LastName: '',


  },
  reducers: {
    updateTeam11PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam11Player1FirstName: (state, action) => {
      state.team11Player1FirstName = action.payload
    },
    updateTeam11Player1LastName: (state, action) => {
      state.team11Player1LastName = action.payload
    },
    updateTeam11Player2FirstName: (state, action) => {
      state.team11Player2FirstName = action.payload
    },
    updateTeam11Player2LastName: (state, action) => {
      state.team11Player2LastName = action.payload
    },
    updateTeam11Player3FirstName: (state, action) => {
      state.team11Player3FirstName = action.payload
    },
    updateTeam11Player3LastName: (state, action) => {
      state.team11Player3LastName = action.payload
    },
    updateTeam11Player4FirstName: (state, action) => {
      state.team11Player4FirstName = action.payload
    },
    updateTeam11Player4LastName: (state, action) => {
      state.team11Player4LastName = action.payload
    },
    updateTeam11Player5FirstName: (state, action) => {
      state.team11Player5FirstName = action.payload
    },
    updateTeam11Player5LastName: (state, action) => {
      state.team11Player5LastName = action.payload
    },
    updateTeam11Player6FirstName: (state, action) => {
      state.team11Player6FirstName = action.payload
    },
    updateTeam11Player6LastName: (state, action) => {
      state.team11Player6LastName = action.payload
    },
    updateTeam11Player7FirstName: (state, action) => {
      state.team11Player7FirstName = action.payload
    },
    updateTeam11Player7LastName: (state, action) => {
      state.team11Player7LastName = action.payload
    },
    updateTeam11Player8FirstName: (state, action) => {
      state.team11Player8FirstName = action.payload
    },
    updateTeam11Player8LastName: (state, action) => {
      state.team11Player8LastName = action.payload
    },
    updateTeam11Player9FirstName: (state, action) => {
      state.team11Player9FirstName = action.payload
    },
    updateTeam11Player9LastName: (state, action) => {
      state.team11Player9LastName = action.payload
    },
    updateTeam11Player10FirstName: (state, action) => {
      state.team11Player10FirstName = action.payload
    },
    updateTeam11Player10LastName: (state, action) => {
      state.team11Player10LastName = action.payload
    },
    updateTeam11Player11FirstName: (state, action) => {
      state.team11Player11FirstName = action.payload
    },
    updateTeam11Player11LastName: (state, action) => {
      state.team11Player11LastName = action.payload
    },
    updateTeam11Player12FirstName: (state, action) => {
      state.team11Player12FirstName = action.payload
    },
    updateTeam11Player12LastName: (state, action) => {
      state.team11Player12LastName = action.payload
    },
    updateTeam11Player13FirstName: (state, action) => {
      state.team11Player13FirstName = action.payload
    },
    updateTeam11Player13LastName: (state, action) => {
      state.team11Player13LastName = action.payload
    },
    updateTeam11Player14FirstName: (state, action) => {
      state.team11Player14FirstName = action.payload
    },
    updateTeam11Player14LastName: (state, action) => {
      state.team11Player14LastName = action.payload
    },
    updateTeam11Player15FirstName: (state, action) => {
      state.team11Player15FirstName = action.payload
    },
    updateTeam11Player15LastName: (state, action) => {
      state.team11Player15LastName = action.payload
    },
    updateTeam11Player16FirstName: (state, action) => {
      state.team11Player16FirstName = action.payload
    },
    updateTeam11Player16LastName: (state, action) => {
      state.team11Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam11Team1PlayerInfo,
  updateTeam11Player1FirstName,
  updateTeam11Player1LastName,
  updateTeam11Player2FirstName,
  updateTeam11Player2LastName,
  updateTeam11Player3FirstName,
  updateTeam11Player3LastName,
  updateTeam11Player4FirstName,
  updateTeam11Player4LastName,
  updateTeam11Player5FirstName,
  updateTeam11Player5LastName,
  updateTeam11Player6FirstName,
  updateTeam11Player6LastName,
  updateTeam11Player7FirstName,
  updateTeam11Player7LastName,
  updateTeam11Player8FirstName,
  updateTeam11Player8LastName,
  updateTeam11Player9FirstName,
  updateTeam11Player9LastName,
  updateTeam11Player10FirstName,
  updateTeam11Player10LastName,
  updateTeam11Player11FirstName,
  updateTeam11Player11LastName,
  updateTeam11Player12FirstName,
  updateTeam11Player12LastName,
  updateTeam11Player13FirstName,
  updateTeam11Player13LastName,
  updateTeam11Player14FirstName,
  updateTeam11Player14LastName,
  updateTeam11Player15FirstName,
  updateTeam11Player15LastName,
  updateTeam11Player16FirstName,
  updateTeam11Player16LastName,

} = team11PlayerInfoSlice.actions
export default team11PlayerInfoSlice.reducer
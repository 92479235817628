import { createSlice } from '@reduxjs/toolkit'

export const team14PlayerInfoSlice = createSlice({
  name: 'team14PlayerInfo',
  initialState: {
    team14Player1FirstName: '',
    team14Player1LastName: '',
    team14Player2FirstName: '',
    team14Player2LastName: '',
    team14Player3FirstName: '',
    team14Player3LastName: '',
    team14Player4FirstName: '',
    team14Player4LastName: '',
    team14Player5FirstName: '',
    team14Player5LastName: '',
    team14Player6FirstName: '',
    team14Player6LastName: '',
    team14Player7FirstName: '',
    team14Player7LastName: '',
    team14Player8FirstName: '',
    team14Player8LastName: '',
    team14Player9FirstName: '',
    team14Player9LastName: '',
    team14Player10FirstName: '',
    team14Player10LastName: '',
    team14Player11FirstName: '',
    team14Player11LastName: '',
    team14Player12FirstName: '',
    team14Player12LastName: '',
    team14Player13FirstName: '',
    team14Player13LastName: '',
    team14Player14FirstName: '',
    team14Player14LastName: '',
    team14Player15FirstName: '',
    team14Player15LastName: '',
    team14Player16FirstName: '',
    team14Player16LastName: '',
    team14Player17FirstName: '',
    team14Player17LastName: '',
    team14Player18FirstName: '',
    team14Player18LastName: '',
    team14Player19FirstName: '',
    team14Player19LastName: '',
    team14Player20FirstName: '',
    team14Player20LastName: '',


  },
  reducers: {
    updateTeam14PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam14Player1FirstName: (state, action) => {
      state.team14Player1FirstName = action.payload
    },
    updateTeam14Player1LastName: (state, action) => {
      state.team14Player1LastName = action.payload
    },
    updateTeam14Player2FirstName: (state, action) => {
      state.team14Player2FirstName = action.payload
    },
    updateTeam14Player2LastName: (state, action) => {
      state.team14Player2LastName = action.payload
    },
    updateTeam14Player3FirstName: (state, action) => {
      state.team14Player3FirstName = action.payload
    },
    updateTeam14Player3LastName: (state, action) => {
      state.team14Player3LastName = action.payload
    },
    updateTeam14Player4FirstName: (state, action) => {
      state.team14Player4FirstName = action.payload
    },
    updateTeam14Player4LastName: (state, action) => {
      state.team14Player4LastName = action.payload
    },
    updateTeam14Player5FirstName: (state, action) => {
      state.team14Player5FirstName = action.payload
    },
    updateTeam14Player5LastName: (state, action) => {
      state.team14Player5LastName = action.payload
    },
    updateTeam14Player6FirstName: (state, action) => {
      state.team14Player6FirstName = action.payload
    },
    updateTeam14Player6LastName: (state, action) => {
      state.team14Player6LastName = action.payload
    },
    updateTeam14Player7FirstName: (state, action) => {
      state.team14Player7FirstName = action.payload
    },
    updateTeam14Player7LastName: (state, action) => {
      state.team14Player7LastName = action.payload
    },
    updateTeam14Player8FirstName: (state, action) => {
      state.team14Player8FirstName = action.payload
    },
    updateTeam14Player8LastName: (state, action) => {
      state.team14Player8LastName = action.payload
    },
    updateTeam14Player9FirstName: (state, action) => {
      state.team14Player9FirstName = action.payload
    },
    updateTeam14Player9LastName: (state, action) => {
      state.team14Player9LastName = action.payload
    },
    updateTeam14Player10FirstName: (state, action) => {
      state.team14Player10FirstName = action.payload
    },
    updateTeam14Player10LastName: (state, action) => {
      state.team14Player10LastName = action.payload
    },
    updateTeam14Player11FirstName: (state, action) => {
      state.team14Player11FirstName = action.payload
    },
    updateTeam14Player11LastName: (state, action) => {
      state.team14Player11LastName = action.payload
    },
    updateTeam14Player12FirstName: (state, action) => {
      state.team14Player12FirstName = action.payload
    },
    updateTeam14Player12LastName: (state, action) => {
      state.team14Player12LastName = action.payload
    },
    updateTeam14Player13FirstName: (state, action) => {
      state.team14Player13FirstName = action.payload
    },
    updateTeam14Player13LastName: (state, action) => {
      state.team14Player13LastName = action.payload
    },
    updateTeam14Player14FirstName: (state, action) => {
      state.team14Player14FirstName = action.payload
    },
    updateTeam14Player14LastName: (state, action) => {
      state.team14Player14LastName = action.payload
    },
    updateTeam14Player15FirstName: (state, action) => {
      state.team14Player15FirstName = action.payload
    },
    updateTeam14Player15LastName: (state, action) => {
      state.team14Player15LastName = action.payload
    },
    updateTeam14Player16FirstName: (state, action) => {
      state.team14Player16FirstName = action.payload
    },
    updateTeam14Player16LastName: (state, action) => {
      state.team14Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam14Team1PlayerInfo,
  updateTeam14Player1FirstName,
  updateTeam14Player1LastName,
  updateTeam14Player2FirstName,
  updateTeam14Player2LastName,
  updateTeam14Player3FirstName,
  updateTeam14Player3LastName,
  updateTeam14Player4FirstName,
  updateTeam14Player4LastName,
  updateTeam14Player5FirstName,
  updateTeam14Player5LastName,
  updateTeam14Player6FirstName,
  updateTeam14Player6LastName,
  updateTeam14Player7FirstName,
  updateTeam14Player7LastName,
  updateTeam14Player8FirstName,
  updateTeam14Player8LastName,
  updateTeam14Player9FirstName,
  updateTeam14Player9LastName,
  updateTeam14Player10FirstName,
  updateTeam14Player10LastName,
  updateTeam14Player11FirstName,
  updateTeam14Player11LastName,
  updateTeam14Player12FirstName,
  updateTeam14Player12LastName,
  updateTeam14Player13FirstName,
  updateTeam14Player13LastName,
  updateTeam14Player14FirstName,
  updateTeam14Player14LastName,
  updateTeam14Player15FirstName,
  updateTeam14Player15LastName,
  updateTeam14Player16FirstName,
  updateTeam14Player16LastName,

} = team14PlayerInfoSlice.actions
export default team14PlayerInfoSlice.reducer
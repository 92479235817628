import { createSlice } from '@reduxjs/toolkit'

export const team4PlayerInfoSlice = createSlice({
  name: 'team4PlayerInfo',
  initialState: {
    team4Player1FirstName: '',
    team4Player1LastName: '',
    team4Player2FirstName: '',
    team4Player2LastName: '',
    team4Player3FirstName: '',
    team4Player3LastName: '',
    team4Player4FirstName: '',
    team4Player4LastName: '',
    team4Player5FirstName: '',
    team4Player5LastName: '',
    team4Player6FirstName: '',
    team4Player6LastName: '',
    team4Player7FirstName: '',
    team4Player7LastName: '',
    team4Player8FirstName: '',
    team4Player8LastName: '',
    team4Player9FirstName: '',
    team4Player9LastName: '',
    team4Player10FirstName: '',
    team4Player10LastName: '',
    team4Player11FirstName: '',
    team4Player11LastName: '',
    team4Player12FirstName: '',
    team4Player12LastName: '',
    team4Player13FirstName: '',
    team4Player13LastName: '',
    team4Player14FirstName: '',
    team4Player14LastName: '',
    team4Player15FirstName: '',
    team4Player15LastName: '',
    team4Player16FirstName: '',
    team4Player16LastName: '',
    team4Player17FirstName: '',
    team4Player17LastName: '',
    team4Player18FirstName: '',
    team4Player18LastName: '',
    team4Player19FirstName: '',
    team4Player19LastName: '',
    team4Player20FirstName: '',
    team4Player20LastName: '',


  },
  reducers: {
    updateTeam4PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam4Player1FirstName: (state, action) => {
      state.team4Player1FirstName = action.payload
    },
    updateTeam4Player1LastName: (state, action) => {
      state.team4Player1LastName = action.payload
    },
    updateTeam4Player2FirstName: (state, action) => {
      state.team4Player2FirstName = action.payload
    },
    updateTeam4Player2LastName: (state, action) => {
      state.team4Player2LastName = action.payload
    },
    updateTeam4Player3FirstName: (state, action) => {
      state.team4Player3FirstName = action.payload
    },
    updateTeam4Player3LastName: (state, action) => {
      state.team4Player3LastName = action.payload
    },
    updateTeam4Player4FirstName: (state, action) => {
      state.team4Player4FirstName = action.payload
    },
    updateTeam4Player4LastName: (state, action) => {
      state.team4Player4LastName = action.payload
    },
    updateTeam4Player5FirstName: (state, action) => {
      state.team4Player5FirstName = action.payload
    },
    updateTeam4Player5LastName: (state, action) => {
      state.team4Player5LastName = action.payload
    },
    updateTeam4Player6FirstName: (state, action) => {
      state.team4Player6FirstName = action.payload
    },
    updateTeam4Player6LastName: (state, action) => {
      state.team4Player6LastName = action.payload
    },
    updateTeam4Player7FirstName: (state, action) => {
      state.team4Player7FirstName = action.payload
    },
    updateTeam4Player7LastName: (state, action) => {
      state.team4Player7LastName = action.payload
    },
    updateTeam4Player8FirstName: (state, action) => {
      state.team4Player8FirstName = action.payload
    },
    updateTeam4Player8LastName: (state, action) => {
      state.team4Player8LastName = action.payload
    },
    updateTeam4Player9FirstName: (state, action) => {
      state.team4Player9FirstName = action.payload
    },
    updateTeam4Player9LastName: (state, action) => {
      state.team4Player9LastName = action.payload
    },
    updateTeam4Player10FirstName: (state, action) => {
      state.team4Player10FirstName = action.payload
    },
    updateTeam4Player10LastName: (state, action) => {
      state.team4Player10LastName = action.payload
    },
    updateTeam4Player11FirstName: (state, action) => {
      state.team4Player11FirstName = action.payload
    },
    updateTeam4Player11LastName: (state, action) => {
      state.team4Player11LastName = action.payload
    },
    updateTeam4Player12FirstName: (state, action) => {
      state.team4Player12FirstName = action.payload
    },
    updateTeam4Player12LastName: (state, action) => {
      state.team4Player12LastName = action.payload
    },
    updateTeam4Player13FirstName: (state, action) => {
      state.team4Player13FirstName = action.payload
    },
    updateTeam4Player13LastName: (state, action) => {
      state.team4Player13LastName = action.payload
    },
    updateTeam4Player14FirstName: (state, action) => {
      state.team4Player14FirstName = action.payload
    },
    updateTeam4Player14LastName: (state, action) => {
      state.team4Player14LastName = action.payload
    },
    updateTeam4Player15FirstName: (state, action) => {
      state.team4Player15FirstName = action.payload
    },
    updateTeam4Player15LastName: (state, action) => {
      state.team4Player15LastName = action.payload
    },
    updateTeam4Player16FirstName: (state, action) => {
      state.team4Player16FirstName = action.payload
    },
    updateTeam4Player16LastName: (state, action) => {
      state.team4Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam4Team1PlayerInfo,
  updateTeam4Player1FirstName,
  updateTeam4Player1LastName,
  updateTeam4Player2FirstName,
  updateTeam4Player2LastName,
  updateTeam4Player3FirstName,
  updateTeam4Player3LastName,
  updateTeam4Player4FirstName,
  updateTeam4Player4LastName,
  updateTeam4Player5FirstName,
  updateTeam4Player5LastName,
  updateTeam4Player6FirstName,
  updateTeam4Player6LastName,
  updateTeam4Player7FirstName,
  updateTeam4Player7LastName,
  updateTeam4Player8FirstName,
  updateTeam4Player8LastName,
  updateTeam4Player9FirstName,
  updateTeam4Player9LastName,
  updateTeam4Player10FirstName,
  updateTeam4Player10LastName,
  updateTeam4Player11FirstName,
  updateTeam4Player11LastName,
  updateTeam4Player12FirstName,
  updateTeam4Player12LastName,
  updateTeam4Player13FirstName,
  updateTeam4Player13LastName,
  updateTeam4Player14FirstName,
  updateTeam4Player14LastName,
  updateTeam4Player15FirstName,
  updateTeam4Player15LastName,
  updateTeam4Player16FirstName,
  updateTeam4Player16LastName,

} = team4PlayerInfoSlice.actions
export default team4PlayerInfoSlice.reducer
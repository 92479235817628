import { createSlice } from '@reduxjs/toolkit'

export const team9PlayerInfoSlice = createSlice({
  name: 'team9PlayerInfo',
  initialState: {
    team9Player1FirstName: '',
    team9Player1LastName: '',
    team9Player2FirstName: '',
    team9Player2LastName: '',
    team9Player3FirstName: '',
    team9Player3LastName: '',
    team9Player4FirstName: '',
    team9Player4LastName: '',
    team9Player5FirstName: '',
    team9Player5LastName: '',
    team9Player6FirstName: '',
    team9Player6LastName: '',
    team9Player7FirstName: '',
    team9Player7LastName: '',
    team9Player8FirstName: '',
    team9Player8LastName: '',
    team9Player9FirstName: '',
    team9Player9LastName: '',
    team9Player10FirstName: '',
    team9Player10LastName: '',
    team9Player11FirstName: '',
    team9Player11LastName: '',
    team9Player12FirstName: '',
    team9Player12LastName: '',
    team9Player13FirstName: '',
    team9Player13LastName: '',
    team9Player14FirstName: '',
    team9Player14LastName: '',
    team9Player15FirstName: '',
    team9Player15LastName: '',
    team9Player16FirstName: '',
    team9Player16LastName: '',
    team9Player17FirstName: '',
    team9Player17LastName: '',
    team9Player18FirstName: '',
    team9Player18LastName: '',
    team9Player19FirstName: '',
    team9Player19LastName: '',
    team9Player20FirstName: '',
    team9Player20LastName: '',


  },
  reducers: {
    updateTeam9PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam9Player1FirstName: (state, action) => {
      state.team9Player1FirstName = action.payload
    },
    updateTeam9Player1LastName: (state, action) => {
      state.team9Player1LastName = action.payload
    },
    updateTeam9Player2FirstName: (state, action) => {
      state.team9Player2FirstName = action.payload
    },
    updateTeam9Player2LastName: (state, action) => {
      state.team9Player2LastName = action.payload
    },
    updateTeam9Player3FirstName: (state, action) => {
      state.team9Player3FirstName = action.payload
    },
    updateTeam9Player3LastName: (state, action) => {
      state.team9Player3LastName = action.payload
    },
    updateTeam9Player4FirstName: (state, action) => {
      state.team9Player4FirstName = action.payload
    },
    updateTeam9Player4LastName: (state, action) => {
      state.team9Player4LastName = action.payload
    },
    updateTeam9Player5FirstName: (state, action) => {
      state.team9Player5FirstName = action.payload
    },
    updateTeam9Player5LastName: (state, action) => {
      state.team9Player5LastName = action.payload
    },
    updateTeam9Player6FirstName: (state, action) => {
      state.team9Player6FirstName = action.payload
    },
    updateTeam9Player6LastName: (state, action) => {
      state.team9Player6LastName = action.payload
    },
    updateTeam9Player7FirstName: (state, action) => {
      state.team9Player7FirstName = action.payload
    },
    updateTeam9Player7LastName: (state, action) => {
      state.team9Player7LastName = action.payload
    },
    updateTeam9Player8FirstName: (state, action) => {
      state.team9Player8FirstName = action.payload
    },
    updateTeam9Player8LastName: (state, action) => {
      state.team9Player8LastName = action.payload
    },
    updateTeam9Player9FirstName: (state, action) => {
      state.team9Player9FirstName = action.payload
    },
    updateTeam9Player9LastName: (state, action) => {
      state.team9Player9LastName = action.payload
    },
    updateTeam9Player10FirstName: (state, action) => {
      state.team9Player10FirstName = action.payload
    },
    updateTeam9Player10LastName: (state, action) => {
      state.team9Player10LastName = action.payload
    },
    updateTeam9Player11FirstName: (state, action) => {
      state.team9Player11FirstName = action.payload
    },
    updateTeam9Player11LastName: (state, action) => {
      state.team9Player11LastName = action.payload
    },
    updateTeam9Player12FirstName: (state, action) => {
      state.team9Player12FirstName = action.payload
    },
    updateTeam9Player12LastName: (state, action) => {
      state.team9Player12LastName = action.payload
    },
    updateTeam9Player13FirstName: (state, action) => {
      state.team9Player13FirstName = action.payload
    },
    updateTeam9Player13LastName: (state, action) => {
      state.team9Player13LastName = action.payload
    },
    updateTeam9Player14FirstName: (state, action) => {
      state.team9Player14FirstName = action.payload
    },
    updateTeam9Player14LastName: (state, action) => {
      state.team9Player14LastName = action.payload
    },
    updateTeam9Player15FirstName: (state, action) => {
      state.team9Player15FirstName = action.payload
    },
    updateTeam9Player15LastName: (state, action) => {
      state.team9Player15LastName = action.payload
    },
    updateTeam9Player16FirstName: (state, action) => {
      state.team9Player16FirstName = action.payload
    },
    updateTeam9Player16LastName: (state, action) => {
      state.team9Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam9Team1PlayerInfo,
  updateTeam9Player1FirstName,
  updateTeam9Player1LastName,
  updateTeam9Player2FirstName,
  updateTeam9Player2LastName,
  updateTeam9Player3FirstName,
  updateTeam9Player3LastName,
  updateTeam9Player4FirstName,
  updateTeam9Player4LastName,
  updateTeam9Player5FirstName,
  updateTeam9Player5LastName,
  updateTeam9Player6FirstName,
  updateTeam9Player6LastName,
  updateTeam9Player7FirstName,
  updateTeam9Player7LastName,
  updateTeam9Player8FirstName,
  updateTeam9Player8LastName,
  updateTeam9Player9FirstName,
  updateTeam9Player9LastName,
  updateTeam9Player10FirstName,
  updateTeam9Player10LastName,
  updateTeam9Player11FirstName,
  updateTeam9Player11LastName,
  updateTeam9Player12FirstName,
  updateTeam9Player12LastName,
  updateTeam9Player13FirstName,
  updateTeam9Player13LastName,
  updateTeam9Player14FirstName,
  updateTeam9Player14LastName,
  updateTeam9Player15FirstName,
  updateTeam9Player15LastName,
  updateTeam9Player16FirstName,
  updateTeam9Player16LastName,

} = team9PlayerInfoSlice.actions
export default team9PlayerInfoSlice.reducer
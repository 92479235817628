import { createSlice } from '@reduxjs/toolkit'

export const team13PlayerInfoSlice = createSlice({
  name: 'team13PlayerInfo',
  initialState: {
    team13Player1FirstName: '',
    team13Player1LastName: '',
    team13Player2FirstName: '',
    team13Player2LastName: '',
    team13Player3FirstName: '',
    team13Player3LastName: '',
    team13Player4FirstName: '',
    team13Player4LastName: '',
    team13Player5FirstName: '',
    team13Player5LastName: '',
    team13Player6FirstName: '',
    team13Player6LastName: '',
    team13Player7FirstName: '',
    team13Player7LastName: '',
    team13Player8FirstName: '',
    team13Player8LastName: '',
    team13Player9FirstName: '',
    team13Player9LastName: '',
    team13Player10FirstName: '',
    team13Player10LastName: '',
    team13Player11FirstName: '',
    team13Player11LastName: '',
    team13Player12FirstName: '',
    team13Player12LastName: '',
    team13Player13FirstName: '',
    team13Player13LastName: '',
    team13Player14FirstName: '',
    team13Player14LastName: '',
    team13Player15FirstName: '',
    team13Player15LastName: '',
    team13Player16FirstName: '',
    team13Player16LastName: '',
    team13Player17FirstName: '',
    team13Player17LastName: '',
    team13Player18FirstName: '',
    team13Player18LastName: '',
    team13Player19FirstName: '',
    team13Player19LastName: '',
    team13Player20FirstName: '',
    team13Player20LastName: '',


  },
  reducers: {
    updateTeam13PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam13Player1FirstName: (state, action) => {
      state.team13Player1FirstName = action.payload
    },
    updateTeam13Player1LastName: (state, action) => {
      state.team13Player1LastName = action.payload
    },
    updateTeam13Player2FirstName: (state, action) => {
      state.team13Player2FirstName = action.payload
    },
    updateTeam13Player2LastName: (state, action) => {
      state.team13Player2LastName = action.payload
    },
    updateTeam13Player3FirstName: (state, action) => {
      state.team13Player3FirstName = action.payload
    },
    updateTeam13Player3LastName: (state, action) => {
      state.team13Player3LastName = action.payload
    },
    updateTeam13Player4FirstName: (state, action) => {
      state.team13Player4FirstName = action.payload
    },
    updateTeam13Player4LastName: (state, action) => {
      state.team13Player4LastName = action.payload
    },
    updateTeam13Player5FirstName: (state, action) => {
      state.team13Player5FirstName = action.payload
    },
    updateTeam13Player5LastName: (state, action) => {
      state.team13Player5LastName = action.payload
    },
    updateTeam13Player6FirstName: (state, action) => {
      state.team13Player6FirstName = action.payload
    },
    updateTeam13Player6LastName: (state, action) => {
      state.team13Player6LastName = action.payload
    },
    updateTeam13Player7FirstName: (state, action) => {
      state.team13Player7FirstName = action.payload
    },
    updateTeam13Player7LastName: (state, action) => {
      state.team13Player7LastName = action.payload
    },
    updateTeam13Player8FirstName: (state, action) => {
      state.team13Player8FirstName = action.payload
    },
    updateTeam13Player8LastName: (state, action) => {
      state.team13Player8LastName = action.payload
    },
    updateTeam13Player9FirstName: (state, action) => {
      state.team13Player9FirstName = action.payload
    },
    updateTeam13Player9LastName: (state, action) => {
      state.team13Player9LastName = action.payload
    },
    updateTeam13Player10FirstName: (state, action) => {
      state.team13Player10FirstName = action.payload
    },
    updateTeam13Player10LastName: (state, action) => {
      state.team13Player10LastName = action.payload
    },
    updateTeam13Player11FirstName: (state, action) => {
      state.team13Player11FirstName = action.payload
    },
    updateTeam13Player11LastName: (state, action) => {
      state.team13Player11LastName = action.payload
    },
    updateTeam13Player12FirstName: (state, action) => {
      state.team13Player12FirstName = action.payload
    },
    updateTeam13Player12LastName: (state, action) => {
      state.team13Player12LastName = action.payload
    },
    updateTeam13Player13FirstName: (state, action) => {
      state.team13Player13FirstName = action.payload
    },
    updateTeam13Player13LastName: (state, action) => {
      state.team13Player13LastName = action.payload
    },
    updateTeam13Player14FirstName: (state, action) => {
      state.team13Player14FirstName = action.payload
    },
    updateTeam13Player14LastName: (state, action) => {
      state.team13Player14LastName = action.payload
    },
    updateTeam13Player15FirstName: (state, action) => {
      state.team13Player15FirstName = action.payload
    },
    updateTeam13Player15LastName: (state, action) => {
      state.team13Player15LastName = action.payload
    },
    updateTeam13Player16FirstName: (state, action) => {
      state.team13Player16FirstName = action.payload
    },
    updateTeam13Player16LastName: (state, action) => {
      state.team13Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam13Team1PlayerInfo,
  updateTeam13Player1FirstName,
  updateTeam13Player1LastName,
  updateTeam13Player2FirstName,
  updateTeam13Player2LastName,
  updateTeam13Player3FirstName,
  updateTeam13Player3LastName,
  updateTeam13Player4FirstName,
  updateTeam13Player4LastName,
  updateTeam13Player5FirstName,
  updateTeam13Player5LastName,
  updateTeam13Player6FirstName,
  updateTeam13Player6LastName,
  updateTeam13Player7FirstName,
  updateTeam13Player7LastName,
  updateTeam13Player8FirstName,
  updateTeam13Player8LastName,
  updateTeam13Player9FirstName,
  updateTeam13Player9LastName,
  updateTeam13Player10FirstName,
  updateTeam13Player10LastName,
  updateTeam13Player11FirstName,
  updateTeam13Player11LastName,
  updateTeam13Player12FirstName,
  updateTeam13Player12LastName,
  updateTeam13Player13FirstName,
  updateTeam13Player13LastName,
  updateTeam13Player14FirstName,
  updateTeam13Player14LastName,
  updateTeam13Player15FirstName,
  updateTeam13Player15LastName,
  updateTeam13Player16FirstName,
  updateTeam13Player16LastName,

} = team13PlayerInfoSlice.actions
export default team13PlayerInfoSlice.reducer
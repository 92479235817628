import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, FormControl, FormGroup, RadioGroup, FormLabel, Select, MenuItem, FormControlLabel, Radio } from '@mui/material';
import PlayerFields from './PlayerFields';


const PlayersPage = (props) => {

  const teamNum = props.teamNum;

  console.log('num of players', props.numOfPlayersState);

  const conditionallyRenderPlayerFields = () => {
    let playerFields = [];
    for (let i = 0; i < props.numOfPlayersState; i++) {
      playerFields.push(<PlayerFields index={i} teamNum={teamNum}  playerNum={i + 1} />)
    }
    return playerFields;
  }


  return (
    <Box sx={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%', zIndex: 1, alignItems: 'center',  }}>
      <Typography variant='h5' fontWeight={600}>Team {`${teamNum}`} Players</Typography>
      <Typography>This will be used for the nationwide player leaderboard</Typography>
      {conditionallyRenderPlayerFields()}
    </Box>

  )
}


export default PlayersPage;





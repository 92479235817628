import React, { useState, useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography, FormControl, FormGroup, RadioGroup, FormLabel, Select, MenuItem, FormControlLabel, Radio } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  updateRegistrationAnswersTeam0Gender, updateRegistrationAnswersTeam1Gender, updateRegistrationAnswersTeam2Gender, updateRegistrationAnswersTeam3Gender, updateRegistrationAnswersTeam4Gender,
  updateRegistrationAnswersTeam5Gender, updateRegistrationAnswersTeam6Gender, updateRegistrationAnswersTeam7Gender, updateRegistrationAnswersTeam8Gender, updateRegistrationAnswersTeam9Gender, updateRegistrationAnswersTeam10Gender,
  updateRegistrationAnswersTeam11Gender, updateRegistrationAnswersTeam12Gender, updateRegistrationAnswersTeam13Gender, updateRegistrationAnswersTeam14Gender, updateRegistrationAnswersTeam15Gender,
} from '../../store/reducers/registrationAnswers';
// import all division actions 
import {
  updateRegistrationAnswersTeam0Division, updateRegistrationAnswersTeam1Division, updateRegistrationAnswersTeam2Division, updateRegistrationAnswersTeam3Division, updateRegistrationAnswersTeam4Division,
  updateRegistrationAnswersTeam5Division, updateRegistrationAnswersTeam6Division, updateRegistrationAnswersTeam7Division, updateRegistrationAnswersTeam8Division, updateRegistrationAnswersTeam9Division, updateRegistrationAnswersTeam10Division,
  updateRegistrationAnswersTeam11Division, updateRegistrationAnswersTeam12Division, updateRegistrationAnswersTeam13Division, updateRegistrationAnswersTeam14Division, updateRegistrationAnswersTeam15Division,
} from '../../store/reducers/registrationAnswers';

import {
  updateRegistrationAnswersTeam0NumOfPlayers, updateRegistrationAnswersTeam1NumOfPlayers, updateRegistrationAnswersTeam2NumOfPlayers, updateRegistrationAnswersTeam3NumOfPlayers, updateRegistrationAnswersTeam4NumOfPlayers,
  updateRegistrationAnswersTeam5NumOfPlayers, updateRegistrationAnswersTeam6NumOfPlayers, updateRegistrationAnswersTeam7NumOfPlayers, updateRegistrationAnswersTeam8NumOfPlayers,
  updateRegistrationAnswersTeam9NumOfPlayers, updateRegistrationAnswersTeam10NumOfPlayers, updateRegistrationAnswersTeam11NumOfPlayers, updateRegistrationAnswersTeam12NumOfPlayers, updateRegistrationAnswersTeam13NumOfPlayers,
  updateRegistrationAnswersTeam14NumOfPlayers, updateRegistrationAnswersTeam15NumOfPlayers,
} from '../../store/reducers/registrationAnswers';

import {
  updateRegistrationAnswersTeam0SkillLevel, updateRegistrationAnswersTeam1SkillLevel, updateRegistrationAnswersTeam2SkillLevel, updateRegistrationAnswersTeam3SkillLevel, updateRegistrationAnswersTeam4SkillLevel,
  updateRegistrationAnswersTeam5SkillLevel, updateRegistrationAnswersTeam6SkillLevel, updateRegistrationAnswersTeam7SkillLevel, updateRegistrationAnswersTeam8SkillLevel, updateRegistrationAnswersTeam9SkillLevel,
  updateRegistrationAnswersTeam10SkillLevel, updateRegistrationAnswersTeam11SkillLevel, updateRegistrationAnswersTeam12SkillLevel, updateRegistrationAnswersTeam13SkillLevel, updateRegistrationAnswersTeam14SkillLevel,
  updateRegistrationAnswersTeam15SkillLevel,
} from '../../store/reducers/registrationAnswers';

const TeamPage = (props) => {

  const dispatch = useDispatch(); 
  const registrationAnswers = useSelector(state => state.registrationAnswers);

  const [numOfPlayersError, setNumOfPlayersError] = useState(false);

  const genderFunction = [
    updateRegistrationAnswersTeam0Gender, updateRegistrationAnswersTeam1Gender, updateRegistrationAnswersTeam2Gender, updateRegistrationAnswersTeam3Gender, updateRegistrationAnswersTeam4Gender,
    updateRegistrationAnswersTeam5Gender, updateRegistrationAnswersTeam6Gender, updateRegistrationAnswersTeam7Gender, updateRegistrationAnswersTeam8Gender, updateRegistrationAnswersTeam9Gender, updateRegistrationAnswersTeam10Gender,
    updateRegistrationAnswersTeam11Gender, updateRegistrationAnswersTeam12Gender, updateRegistrationAnswersTeam13Gender, updateRegistrationAnswersTeam14Gender, updateRegistrationAnswersTeam15Gender,
  ]

  const divisionFunction = [
    updateRegistrationAnswersTeam0Division, updateRegistrationAnswersTeam1Division, updateRegistrationAnswersTeam2Division, updateRegistrationAnswersTeam3Division, updateRegistrationAnswersTeam4Division,
    updateRegistrationAnswersTeam5Division, updateRegistrationAnswersTeam6Division, updateRegistrationAnswersTeam7Division, updateRegistrationAnswersTeam8Division, updateRegistrationAnswersTeam9Division, updateRegistrationAnswersTeam10Division,
    updateRegistrationAnswersTeam11Division, updateRegistrationAnswersTeam12Division, updateRegistrationAnswersTeam13Division, updateRegistrationAnswersTeam14Division, updateRegistrationAnswersTeam15Division,
  ]

  const numOfPlayersFunction = [
    updateRegistrationAnswersTeam0NumOfPlayers, updateRegistrationAnswersTeam1NumOfPlayers, updateRegistrationAnswersTeam2NumOfPlayers, updateRegistrationAnswersTeam3NumOfPlayers, updateRegistrationAnswersTeam4NumOfPlayers,
    updateRegistrationAnswersTeam5NumOfPlayers, updateRegistrationAnswersTeam6NumOfPlayers, updateRegistrationAnswersTeam7NumOfPlayers, updateRegistrationAnswersTeam8NumOfPlayers,
    updateRegistrationAnswersTeam9NumOfPlayers, updateRegistrationAnswersTeam10NumOfPlayers, updateRegistrationAnswersTeam11NumOfPlayers, updateRegistrationAnswersTeam12NumOfPlayers, updateRegistrationAnswersTeam13NumOfPlayers, updateRegistrationAnswersTeam14NumOfPlayers, updateRegistrationAnswersTeam15NumOfPlayers,
  ] 

  const skillLevelFunction = [
    updateRegistrationAnswersTeam0SkillLevel, updateRegistrationAnswersTeam1SkillLevel, updateRegistrationAnswersTeam2SkillLevel, updateRegistrationAnswersTeam3SkillLevel, updateRegistrationAnswersTeam4SkillLevel,
    updateRegistrationAnswersTeam5SkillLevel, updateRegistrationAnswersTeam6SkillLevel, updateRegistrationAnswersTeam7SkillLevel, updateRegistrationAnswersTeam8SkillLevel, updateRegistrationAnswersTeam9SkillLevel,
    updateRegistrationAnswersTeam10SkillLevel, updateRegistrationAnswersTeam11SkillLevel, updateRegistrationAnswersTeam12SkillLevel, updateRegistrationAnswersTeam13SkillLevel, updateRegistrationAnswersTeam14SkillLevel,
    updateRegistrationAnswersTeam15SkillLevel,
  ]

  const numOfPlayersHandler = (e) => {
    const number = Number(e.target.value )
    number > 0 && number <= 16 ? dispatch(numOfPlayersFunction[props.teamNum - 1](number)) : setNumOfPlayersError(true)
  }

  console.log(`skill level: team ${props.teamNum} is ${registrationAnswers[`team${props.teamNum - 1}SkillLevel`]}`)
  console.log('the division is: ', registrationAnswers[`team${props.teamNum - 1}Division`])
  return (
    <FormGroup sx={{}}>
      <Typography variant='h5' fontWeight={600}>Team {props.teamNum}</Typography>
      <FormControl>
        <FormLabel >What Gender is this Team?</FormLabel>
        <RadioGroup
          onChange={(e) => {
            dispatch(genderFunction[props.teamNum - 1](e.target.value))
          }}
          defaultValue="Boys"
        >
          <FormControlLabel value="Boys" control={<Radio />} label="Boys" />
          <FormControlLabel value="Girls" control={<Radio />} label="Girls" />
        </RadioGroup>
      </FormControl>
      <FormLabel >Team Division / Grade</FormLabel>
      <Select value={registrationAnswers[`team${props.teamNum - 1}Division`]} onChange={(e) => {
        dispatch(divisionFunction[props.teamNum - 1](e.target.value))
      }} variant='outlined'>

        <MenuItem value={'4th Grade - 10U'}>10U</MenuItem>
        <MenuItem value={'5th Grade - 11U'}>11U</MenuItem>
        <MenuItem value={'6th Grade - 12U'}>12U</MenuItem>
        <MenuItem value={'7th Grade - 13U'}>13U</MenuItem>
        <MenuItem value={'8th Grade - 14U'}>14U</MenuItem>
        <MenuItem value={'9th Grade - 15U'}>9th Grade</MenuItem>
        <MenuItem value={'10th Grade - 16U'}>10th Grade</MenuItem>
        <MenuItem value={'11th Grade - 17U'}>11th Grade </MenuItem>
        <MenuItem value={'12th Grade - 18U'}>12th Grade</MenuItem>
      </Select>

      <FormLabel >Skill Level</FormLabel>
      <Select value={registrationAnswers[`team${props.teamNum - 1}SkillLevel`]} onChange={(e) => {
        dispatch(skillLevelFunction[props.teamNum - 1](e.target.value))
      }} variant='outlined'>

        <MenuItem value={'Bronze'}>Bronze</MenuItem>
        <MenuItem value={'Silver'}>Silver</MenuItem>
        <MenuItem value={'Gold'}>Gold</MenuItem>

      </Select>

      <FormGroup sx={{ paddingTop: 1 }}>
        <FormLabel margin={'normal'}>How Many Players For This Team?</FormLabel>
        <TextField helperText={numOfPlayersError ? 'Maximum players per team is 16' : null} onChange={numOfPlayersHandler} value={registrationAnswers[`team${props.teamNum - 1}NumOfPlayers`]} margin={'dense'} label="Number of Players" type={'number'} />
      </FormGroup>



    </FormGroup>
  )
}


export default TeamPage;


import { createSlice } from '@reduxjs/toolkit'

export const team6PlayerInfoSlice = createSlice({
  name: 'team6PlayerInfo',
  initialState: {
    team6Player1FirstName: '',
    team6Player1LastName: '',
    team6Player2FirstName: '',
    team6Player2LastName: '',
    team6Player3FirstName: '',
    team6Player3LastName: '',
    team6Player4FirstName: '',
    team6Player4LastName: '',
    team6Player5FirstName: '',
    team6Player5LastName: '',
    team6Player6FirstName: '',
    team6Player6LastName: '',
    team6Player7FirstName: '',
    team6Player7LastName: '',
    team6Player8FirstName: '',
    team6Player8LastName: '',
    team6Player9FirstName: '',
    team6Player9LastName: '',
    team6Player10FirstName: '',
    team6Player10LastName: '',
    team6Player11FirstName: '',
    team6Player11LastName: '',
    team6Player12FirstName: '',
    team6Player12LastName: '',
    team6Player13FirstName: '',
    team6Player13LastName: '',
    team6Player14FirstName: '',
    team6Player14LastName: '',
    team6Player15FirstName: '',
    team6Player15LastName: '',
    team6Player16FirstName: '',
    team6Player16LastName: '',
    team6Player17FirstName: '',
    team6Player17LastName: '',
    team6Player18FirstName: '',
    team6Player18LastName: '',
    team6Player19FirstName: '',
    team6Player19LastName: '',
    team6Player20FirstName: '',
    team6Player20LastName: '',


  },
  reducers: {
    updateTeam6PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam6Player1FirstName: (state, action) => {
      state.team6Player1FirstName = action.payload
    },
    updateTeam6Player1LastName: (state, action) => {
      state.team6Player1LastName = action.payload
    },
    updateTeam6Player2FirstName: (state, action) => {
      state.team6Player2FirstName = action.payload
    },
    updateTeam6Player2LastName: (state, action) => {
      state.team6Player2LastName = action.payload
    },
    updateTeam6Player3FirstName: (state, action) => {
      state.team6Player3FirstName = action.payload
    },
    updateTeam6Player3LastName: (state, action) => {
      state.team6Player3LastName = action.payload
    },
    updateTeam6Player4FirstName: (state, action) => {
      state.team6Player4FirstName = action.payload
    },
    updateTeam6Player4LastName: (state, action) => {
      state.team6Player4LastName = action.payload
    },
    updateTeam6Player5FirstName: (state, action) => {
      state.team6Player5FirstName = action.payload
    },
    updateTeam6Player5LastName: (state, action) => {
      state.team6Player5LastName = action.payload
    },
    updateTeam6Player6FirstName: (state, action) => {
      state.team6Player6FirstName = action.payload
    },
    updateTeam6Player6LastName: (state, action) => {
      state.team6Player6LastName = action.payload
    },
    updateTeam6Player7FirstName: (state, action) => {
      state.team6Player7FirstName = action.payload
    },
    updateTeam6Player7LastName: (state, action) => {
      state.team6Player7LastName = action.payload
    },
    updateTeam6Player8FirstName: (state, action) => {
      state.team6Player8FirstName = action.payload
    },
    updateTeam6Player8LastName: (state, action) => {
      state.team6Player8LastName = action.payload
    },
    updateTeam6Player9FirstName: (state, action) => {
      state.team6Player9FirstName = action.payload
    },
    updateTeam6PlayerLastName: (state, action) => {
      state.team6Player9LastName = action.payload
    },
    updateTeam6Player10FirstName: (state, action) => {
      state.team6Player10FirstName = action.payload
    },
    updateTeam6Player10LastName: (state, action) => {
      state.team6Player10LastName = action.payload
    },
    updateTeam6Player11FirstName: (state, action) => {
      state.team6Player11FirstName = action.payload
    },
    updateTeam6Player11LastName: (state, action) => {
      state.team6Player11LastName = action.payload
    },
    updateTeam6Player12FirstName: (state, action) => {
      state.team6Player12FirstName = action.payload
    },
    updateTeam6Player12LastName: (state, action) => {
      state.team6Player12LastName = action.payload
    },
    updateTeam6Player13FirstName: (state, action) => {
      state.team6Player13FirstName = action.payload
    },
    updateTeam6Player13LastName: (state, action) => {
      state.team6Player13LastName = action.payload
    },
    updateTeam6Player14FirstName: (state, action) => {
      state.team6Player14FirstName = action.payload
    },
    updateTeam6Player14LastName: (state, action) => {
      state.team6Player14LastName = action.payload
    },
    updateTeam6Player15FirstName: (state, action) => {
      state.team6Player15FirstName = action.payload
    },
    updateTeam6Player15LastName: (state, action) => {
      state.team6Player15LastName = action.payload
    },
    updateTeam6Player16FirstName: (state, action) => {
      state.team6Player16FirstName = action.payload
    },
    updateTeam6Player16LastName: (state, action) => {
      state.team6Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam6Team1PlayerInfo,
  updateTeam6Player1FirstName,
  updateTeam6Player1LastName,
  updateTeam6Player2FirstName,
  updateTeam6Player2LastName,
  updateTeam6Player3FirstName,
  updateTeam6Player3LastName,
  updateTeam6Player4FirstName,
  updateTeam6Player4LastName,
  updateTeam6Player5FirstName,
  updateTeam6Player5LastName,
  updateTeam6Player6FirstName,
  updateTeam6Player6LastName,
  updateTeam6Player7FirstName,
  updateTeam6Player7LastName,
  updateTeam6Player8FirstName,
  updateTeam6Player8LastName,
  updateTeam6Player9FirstName,
  updateTeam6Player9LastName,
  updateTeam6Player10FirstName,
  updateTeam6Player10LastName,
  updateTeam6Player11FirstName,
  updateTeam6Player11LastName,
  updateTeam6Player12FirstName,
  updateTeam6Player12LastName,
  updateTeam6Player13FirstName,
  updateTeam6Player13LastName,
  updateTeam6Player14FirstName,
  updateTeam6Player14LastName,
  updateTeam6Player15FirstName,
  updateTeam6Player15LastName,
  updateTeam6Player16FirstName,
  updateTeam6Player16LastName,

} = team6PlayerInfoSlice.actions
export default team6PlayerInfoSlice.reducer
import React, { useState } from "react";
import { Box, Button, Container, Grid, Paper, Typography, TextField, Link } from "@mui/material";
import { app, auth } from './firebaseConfig';
import firebase from "firebase/app";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, updateProfile, } from "firebase/auth";

const Register__LoginForm = (props) => {
  const [newUser, setNewUser] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [organizationName, setOrganizationName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newEmailError, setNewEmailError] = useState(false)
  const [emailError, seEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [newPasswordHelperText, setNewPasswordHelperText] = useState('')
  const [passwordHelperText, setPasswordHelperText] = useState('')
  const [newEmailHelperText, setNewEmailHelperText] = useState('')
  const [emailHelperText, setEmailHelperText] = useState('')


  console.log(email)
  console.log(password)
  console.log(organizationName)




  const navigate = props.navigate



  const errorHandler = (error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    if (errorCode == 'auth/email-already-in-use') {
      setNewEmailError(true)
      setNewEmailHelperText('This email is already being used, try signing in instead!')
      console.log('This email is already being used, try signing in instead!')
    }
    console.log('errors: ', errorCode, errorMessage)

    // ..
  }


  const handleSignIn = (e, email, pass) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, pass)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        navigate('/dashboard')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('errors: ', errorCode, errorMessage)
      });
  }

  const handleCreateUser = async () => {
    const userCredential = await createUserWithEmailAndPassword(auth, newEmail, newPassword).catch((error) => errorHandler(error));
    await updateProfile(auth.currentUser, { displayName: organizationName, clubName: organizationName, }).catch((error) => errorHandler(error));


    const ending = () => {
      // Signed in 
      const user = userCredential.user;
      // ...
      navigate('/beginpayment')
      console.log('created account!', user)
    }
    ending()
  }

  const conditionalNewUserForm = () => {
    if (newUser) {
      return (
        <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', height: '100%', width: '100%', }} >
          <Typography variant='h2'>Register</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography align="center" variant="h5">Create Account</Typography>
              <TextField onChange={(e) => setOrganizationName(e.target.value)} label='Organization Name'></TextField>
              <TextField onChange={(e) => setNewEmail(e.target.value)} helperText={newEmailHelperText} error={newEmailError} label='Email'></TextField>
              <TextField onChange={(e) => setNewPassword(e.target.value)} type={"password"} label='Password'></TextField>
              <Button onClick={handleCreateUser} variant='contained'>Register</Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', }}>
              <Typography sx={{ display: 'flex', flexDirection: 'row', }} align="center" variant="body1">Already have an account? <Typography onClick={() => setNewUser(!newUser)} sx={{ textDecoration: 'underline', color: 'blue' }}>{`Login`}</Typography> </Typography>
              <Typography> </Typography>
            </Box>

          </Box>
        </Container>
      )
    } else {
      return (
        <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', height: '100%', width: '100%', }} >
          <Typography variant='h2'>Login</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography align="center" variant="h5">Sign In</Typography>
              <TextField onChange={(e) => setEmail(e.target.value)} label='Email'></TextField>
              <TextField onChange={(e) => setPassword(e.target.value)} type={"password"} label='Password'></TextField>
              <Button onClick={(e) => handleSignIn(e, email, password)} variant='contained'>Sign In</Button>

            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', }}>
              <Typography sx={{ display: 'flex', flexDirection: 'row', }} align="center" variant="body1">Dont have an account? <Typography onClick={() => setNewUser(!newUser)} sx={{ textDecoration: 'underline', color: 'blue' }}>{`Sign Up`}</Typography> </Typography>
              <Typography> </Typography>
            </Box>

          </Box>
        </Container>
      )
    }
  }

  return conditionalNewUserForm()
}

export default Register__LoginForm
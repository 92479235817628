import React from "react";
import { Box, Button, Grid, Typography, FormGroup, FormLabel, TextField, Container } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux'
import { updateRegistrationAnswersNumOfTeams } from '../../store/reducers/registrationAnswers'
import { stripePromise } from '../..';
import { useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import { app, auth, payments, db } from '../../firebaseConfig';
import { getStripePayments, getProducts, createCheckoutSession } from "@stripe/firestore-stripe-payments";



import Payment from "./Payment";



const RegisterNumOfTeams = () => {


  const registrationAnswers = useSelector(state => state.registrationAnswers)

  const dispatch = useDispatch()

  const teamNumberHandler = (event) => {
    console.log(event.target.value)
    const number = Number(event.target.value)
    dispatch(updateRegistrationAnswersNumOfTeams(number))
  }

  const price = () => {
    if (registrationAnswers.numOfTeams === 1) {
      return 310
    }
    else {
      return registrationAnswers.numOfTeams * 285
    }
  }

  const navigate = useNavigate();

  const handlePurchase = async () => {

    const testing = 'price_1MB6jdHPZDnf3WXbQLoxSYDK'
    const testPrice250 = 'price_1MB0DcHPZDnf3WXbiC5NnI9S'
    const testPrice275 = 'price_1MAetZHPZDnf3WXbqlTOgMWW'
    const price250 = 'price_1MB08zHPZDnf3WXbaRVnmwB5'
    const price275 = 'price_1MAzdFHPZDnf3WXb6PRzH95Q'

    
    const singleUnitPrice = 'price_1NUArPHPZDnf3WXbulNR5tk6'
    const multipleUnitPrice = 'price_1NUAsIHPZDnf3WXbYELFdHa2'




    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      lineItems: [{
        price: registrationAnswers.numOfTeams > 1 ? multipleUnitPrice : singleUnitPrice, // Replace with the ID of your price
        quantity: registrationAnswers.numOfTeams,
      }],
      mode: 'payment',
      successUrl: 'https://bigstagetournaments.com/register',
      cancelUrl: 'https://bigstagetournaments.com/beginpayment',
      // allow_promotion_codes: true,

    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.

  }

  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', height: '100vh', width: '100%', }}>
      <Container maxWidth='md' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%', }}>
        <Box>
          <Typography>

          </Typography>
        </Box>
        <FormGroup sx={{ paddingTop: 1.5 }}>
          <FormLabel margin={'normal'}>How Many Teams Are You Registering?</FormLabel>
          <TextField onChange={teamNumberHandler} value={registrationAnswers.numOfTeams} margin={'normal'} label="Number of Teams" type={'number'} />
        </FormGroup>

        <Container maxWidth='xs' sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', }}>
          <Typography variant='h6'>Price: ${price()} </Typography>
          <Button variant='contained' onClick={handlePurchase}>Purchase</Button>

        </Container>

      </Container>
    </Box>
  )
}


export default RegisterNumOfTeams;
import { createSlice } from '@reduxjs/toolkit'

export const team8PlayerInfoSlice = createSlice({
  name: 'team8PlayerInfo',
  initialState: {
    team8Player1FirstName: '',
    team8Player1LastName: '',
    team8Player2FirstName: '',
    team8Player2LastName: '',
    team8Player3FirstName: '',
    team8Player3LastName: '',
    team8Player4FirstName: '',
    team8Player4LastName: '',
    team8Player5FirstName: '',
    team8Player5LastName: '',
    team8Player6FirstName: '',
    team8Player6LastName: '',
    team8Player7FirstName: '',
    team8Player7LastName: '',
    team8Player8FirstName: '',
    team8Player8LastName: '',
    team8Player9FirstName: '',
    team8Player9LastName: '',
    team8Player10FirstName: '',
    team8Player10LastName: '',
    team8Player11FirstName: '',
    team8Player11LastName: '',
    team8Player12FirstName: '',
    team8Player12LastName: '',
    team8Player13FirstName: '',
    team8Player13LastName: '',
    team8Player14FirstName: '',
    team8Player14LastName: '',
    team8Player15FirstName: '',
    team8Player15LastName: '',
    team8Player16FirstName: '',
    team8Player16LastName: '',
    team8Player17FirstName: '',
    team8Player17LastName: '',
    team8Player18FirstName: '',
    team8Player18LastName: '',
    team8Player19FirstName: '',
    team8Player19LastName: '',
    team8Player20FirstName: '',
    team8Player20LastName: '',


  },
  reducers: {
    updateTeam8PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam8Player1FirstName: (state, action) => {
      state.team8Player1FirstName = action.payload
    },
    updateTeam8Player1LastName: (state, action) => {
      state.team8Player1LastName = action.payload
    },
    updateTeam8Player2FirstName: (state, action) => {
      state.team8Player2FirstName = action.payload
    },
    updateTeam8Player2LastName: (state, action) => {
      state.team8Player2LastName = action.payload
    },
    updateTeam8Player3FirstName: (state, action) => {
      state.team8Player3FirstName = action.payload
    },
    updateTeam8Player3LastName: (state, action) => {
      state.team8Player3LastName = action.payload
    },
    updateTeam8Player4FirstName: (state, action) => {
      state.team8Player4FirstName = action.payload
    },
    updateTeam8Player4LastName: (state, action) => {
      state.team8Player4LastName = action.payload
    },
    updateTeam8Player5FirstName: (state, action) => {
      state.team8Player5FirstName = action.payload
    },
    updateTeam8Player5LastName: (state, action) => {
      state.team8Player5LastName = action.payload
    },
    updateTeam8Player6FirstName: (state, action) => {
      state.team8Player6FirstName = action.payload
    },
    updateTeam8Player6LastName: (state, action) => {
      state.team8Player6LastName = action.payload
    },
    updateTeam8Player7FirstName: (state, action) => {
      state.team8Player7FirstName = action.payload
    },
    updateTeam8Player7LastName: (state, action) => {
      state.team8Player7LastName = action.payload
    },
    updateTeam8Player8FirstName: (state, action) => {
      state.team8Player8FirstName = action.payload
    },
    updateTeam8Player8LastName: (state, action) => {
      state.team8Player8LastName = action.payload
    },
    updateTeam8Player9FirstName: (state, action) => {
      state.team8Player9FirstName = action.payload
    },
    updateTeam8Player9LastName: (state, action) => {
      state.team8Player9LastName = action.payload
    },
    updateTeam8Player10FirstName: (state, action) => {
      state.team8Player10FirstName = action.payload
    },
    updateTeam8Player10LastName: (state, action) => {
      state.team8Player10LastName = action.payload
    },
    updateTeam8Player11FirstName: (state, action) => {
      state.team8Player11FirstName = action.payload
    },
    updateTeam8Player11LastName: (state, action) => {
      state.team8Player11LastName = action.payload
    },
    updateTeam8Player12FirstName: (state, action) => {
      state.team8Player12FirstName = action.payload
    },
    updateTeam8Player12LastName: (state, action) => {
      state.team8Player12LastName = action.payload
    },
    updateTeam8Player13FirstName: (state, action) => {
      state.team8Player13FirstName = action.payload
    },
    updateTeam8Player13LastName: (state, action) => {
      state.team8Player13LastName = action.payload
    },
    updateTeam8Player14FirstName: (state, action) => {
      state.team8Player14FirstName = action.payload
    },
    updateTeam8Player14LastName: (state, action) => {
      state.team8Player14LastName = action.payload
    },
    updateTeam8Player15FirstName: (state, action) => {
      state.team8Player15FirstName = action.payload
    },
    updateTeam8Player15LastName: (state, action) => {
      state.team8Player15LastName = action.payload
    },
    updateTeam8Player16FirstName: (state, action) => {
      state.team8Player16FirstName = action.payload
    },
    updateTeam8Player16LastName: (state, action) => {
      state.team8Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam8Team1PlayerInfo,
  updateTeam8Player1FirstName,
  updateTeam8Player1LastName,
  updateTeam8Player2FirstName,
  updateTeam8Player2LastName,
  updateTeam8Player3FirstName,
  updateTeam8Player3LastName,
  updateTeam8Player4FirstName,
  updateTeam8Player4LastName,
  updateTeam8Player5FirstName,
  updateTeam8Player5LastName,
  updateTeam8Player6FirstName,
  updateTeam8Player6LastName,
  updateTeam8Player7FirstName,
  updateTeam8Player7LastName,
  updateTeam8Player8FirstName,
  updateTeam8Player8LastName,
  updateTeam8Player9FirstName,
  updateTeam8Player9LastName,
  updateTeam8Player10FirstName,
  updateTeam8Player10LastName,
  updateTeam8Player11FirstName,
  updateTeam8Player11LastName,
  updateTeam8Player12FirstName,
  updateTeam8Player12LastName,
  updateTeam8Player13FirstName,
  updateTeam8Player13LastName,
  updateTeam8Player14FirstName,
  updateTeam8Player14LastName,
  updateTeam8Player15FirstName,
  updateTeam8Player15LastName,
  updateTeam8Player16FirstName,
  updateTeam8Player16LastName,

} = team8PlayerInfoSlice.actions
export default team8PlayerInfoSlice.reducer
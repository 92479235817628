import React, { useState, useEffect, } from "react";
import { Box, Container, Typography, Card, Button, } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab'
import image from '../images/basketball-court.jpg';
import { ReactComponent as LayeredWaves } from '../images/layered-waves.svg'
import { css, } from '@emotion/react';
import TimelineComponent from "../components/TimelineComponent";
import InformationSection from "../components/InformationSection";
import RegistrationSection from "../components/RegistrationSection";
import RegistrationForm from "../components/RegistrationForm";
import { motion } from "framer-motion";
import RegistrationFormOrgInfo from "../components/registration/RegistrationFormOrgInfo";
import RegistrationFormSocial from "../components/registration/RegistrationFormSocial";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../components/registration/firebaseConfig';
import { onAuthStateChanged } from "firebase/auth";
import { useSelector, useDispatch } from "react-redux";
import { updateDisplayName, updateEmail, updatePhoneNumber, updatePhotoUrl, updateEmailVerified, updateUid, signOutUser } from '../store/reducers/user';
import ResponsiveAppBar from "../components/ResponsiveAppBar";


const Main = () => {

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  }


  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();



  useEffect(() => {
    console.log('scroll position: ', scrollPosition)
  }, [scrollPosition])


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(updateDisplayName(user.displayName));
        dispatch(updateEmail(user.email));
        dispatch(updatePhoneNumber(user.phoneNumber));
        dispatch(updatePhotoUrl(user.photoURL));
        dispatch(updateEmailVerified(user.emailVerified));
        dispatch(updateUid(user.uid));
        console.log('user is signed in')
      } else {

        dispatch(signOutUser());
        console.log('user is signed out')
      }
    })
  }, [auth])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));



  return (
    <Box sx={{ height: '100%', width: '100%', }}>
      <ResponsiveAppBar   barColor={scrollPosition > 100 ? 'primary' : 'transparent'} boxShadow={scrollPosition > 100 ? 4 : 0 } />

      <Box sx={{ overflow: 'hidden', }}>
        <Box sx={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: `linear-gradient(0deg, rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${image})`, backgroundSize: 'cover' }}>
          <Container maxWidth='xl' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', }} >
            <Typography variant='h1' color='white' align="center" fontWeight={700}>Big Stage Hoops</Typography>
            <Button sx={{ height: 50, width: 150, marginTop: '2%' }} href={user.uid ? '/dashboard' : "/signin"} variant='contained'>Register</Button>
          </Container>
        </Box>



        <Container maxWidth='lg' sx={{ display: 'flex', flexDirection: 'column', alignItems: isMobile ? 'center' : null, marginTop: isMobile ? 8 : 8, }} >
          <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
            <Typography variant='h2' align={isMobile ? 'center' : 'left'} fontWeight={700}>Learn Our Story</Typography>
            <Typography sx={{ marginTop: 1 }} align={isMobile ? 'center' : 'left'} variant='body1' fontWeight={500}>We are a National Club Ranking Tournament Provider for the USA for all club teams that attend our events.
              We provide an excellent basketball experience for every coach, program, family, parent and player. We draw attention to programs and players,
              with great nationwide competition. </Typography>
            <Button href={user.uid ? '/dashboard' : "/signin"} sx={{ height: 50, width: 150, marginTop: 4, }} variant='contained'>Register</Button>
          </Container>

        </Container>



        <Box sx={{ height: 'auto', width: '100%', marginTop: 8, }}>
          <InformationSection />
        </Box>


        <Box sx={{ height: 'auto', width: '100%', marginTop: 8, marginBottom: 8, }}>
          <RegistrationSection />
        </Box>


      </Box>



    </Box>
  )
}

export default Main;
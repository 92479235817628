import Reactm, { useEffect, useState } from "react";
import { Box, TextField, RadioGroup, MenuItem, Select, FormHelperText, Radio, FormGroup, FormControl, FormLabel, FormControlLabel, Container, Checkbox, Typography, Card, Divider, Button, } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { updateRegistrationState } from "../../store/reducers/registrationState";
import {
  updateRegistrationAnswers,
  updateRegistrationAnswersOrganizationPhoneNumber, updateRegistrationAnswersOrganizationEmail, updateRegistrationAnswersOrganizationName,
  updateRegistrationAnswersFbTag, updateRegistrationAnswersIgTag, updateRegistrationAnswersOptOut, updateRegistrationAnswersFbHandle,
  updateRegistrationAnswersIgHandle, updateRegistrationAnswersTeamBrag, updateRegistrationAnswersNumOfCoaches, updateRegistrationAnswersNumOfTeams, updateRegistrationAnswersNumOfPlayers,
  updateRegistrationAnswersCoachFirstName1, updateRegistrationAnswersCoachLastName1, updateRegistrationAnswersCoachFirstName2, updateRegistrationAnswersCoachLastName2,
  updateRegistrationAnswersTeam0NumOfPlayers, updateRegistrationAnswersTeam1NumOfPlayers, updateRegistrationAnswersTeam2NumOfPlayers, updateRegistrationAnswersTeam3NumOfPlayers,
  updateRegistrationAnswersTeam4NumOfPlayers, updateRegistrationAnswersTeam5NumOfPlayers, updateRegistrationAnswersTeam6NumOfPlayers, updateRegistrationAnswersTeam7NumOfPlayers,
  updateRegistrationAnswersTeam8NumOfPlayers, updateRegistrationAnswersTeam9NumOfPlayers, updateRegistrationAnswersTeam10NumOfPlayers, updateRegistrationAnswersTeam11NumOfPlayers,
  updateRegistrationAnswersTeam12NumOfPlayers, updateRegistrationAnswersTeam13NumOfPlayers, updateRegistrationAnswersTeam14NumOfPlayers, updateRegistrationAnswersTeam15NumOfPlayers,
} from '../../store/reducers/registrationAnswers'
import { useTheme, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  updateTeam1PlayerInfo, updatePlayer1FirstName, updateTeam2PlayerInfo, updatePlayer2FirstName,
  updateTeam3PlayerInfo, updatePlayer3FirstName, updateTeam4PlayerInfo, updatePlayer4FirstName, updateTeam5PlayerInfo, updatePlayer5FirstName,
  updateTeam6PlayerInfo, updatePlayer6FirstName, updateTeam7PlayerInfo, updatePlayer7FirstName, updateTeam8PlayerInfo, updatePlayer8FirstName,
  updateTeam9PlayerInfo, updatePlayer9FirstName, updateTeam10PlayerInfo, updatePlayer10FirstName, updateTeam11PlayerInfo, updatePlayer11FirstName,
  updateTeam12PlayerInfo, updatePlayer12FirstName, updateTeam13PlayerInfo, updatePlayer13FirstName, updateTeam14PlayerInfo, updatePlayer14FirstName,
  updateTeam15PlayerInfo, updatePlayer15FirstName, updateTeam16PlayerInfo, updatePlayer16FirstName,
} from '../../store/reducers/playerInfoForTeams/team1PlayerInfo';

import TeamPage from "./TeamPage";
import PlayersPage from "./PlayersPage";





const RegistrationFormTeams = () => {

  const dispatch = useDispatch();
  const registrationState = useSelector(state => state.registrationState)
  const registrationAnswers = useSelector(state => state.registrationAnswers)
  const team1PlayerInfo = useSelector(state => state.team1PlayerInfo)
  const team2PlayerInfo = useSelector(state => state.team2PlayerInfo)
  const team3PlayerInfo = useSelector(state => state.team3PlayerInfo)
  const team4PlayerInfo = useSelector(state => state.team4PlayerInfo)
  const team5PlayerInfo = useSelector(state => state.team5PlayerInfo)
  const team6PlayerInfo = useSelector(state => state.team6PlayerInfo)
  const team7PlayerInfo = useSelector(state => state.team7PlayerInfo)
  const team8PlayerInfo = useSelector(state => state.team8PlayerInfo)
  const team9PlayerInfo = useSelector(state => state.team9PlayerInfo)
  const team10PlayerInfo = useSelector(state => state.team10PlayerInfo)
  const team11PlayerInfo = useSelector(state => state.team11PlayerInfo)
  const team12PlayerInfo = useSelector(state => state.team12PlayerInfo)
  const team13PlayerInfo = useSelector(state => state.team13PlayerInfo)
  const team14PlayerInfo = useSelector(state => state.team14PlayerInfo)
  const team15PlayerInfo = useSelector(state => state.team15PlayerInfo)
  const team16PlayerInfo = useSelector(state => state.team16PlayerInfo)


  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));


  const [numOfPlayers, setNumOfPlayers] = useState(1);
  const [numOfTeams, setNumOfTeams] = useState(1);
  const [numOfTeamsError, setNumOfTeamsError] = useState(false);

  const nextHandler = () => {
    dispatch(updateRegistrationState(registrationState.value + 1))
  }

  const previousHandler = () => {
    dispatch(updateRegistrationState(registrationState.value - 1))
  }


  console.log('team1PlayerInfo', team1PlayerInfo)


  const conditionallyRenderTeamFields = () => {
    const teams = []
    for (let i = 0; i < numOfTeams; i++) {
      teams.push(
        teamInfoSection(i)
      )
    } return teams;
  }

  const teamInfoSection = (index, state, setter) => {
    return (

      <FormGroup sx={{}}>
        <Typography variant='h5' fontWeight={600}>Team {index + 1}</Typography>
        <FormControl>
          <FormLabel >What Gender is this Team?</FormLabel>
          <RadioGroup
            defaultValue="Boys"
          >
            <FormControlLabel value="Boys" control={<Radio />} label="Boys" />
            <FormControlLabel value="Girls" control={<Radio />} label="Girls" />
          </RadioGroup>
        </FormControl>
        <FormLabel >Team Division / Grade</FormLabel>
        <Select variant='outlined'>
          <MenuItem value={'2nd Grade - 8U'}>2nd Grade - 8U</MenuItem>
          <MenuItem value={'3rd Grade - 9U'}>3rd Grade - 9U</MenuItem>
          <MenuItem value={'4th Grade - 10U'}>4th Grade - 10U</MenuItem>
          <MenuItem value={'5th Grade - 11U'}>5th Grade - 11U</MenuItem>
          <MenuItem value={'6th Grade - 12U'}>6th Grade - 12U</MenuItem>
          <MenuItem value={'7th Grade - 13U'}>7th Grade - 13U</MenuItem>
          <MenuItem value={'8th Grade - 14U'}>8th Grade - 14U</MenuItem>
          <MenuItem value={'9th Grade - 15U'}>9th Grade - 15U</MenuItem>
        </Select>

        <FormGroup sx={{ paddingTop: 1 }}>
          <FormLabel margin={'normal'}>How Many Players For This Team?</FormLabel>
          <TextField onChange={(event) => playerSetter(event, index)} value={state} margin={'dense'} label="Number of Players" type={'number'} />
        </FormGroup>



      </FormGroup>
    )
  }

  const playerSetter = (event, index) => {



    console.log('index', index)
    if (index === 0) {
      dispatch(updateRegistrationAnswersTeam0NumOfPlayers(Number(event.target.value)))
    } else if (index == 1) {
      dispatch(updateRegistrationAnswersTeam1NumOfPlayers(Number(event.target.value)))
    } else if (index == 2) {
      dispatch(updateRegistrationAnswersTeam2NumOfPlayers(Number(event.target.value)))
    } else if (index == 3) {
      dispatch(updateRegistrationAnswersTeam3NumOfPlayers(Number(event.target.value)))
    } else if (index == 4) {
      dispatch(updateRegistrationAnswersTeam4NumOfPlayers(Number(event.target.value)))
    } else if (index == 5) {
      dispatch(updateRegistrationAnswersTeam5NumOfPlayers(Number(event.target.value)))
    } else if (index == 6) {
      dispatch(updateRegistrationAnswersTeam6NumOfPlayers(Number(event.target.value)))
    } else if (index == 7) {
      dispatch(updateRegistrationAnswersTeam7NumOfPlayers(Number(event.target.value)))
    } else if (index == 8) {
      dispatch(updateRegistrationAnswersTeam8NumOfPlayers(Number(event.target.value)))
    } else if (index == 9) {
      dispatch(updateRegistrationAnswersTeam9NumOfPlayers(Number(event.target.value)))
    } else if (index == 10) {
      dispatch(updateRegistrationAnswersTeam10NumOfPlayers(Number(event.target.value)))
    } else if (index == 11) {
      dispatch(updateRegistrationAnswersTeam11NumOfPlayers(Number(event.target.value)))
    } else if (index == 12) {
      dispatch(updateRegistrationAnswersTeam12NumOfPlayers(Number(event.target.value)))
    } else if (index == 13) {
      dispatch(updateRegistrationAnswersTeam13NumOfPlayers(Number(event.target.value)))
    } else if (index == 14) {
      dispatch(updateRegistrationAnswersTeam14NumOfPlayers(Number(event.target.value)))
    } else if (index == 15) {
      dispatch(updateRegistrationAnswersTeam15NumOfPlayers(Number(event.target.value)))
    } else {
      console.log('error')
    }
  }

  const playerNumberHandler = (event, setter) => {
    console.log(event.target.value)
    setNumOfPlayers(event.target.value)
    setter(event.target.value)

  }

  const conditionallyRenderPlayerFields = (players) => {
    console.log('ran condition');
    const items = []
    for (let i = 0; i < players; i++) {

      const actionFirstName = `updatePlayer${i + 1}FirstName`
      const actionLastName = `updatePlayer${i + 1}LastName`



      items.push(
        <FormGroup sx={{ paddingTop: 1.5, }} >
          <FormLabel sx={{ fontWeight: 600 }}>Player #{i + 1}</FormLabel>
          <FormGroup row={isMobile ? false : true} >
            <TextField onChange={(event) => dispatch(global[actionFirstName](event.target.value))} label='First Name' variant='outlined' margin={'dense'} />
            <TextField label='Last Name' variant='outlined' margin={'dense'} />
          </FormGroup>
        </FormGroup>
      )

    } return items
  }

  useEffect(() => {
    console.log('number of teams', registrationAnswers.numOfTeams)
  }, [registrationAnswers.numOfTeams])


  const teamNumberHandler = (event) => {

    const number = Number(event.target.value)

    number <= 10 && number >= 1 ? dispatch(updateRegistrationAnswersNumOfTeams(number)) : setNumOfTeamsError(true)

  }





  const [numOfPlayers0, setNumOfPlayers0] = useState(1)
  const [numOfPlayers1, setNumOfPlayers1] = useState(1)
  const [numOfPlayers2, setNumOfPlayers2] = useState(1)
  const [numOfPlayers3, setNumOfPlayers3] = useState(1)
  const [numOfPlayers4, setNumOfPlayers4] = useState(1)
  const [numOfPlayers5, setNumOfPlayers5] = useState(1)
  const [numOfPlayers6, setNumOfPlayers6] = useState(1)
  const [numOfPlayers7, setNumOfPlayers7] = useState(1)
  const [numOfPlayers8, setNumOfPlayers8] = useState(1)
  const [numOfPlayers9, setNumOfPlayers9] = useState(1)
  const [numOfPlayers10, setNumOfPlayers10] = useState(1)
  const [numOfPlayers11, setNumOfPlayers11] = useState(1)
  const [numOfPlayers12, setNumOfPlayers12] = useState(1)
  const [numOfPlayers13, setNumOfPlayers13] = useState(1)
  const [numOfPlayers14, setNumOfPlayers14] = useState(1)
  const [numOfPlayers15, setNumOfPlayers15] = useState(1)


  console.log('numOfPlayers0', registrationAnswers.team1NumOfPlayers)

  const conditionallyRenderPage = () => {
    if (registrationState.value === 10) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
          <FormGroup sx={{ paddingTop: 1.5, }}>
            <FormLabel margin={'normal'}>How Many Teams Are You Registering?</FormLabel>
            <TextField onChange={teamNumberHandler} value={registrationAnswers.numOfTeams}
              helperText={numOfTeamsError ? 'You can only register up to 10 teams each time! You can always register more later!' : null}
              margin={'normal'} label="Number of Teams" type={'number'} FormHelperTextProps={{ sx: { height: 10, width: 250, alignSelf: 'center', flexWrap: "wrap" } }} />
          </FormGroup>
        </Box>
      )
    } else if (registrationState.value === 11 && registrationAnswers.numOfTeams >= 1) {
      console.log('team 1')
      return <TeamPage teamNum={1} numOfPlayerState={registrationAnswers.team0NumOfPlayers} />

    } else if (registrationState.value === 12 && registrationAnswers.numOfTeams >= 1) {
      return <PlayersPage teamNum={1} numOfPlayersState={registrationAnswers.team0NumOfPlayers} />

    } else if (registrationState.value === 13 && registrationAnswers.numOfTeams >= 2) {
      return <TeamPage teamNum={2} numOfPlayerState={registrationAnswers.team1NumOfPlayers} />

    } else if (registrationState.value === 14 && registrationAnswers.numOfTeams >= 2) {
      return <PlayersPage teamNum={2} numOfPlayersState={registrationAnswers.team1NumOfPlayers} />

    } else if (registrationState.value === 15 && registrationAnswers.numOfTeams >= 3) {
      return <TeamPage teamNum={3} numOfPlayerState={registrationAnswers.team2NumOfPlayers} />

    } else if (registrationState.value === 16 && registrationAnswers.numOfTeams >= 3) {
      return <PlayersPage teamNum={3} numOfPlayersState={registrationAnswers.team2NumOfPlayers} />

    } else if (registrationState.value === 17 && registrationAnswers.numOfTeams >= 4) {
      return <TeamPage teamNum={4} numOfPlayerState={registrationAnswers.team3NumOfPlayers} />

    } else if (registrationState.value === 18 && registrationAnswers.numOfTeams >= 4) {
      return <PlayersPage teamNum={4} numOfPlayersState={registrationAnswers.team3NumOfPlayers} />

    } else if (registrationState.value === 19 && registrationAnswers.numOfTeams >= 5) {
      return <TeamPage teamNum={5} numOfPlayerState={registrationAnswers.team4NumOfPlayers} />

    } else if (registrationState.value === 20 && registrationAnswers.numOfTeams >= 5) {
      return <PlayersPage teamNum={5} numOfPlayersState={registrationAnswers.team4NumOfPlayers} />

    } else if (registrationState.value === 21 && registrationAnswers.numOfTeams >= 6) {
      return <TeamPage teamNum={6} numOfPlayerState={registrationAnswers.team5NumOfPlayers} />

    } else if (registrationState.value === 22 && registrationAnswers.numOfTeams >= 6) {
      return <PlayersPage teamNum={6} numOfPlayersState={registrationAnswers.team5NumOfPlayers} />

    } else if (registrationState.value === 23 && registrationAnswers.numOfTeams >= 7) {
      return <TeamPage teamNum={7} numOfPlayerState={registrationAnswers.team6NumOfPlayers} />

    } else if (registrationState.value === 24 && registrationAnswers.numOfTeams >= 7) {
      return <PlayersPage teamNum={7} numOfPlayersState={registrationAnswers.team6NumOfPlayers} />


    } else if (registrationState.value === 25 && registrationAnswers.numOfTeams >= 8) {
      return <TeamPage teamNum={8} numOfPlayerState={registrationAnswers.team7NumOfPlayers} />

    } else if (registrationState.value === 26 && registrationAnswers.numOfTeams >= 8) {
      return <PlayersPage teamNum={8} numOfPlayersState={registrationAnswers.team7NumOfPlayers} />


    } else if (registrationState.value === 27 && registrationAnswers.numOfTeams >= 9) {
      return <TeamPage teamNum={9} numOfPlayerState={registrationAnswers.team8NumOfPlayers} />

    } else if (registrationState.value === 28 && registrationAnswers.numOfTeams >= 9) {
      return <PlayersPage teamNum={9} numOfPlayersState={registrationAnswers.team8NumOfPlayers} />


    } else if (registrationState.value === 29 && registrationAnswers.numOfTeams >= 10) {
      return <TeamPage teamNum={10} numOfPlayerState={registrationAnswers.team9NumOfPlayers} />

    } else if (registrationState.value === 30 && registrationAnswers.numOfTeams >= 10) {
      return <PlayersPage teamNum={10} numOfPlayersState={registrationAnswers.team10NumOfPlayers} />


    }



  }

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
      height: '100%', width: '100%', boxSizing: 'border-box',
    }}>
      <Container maxWidth='md' sx={{
        display: 'flex', flexDirection: 'column', width: '100%',
        height: '100%', alignItems: 'center', justifyContent: 'space-between',
      }} >
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', }}>

          {conditionallyRenderPage()}
        </Box>

        <FormGroup sx={{ width: '100%', padding: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} row>
          <Button onClick={previousHandler} variant="outlined" sx={{ marginRight: 1, }} >Previous</Button>
          <Button onClick={nextHandler} variant="contained" sx={{ marginLeft: 1 }} >Next</Button>
        </FormGroup>




      </Container>
    </Box>
  )
}


export default RegistrationFormTeams;
import React from "react";
import { Box, Container, Typography, Card, Divider, Button, } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useTheme, createTheme, responsiveFontSizes, } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { motion } from "framer-motion";
import { useSelector } from "react-redux";



const RegistrationSection = () => {


  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const isLarge = useMediaQuery(useTheme().breakpoints.up('lg'));
  const user = useSelector((state) => state.user);


  const fadeDownVariants = {
    hidden: {
      opacity: 0,
      y: -100,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        delay: 0.5,
      }
    }
  }

  const fadeUpVariants = {
    hidden: {
      opacity: 0,
      y: 200,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.7,
        delay: 0.7,
      }
    }
  }

  const fadeRightVariants = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.7,
        delay: 0.7,
      }
    }
  }

  const fadeLeftVariants = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.7,
        delay: 1,
      }
    }
  }



  return (
    <Box sx={{ height: 'auto', width: 'auto', }} >
      <Container sx={{ height: 'auto', }}>

        <Container maxWidth='lg' sx={{ height: 'auto', }} >
          <Container disableGutters maxWidth='lg' sx={{ display: 'flex', height: 'auto', marginBottom: 4, }}>
            <Container disableGutters maxWidth='lg' sx={{ display: 'flex', justifyContent: isMobile ? 'center' : null, height: 'auto', }}>
              <Typography variant='h2' align={isMobile ? 'center' : 'left'} fontWeight={700} >Registration & Admission Fees</Typography>
            </Container>
          </Container>


          <Container disableGutters sx={{ height: isMobile ? 'auto' : '35vh',  }} >
            <Grid2  container spacing={2} sx={{ display: 'flex', justifyContent: 'center', height: '100%',  }}>

              <Grid2  xs={12} sm={6} md={6} lg={6} sx={{}}>
                <Card raised sx={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 5, }}>
                  <Container disableGutters sx={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', borderRadius: 5, padding: 4 }}>
                    <Container disableGutters sx={{ display: 'flex', height: '40%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', }}>
                      <Typography align='center' variant='h4'>Single Team</Typography>
                      <Divider flexItem />
                      <Typography variant='h4'>$300</Typography>
                    </Container>
                    <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '50%', }}>
                      <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: 'space-evenly', borderColor: 'red', }}>
                        <Typography variant='subtitle1' align='center'>No Refund</Typography>
                        <Typography variant='subtitle1' align='center'>National Club Ranking</Typography>
                        <Typography variant='subtitle1' align='center'>BBCS NBAA Certified Event</Typography>
                      </Container>
                    </Container>
                    <Button href={ user.uid ? '/register' : "/signin"} variant='contained' sx={{ height: '15%', width: '75%', }}>Register</Button>
                  </Container>
                </Card>
              </Grid2>


              <Grid2  xs={12} sm={6} md={6} lg={6} sx={{}}>
                <Card raised sx={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', borderRadius: 5, }}>
                  <Container disableGutters sx={{ display: 'flex', height: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', borderRadius: 5, padding: 4 }}>
                    <Container disableGutters sx={{ display: 'flex', height: '40%', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', }}>
                      <Typography align='center' variant='h4'>Multiple Teams</Typography>
                      <Divider flexItem />
                      <Typography variant='h4'>$275</Typography>
                    </Container>
                    <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '50%', }}>
                      <Container disableGutters sx={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: 'space-evenly', borderColor: 'red', }}>
                        <Typography variant='subtitle1' align='center'>No Refund</Typography>
                        <Typography variant='subtitle1' align='center'>National Club Ranking</Typography>
                        <Typography variant='subtitle1' align='center'>BBCS NBAA Certified Event</Typography>
                      </Container>
                    </Container>
                    <Button href={ user.uid ? '/register' : "/signin"} variant='contained' sx={{ height: '15%', width: '75%', }}>Register</Button>
                  </Container>
                </Card>
              </Grid2>

              

             



            </Grid2>
          </Container>

        </Container>
      </Container>
    </Box >
  )
}


export default RegistrationSection;
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#7b7b7b',

  },

  '&:nth-of-type(even)': {
    border: 0,
    backgroundColor: '#656565',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name: string,
  score: number,
  assists: number,
  blocks: number,
  points: number,
) {
  return { name, score, assists, blocks, points };
}

const rows = [
  createData('John Smith', 159, 6.0, 24, 4.0),
  createData('Alex Will', 237, 9.0, 37, 4.3),
  createData('Mike Hawk', 262, 16.0, 24, 6.0),
  createData('Ben Dover', 305, 3.7, 67, 4.3),
  createData('Lengend Hairy', 356, 16.0, 49, 3.9),
  createData('John Smith', 159, 6.0, 24, 4.0),
  createData('Alex Will', 237, 9.0, 37, 4.3),
  createData('Mike Hawk', 262, 16.0, 24, 6.0),
  createData('Ben Dover', 305, 3.7, 67, 4.3),
  createData('Lengend Hairy', 356, 16.0, 49, 3.9),
  createData('John Smith', 159, 6.0, 24, 4.0),
  createData('Alex Will', 237, 9.0, 37, 4.3),
  createData('Mike Hawk', 262, 16.0, 24, 6.0),
  createData('Ben Dover', 305, 3.7, 67, 4.3),
  createData('Lengend Hairy', 356, 16.0, 49, 3.9),
  createData('John Smith', 159, 6.0, 24, 4.0),
  createData('Alex Will', 237, 9.0, 37, 4.3),
  createData('Mike Hawk', 262, 16.0, 24, 6.0),
  createData('Ben Dover', 305, 3.7, 67, 4.3),
  createData('Lengend Hairy', 356, 16.0, 49, 3.9),
];

export default function CustomizedTables() {
  return (
    <TableContainer sx={{ width: '90%', height: '90%', }} component={Paper}>
      <Table stickyHeader sx={{ minWidth: 700, }} aria-label="customized table">
        <TableHead>
          <TableRow  >
            <StyledTableCell sx={{ border: 0, }} >Players</StyledTableCell>
            <StyledTableCell sx={{ border: 0 }} align="right">Score</StyledTableCell>
            <StyledTableCell sx={{ border: 0 }} align="right">Assists</StyledTableCell>
            <StyledTableCell sx={{ border: 0 }} align="right">Blocks</StyledTableCell>
            <StyledTableCell sx={{ border: 0 }} align="right">Points</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell sx={{ border: 0, color: '#dddddd', }} component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell sx={{ border: 0, color: '#dddddd', }} align="right">{row.score}</StyledTableCell>
              <StyledTableCell sx={{ border: 0, color: '#dddddd',  }} align="right">{row.assists}</StyledTableCell>
              <StyledTableCell sx={{ border: 0, color: '#dddddd',  }} align="right">{row.blocks}</StyledTableCell>
              <StyledTableCell sx={{ border: 0, color: '#dddddd',  }} align="right">{row.points}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { createSlice } from '@reduxjs/toolkit'

export const team15PlayerInfoSlice = createSlice({
  name: 'team15PlayerInfo',
  initialState: {
    team15Player1FirstName: '',
    team15Player1LastName: '',
    team15Player2FirstName: '',
    team15Player2LastName: '',
    team15Player3FirstName: '',
    team15Player3LastName: '',
    team15Player4FirstName: '',
    team15Player4LastName: '',
    team15Player5FirstName: '',
    team15Player5LastName: '',
    team15Player6FirstName: '',
    team15Player6LastName: '',
    team15Player7FirstName: '',
    team15Player7LastName: '',
    team15Player8FirstName: '',
    team15Player8LastName: '',
    team15Player9FirstName: '',
    team15Player9LastName: '',
    team15Player10FirstName: '',
    team15Player10LastName: '',
    team15Player11FirstName: '',
    team15Player11LastName: '',
    team15Player12FirstName: '',
    team15Player12LastName: '',
    team15Player13FirstName: '',
    team15Player13LastName: '',
    team15Player14FirstName: '',
    team15Player14LastName: '',
    team15Player15FirstName: '',
    team15Player15LastName: '',
    team15Player16FirstName: '',
    team15Player16LastName: '',
    team15Player17FirstName: '',
    team15Player17LastName: '',
    team15Player18FirstName: '',
    team15Player18LastName: '',
    team15Player19FirstName: '',
    team15Player19LastName: '',
    team15Player20FirstName: '',
    team15Player20LastName: '',


  },
  reducers: {
    updateTeam15PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam15Player1FirstName: (state, action) => {
      state.team15Player1FirstName = action.payload
    },
    updateTeam15Player1LastName: (state, action) => {
      state.team15Player1LastName = action.payload
    },
    updateTeam15Player2FirstName: (state, action) => {
      state.team15Player2FirstName = action.payload
    },
    updateTeam15Player2LastName: (state, action) => {
      state.team15Player2LastName = action.payload
    },
    updateTeam15Player3FirstName: (state, action) => {
      state.team15Player3FirstName = action.payload
    },
    updateTeam15Player3LastName: (state, action) => {
      state.team15Player3LastName = action.payload
    },
    updateTeam15Player4FirstName: (state, action) => {
      state.team15Player4FirstName = action.payload
    },
    updateTeam15Player4LastName: (state, action) => {
      state.team15Player4LastName = action.payload
    },
    updateTeam15Player5FirstName: (state, action) => {
      state.team15Player5FirstName = action.payload
    },
    updateTeam15Player5LastName: (state, action) => {
      state.team15Player5LastName = action.payload
    },
    updateTeam15Player6FirstName: (state, action) => {
      state.team15Player6FirstName = action.payload
    },
    updateTeam15Player6LastName: (state, action) => {
      state.team15Player6LastName = action.payload
    },
    updateTeam15Player7FirstName: (state, action) => {
      state.team15Player7FirstName = action.payload
    },
    updateTeam15Player7LastName: (state, action) => {
      state.team15Player7LastName = action.payload
    },
    updateTeam15Player8FirstName: (state, action) => {
      state.team15Player8FirstName = action.payload
    },
    updateTeam15Player8LastName: (state, action) => {
      state.team15Player8LastName = action.payload
    },
    updateTeam15Player9FirstName: (state, action) => {
      state.team15Player9FirstName = action.payload
    },
    updateTeam15Player9LastName: (state, action) => {
      state.team15Player9LastName = action.payload
    },
    updateTeam15Player10FirstName: (state, action) => {
      state.team15Player10FirstName = action.payload
    },
    updateTeam15Player10LastName: (state, action) => {
      state.team15Player10LastName = action.payload
    },
    updateTeam15Player11FirstName: (state, action) => {
      state.team15Player11FirstName = action.payload
    },
    updateTeam15Player11LastName: (state, action) => {
      state.team15Player11LastName = action.payload
    },
    updateTeam15Player12FirstName: (state, action) => {
      state.team15Player12FirstName = action.payload
    },
    updateTeam15Player12LastName: (state, action) => {
      state.team15Player12LastName = action.payload
    },
    updateTeam15Player13FirstName: (state, action) => {
      state.team15Player13FirstName = action.payload
    },
    updateTeam15Player13LastName: (state, action) => {
      state.team15Player13LastName = action.payload
    },
    updateTeam15Player14FirstName: (state, action) => {
      state.team15Player14FirstName = action.payload
    },
    updateTeam15Player14LastName: (state, action) => {
      state.team15Player14LastName = action.payload
    },
    updateTeam15Player15FirstName: (state, action) => {
      state.team15Player15FirstName = action.payload
    },
    updateTeam15Player15LastName: (state, action) => {
      state.team15Player15LastName = action.payload
    },
    updateTeam15Player16FirstName: (state, action) => {
      state.team15Player16FirstName = action.payload
    },
    updateTeam15Player16LastName: (state, action) => {
      state.team15Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam15Team1PlayerInfo,
  updateTeam15Player1FirstName,
  updateTeam15Player1LastName,
  updateTeam15Player2FirstName,
  updateTeam15Player2LastName,
  updateTeam15Player3FirstName,
  updateTeam15Player3LastName,
  updateTeam15Player4FirstName,
  updateTeam15Player4LastName,
  updateTeam15Player5FirstName,
  updateTeam15Player5LastName,
  updateTeam15Player6FirstName,
  updateTeam15Player6LastName,
  updateTeam15Player7FirstName,
  updateTeam15Player7LastName,
  updateTeam15Player8FirstName,
  updateTeam15Player8LastName,
  updateTeam15Player9FirstName,
  updateTeam15Player9LastName,
  updateTeam15Player10FirstName,
  updateTeam15Player10LastName,
  updateTeam15Player11FirstName,
  updateTeam15Player11LastName,
  updateTeam15Player12FirstName,
  updateTeam15Player12LastName,
  updateTeam15Player13FirstName,
  updateTeam15Player13LastName,
  updateTeam15Player14FirstName,
  updateTeam15Player14LastName,
  updateTeam15Player15FirstName,
  updateTeam15Player15LastName,
  updateTeam15Player16FirstName,
  updateTeam15Player16LastName,

} = team15PlayerInfoSlice.actions
export default team15PlayerInfoSlice.reducer
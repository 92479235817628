import React, { useState, } from 'react';
import { Box, Typography, Divider, ListItemText, IconButton, Button, } from '@mui/material'
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MessageIcon from '@mui/icons-material/Message';
import { useSelector, useDispatch } from 'react-redux'
import { change } from '../store/reducers/dashboardState'
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';





const DrawerComponent = (props) => {
  const navItems = props.navItems || ['Tournaments', 'Brackets', 'Teams',];
  const mobileOpen = props.mobileOpen || false;
  const setMobileOpen = props.setMobileOpen

  console.log(mobileOpen)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const drawerWidth = props.drawerWidth
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { window } = props;

  const handleDrawerToggle = () => {
    setMobileOpen(false);
  };

  const listItemClickHandler = (item) => {
    if (isMobile) {
      handleDrawerToggle()
      if (item == 'Purchases') {
        dispatch(change(4))
      } else if (item == 'Leads') {
        dispatch(change(5))
      }
    }

    if (item === 'Tournaments') {
      dispatch(change(1))
    } else if (item === 'Brackets') {
      dispatch(change(3))
    } else if (item === 'Teams') {
      dispatch(change(2))
    } else if (item == 'Purchases') {
      dispatch(change(4))
    } else if (item == 'Leads') {
      dispatch(change(5))
    }

  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', height: '100%', }}>
      <Box sx={{}}>
        <Typography variant="h6" sx={{ my: 2 }}>
          Big Stage
        </Typography>
        <Divider />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 'auto', justifyContent: 'center', alignItems: 'center', }}>
          <List sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'space-evenly', width: '100%', }}>
            {navItems.map((item) => (
              <ListItem sx={{ paddingY: 2, }} key={item} disablePadding>
                <ListItemButton onClick={() => listItemClickHandler(item)} sx={{ textAlign: 'center' }}>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', }}>
      <CssBaseline />

      <Box component="nav">
        <Drawer
          container={container}
          variant={isMobile ? 'temporary' : 'permanent'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'flex' },
            flexDirection: 'column',
            height: '100%',
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

    </Box>
  );
}




export default DrawerComponent;
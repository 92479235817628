import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Dashboard from './screens/Dashboard';
import Homepage from './screens/Homepage';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";
import ErrorPage from "./screens/ErrorPage";
import store from './store/store';
import { Provider } from 'react-redux';
import Main from './screens/Main';
import Tournaments from './screens/Tournaments';
import BracketComponent from './components/BracketComponent';
import Register from './screens/Register';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Payment from './components/registration/Payment';
import { app } from './firebaseConfig'
import SignIn from './screens/SignIn';
import RegisterNumOfTeams from './components/registration/RegisterNumOfTeams';
import { firebaseConfig } from './firebaseConfig';
import { FirebaseAppProvider, FirestoreProvider, useFirestoreDocData, useFirestore, useFirebaseApp } from 'reactfire';
import { doc, getFirestore } from 'firebase/firestore';



export const stripePromise = loadStripe('pk_live_51MALP1HPZDnf3WXbGN0idQsg615WbtUSmyOGty9leMCwelKBgFXFurmMbk2tGini6DeGqQsi4tXDMtjaqJ8oIgWn00SppzvbiV');



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Provider store={store}>
        <App />
      </Provider>
    </FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

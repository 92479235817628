import { createSlice } from '@reduxjs/toolkit'

export const team3PlayerInfoSlice = createSlice({
  name: 'team3PlayerInfo',
  initialState: {
    team3Player1FirstName: '',
    team3Player1LastName: '',
    team3Player2FirstName: '',
    team3Player2LastName: '',
    team3Player3FirstName: '',
    team3Player3LastName: '',
    team3Player4FirstName: '',
    team3Player4LastName: '',
    team3Player5FirstName: '',
    team3Player5LastName: '',
    team3Player6FirstName: '',
    team3Player6LastName: '',
    team3Player7FirstName: '',
    team3Player7LastName: '',
    team3Player8FirstName: '',
    team3Player8LastName: '',
    team3Player9FirstName: '',
    team3Player9LastName: '',
    team3Player10FirstName: '',
    team3Player10LastName: '',
    team3Player11FirstName: '',
    team3Player11LastName: '',
    team3Player12FirstName: '',
    team3Player12LastName: '',
    team3Player13FirstName: '',
    team3Player13LastName: '',
    team3Player14FirstName: '',
    team3Player14LastName: '',
    team3Player15FirstName: '',
    team3Player15LastName: '',
    team3Player16FirstName: '',
    team3Player16LastName: '',
    team3Player17FirstName: '',
    team3Player17LastName: '',
    team3Player18FirstName: '',
    team3Player18LastName: '',
    team3Player19FirstName: '',
    team3Player19LastName: '',
    team3Player20FirstName: '',
    team3Player20LastName: '',

  },
  reducers: {
    updateTeam3PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam3Player1FirstName: (state, action) => {
      state.team3Player1FirstName = action.payload
    },
    updateTeam3Player1LastName: (state, action) => {
      state.team3Player1LastName = action.payload
    },
    updateTeam3Player2FirstName: (state, action) => {
      state.team3Player2FirstName = action.payload
    },
    updateTeam3Player2LastName: (state, action) => {
      state.team3Player2LastName = action.payload
    },
    updateTeam3Player3FirstName: (state, action) => {
      state.team3Player3FirstName = action.payload
    },
    updateTeam3Player3LastName: (state, action) => {
      state.team3Player3LastName = action.payload
    },
    updateTeam3Player4FirstName: (state, action) => {
      state.team3Player4FirstName = action.payload
    },
    updateTeam3Player4LastName: (state, action) => {
      state.team3Player4LastName = action.payload
    },
    updateTeam3Player5FirstName: (state, action) => {
      state.team3Player5FirstName = action.payload
    },
    updateTeam3Player5LastName: (state, action) => {
      state.team3Player5LastName = action.payload
    },
    updateTeam3Player6FirstName: (state, action) => {
      state.team3Player6FirstName = action.payload
    },
    updateTeam3Player6LastName: (state, action) => {
      state.team3Player6LastName = action.payload
    },
    updateTeam3Player7FirstName: (state, action) => {
      state.team3Player7FirstName = action.payload
    },
    updateTeam3Player7LastName: (state, action) => {
      state.team3Player7LastName = action.payload
    },
    updateTeam3Player8FirstName: (state, action) => {
      state.team3Player8FirstName = action.payload
    },
    updateTeam3Player8LastName: (state, action) => {
      state.team3Player8LastName = action.payload
    },
    updateTeam3Player9FirstName: (state, action) => {
      state.team3Player9FirstName = action.payload
    },
    updateTeam3Player9LastName: (state, action) => {
      state.team3Player9LastName = action.payload
    },
    updateTeam3Player10FirstName: (state, action) => {
      state.team3Player10FirstName = action.payload
    },
    updateTeam3Player10LastName: (state, action) => {
      state.team3Player10LastName = action.payload
    },
    updateTeam3Player11FirstName: (state, action) => {
      state.team3Player11FirstName = action.payload
    },
    updateTeam3Player11LastName: (state, action) => {
      state.team3Player11LastName = action.payload
    },
    updateTeam3Player12FirstName: (state, action) => {
      state.team3Player12FirstName = action.payload
    },
    updateTeam3Player12LastName: (state, action) => {
      state.team3Player12LastName = action.payload
    },
    updateTeam3Player13FirstName: (state, action) => {
      state.team3Player13FirstName = action.payload
    },
    updateTeam3Player13LastName: (state, action) => {
      state.team3Player13LastName = action.payload
    },
    updateTeam3Player14FirstName: (state, action) => {
      state.team3Player14FirstName = action.payload
    },
    updateTeam3Player14LastName: (state, action) => {
      state.team3Player14LastName = action.payload
    },
    updateTeam3Player15FirstName: (state, action) => {
      state.team3Player15FirstName = action.payload
    },
    updateTeam3Player15LastName: (state, action) => {
      state.team3Player15LastName = action.payload
    },
    updateTeam3Player16FirstName: (state, action) => {
      state.team3Player16FirstName = action.payload
    },
    updateTeam3Player16LastName: (state, action) => {
      state.team3Player16LastName = action.payloadTeam3
    },




  }
})

export const {
  updateTeam3Team1PlayerInfo,
  updateTeam3Player1FirstName,
  updateTeam3Player1LastName,
  updateTeam3Player2FirstName,
  updateTeam3Player2LastName,
  updateTeam3Player3FirstName,
  updateTeam3Player3LastName,
  updateTeam3Player4FirstName,
  updateTeam3Player4LastName,
  updateTeam3Player5FirstName,
  updateTeam3Player5LastName,
  updateTeam3Player6FirstName,
  updateTeam3Player6LastName,
  updateTeam3Player7FirstName,
  updateTeam3Player7LastName,
  updateTeam3Player8FirstName,
  updateTeam3Player8LastName,
  updateTeam3Player9FirstName,
  updateTeam3Player9LastName,
  updateTeam3Player10FirstName,
  updateTeam3Player10LastName,
  updateTeam3Player11FirstName,
  updateTeam3Player11LastName,
  updateTeam3Player12FirstName,
  updateTeam3Player12LastName,
  updateTeam3Player13FirstName,
  updateTeam3Player13LastName,
  updateTeam3Player14FirstName,
  updateTeam3Player14LastName,
  updateTeam3Player15FirstName,
  updateTeam3Player15LastName,
  updateTeam3Player16FirstName,
  updateTeam3Player16LastName,

} = team3PlayerInfoSlice.actions

export default team3PlayerInfoSlice.reducer
import React from "react";
import { css, } from '@emotion/react';
import { Container, Paper, Card, Typography, Box, CardActionArea } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Image from 'mui-image'
import basketballCourt from '../images/basketball-court.jpeg'
import basketballParents from '../images/basketball-parents.jpg'
import basketballCoaches from '../images/basketball-coaches.jfif'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, createTheme, responsiveFontSizes, } from '@mui/material/styles';



const Homepage = () => {

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  return (
    <Box sx={{
      backgroundColor: 'primary.light',
      height: '100vh',
      width: '100%',
      background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${basketballCourt})`,
      backgroundSize: 'cover',
    }}>
      <Container maxWidth="lg" sx={{ height: '100%', paddingTop: 5,  }}>
        <Typography variant='h1' align="center" color={'white'}  >
          Big Stage Hoops
        </Typography>
        <Grid justify="space-around" container spacing={0} sx={{ height: '50vh', marginTop: 10, justifySelf: 'center', display: 'flex', }}
          xs={12} sm={12} lg={12}  >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ paddingRight: isMobile ? 0 : 2, paddingBottom: isMobile ? 2 : 0 }} >
            <Card sx={{
              boxShadow: 8,
              height: '100%', display: 'flex', borderRadius: 3, justifyContent: 'center',
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(${basketballParents})`, backgroundSize: 'cover',
            }}>
              <CardActionArea>
                <Typography gutterBottom={false} variant="h2" component="div" sx={{ display: 'flex', alignSelf: 'flex-start', marginTop: 2, justifyContent: 'center', color: 'white', }}>
                  Parents
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} sx={{ paddingLeft: isMobile ? 0 : 2, paddingTop: isMobile ? 2 : 0 }}>
            <Card sx={{
              boxShadow: 8, borderRadius: 3,
              height: '100%', backgroundColor: 'blue', display: 'flex', justifyContent: 'center',
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(${basketballCoaches})`, backgroundSize: 'cover',
            }}>
              <CardActionArea>
                <Typography variant="h2" component="div" sx={{ display: 'flex', alignSelf: 'center', alignSelf: 'flex-start', marginTop: 2, justifyContent: 'center', color: 'white', }} >
                  Coaches
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>

      </Container>


    </Box>
  )
}



export default Homepage;
import { createSlice } from '@reduxjs/toolkit'

export const team5PlayerInfoSlice = createSlice({
  name: 'team5PlayerInfo',
  initialState: {
    team5Player1FirstName: '',
    team5Player1LastName: '',
    team5Player2FirstName: '',
    team5Player2LastName: '',
    team5Player3FirstName: '',
    team5Player3LastName: '',
    team5Player4FirstName: '',
    team5Player4LastName: '',
    team5Player5FirstName: '',
    team5Player5LastName: '',
    team5Player6FirstName: '',
    team5Player6LastName: '',
    team5Player7FirstName: '',
    team5Player7LastName: '',
    team5Player8FirstName: '',
    team5Player8LastName: '',
    team5Player9FirstName: '',
    team5Player9LastName: '',
    team5Player10FirstName: '',
    team5Player10LastName: '',
    team5Player11FirstName: '',
    team5Player11LastName: '',
    team5Player12FirstName: '',
    team5Player12LastName: '',
    team5Player13FirstName: '',
    team5Player13LastName: '',
    team5Player14FirstName: '',
    team5Player14LastName: '',
    team5Player15FirstName: '',
    team5Player15LastName: '',
    team5Player16FirstName: '',
    team5Player16LastName: '',
    team5Player17FirstName: '',
    team5Player17LastName: '',
    team5Player18FirstName: '',
    team5Player18LastName: '',
    team5Player19FirstName: '',
    team5Player19LastName: '',
    team5Player20FirstName: '',
    team5Player20LastName: '',


  },
  reducers: {
    updateTeam5PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam5Player1FirstName: (state, action) => {
      state.team5Player1FirstName = action.payload
    },
    updateTeam5Player1LastName: (state, action) => {
      state.team5Player1LastName = action.payload
    },
    updateTeam5Player2FirstName: (state, action) => {
      state.team5Player2FirstName = action.payload
    },
    updateTeam5Player2LastName: (state, action) => {
      state.team5Player2LastName = action.payload
    },
    updateTeam5Player3FirstName: (state, action) => {
      state.team5Player3FirstName = action.payload
    },
    updateTeam5Player3LastName: (state, action) => {
      state.team5Player3LastName = action.payload
    },
    updateTeam5Player4FirstName: (state, action) => {
      state.team5Player4FirstName = action.payload
    },
    updateTeam5Player4LastName: (state, action) => {
      state.team5Player4LastName = action.payload
    },
    updateTeam5Player5FirstName: (state, action) => {
      state.team5Player5FirstName = action.payload
    },
    updateTeam5Player5LastName: (state, action) => {
      state.team5Player5LastName = action.payload
    },
    updateTeam5Player6FirstName: (state, action) => {
      state.team5Player6FirstName = action.payload
    },
    updateTeam5Player6LastName: (state, action) => {
      state.team5Player6LastName = action.payload
    },
    updateTeam5Player7FirstName: (state, action) => {
      state.team5Player7FirstName = action.payload
    },
    updateTeam5Player7LastName: (state, action) => {
      state.team5Player7LastName = action.payload
    },
    updateTeam5Player8FirstName: (state, action) => {
      state.team5Player8FirstName = action.payload
    },
    updateTeam5Player8LastName: (state, action) => {
      state.team5Player8LastName = action.payload
    },
    updateTeam5Player9FirstName: (state, action) => {
      state.team5Player9FirstName = action.payload
    },
    updateTeam5Player9LastName: (state, action) => {
      state.team5Player9LastName = action.payload
    },
    updateTeam5Player10FirstName: (state, action) => {
      state.team5Player10FirstName = action.payload
    },
    updateTeam5Player10LastName: (state, action) => {
      state.team5Player10LastName = action.payload
    },
    updateTeam5Player11FirstName: (state, action) => {
      state.team5Player11FirstName = action.payload
    },
    updateTeam5Player11LastName: (state, action) => {
      state.team5Player11LastName = action.payload
    },
    updateTeam5Player12FirstName: (state, action) => {
      state.team5Player12FirstName = action.payload
    },
    updateTeam5Player12LastName: (state, action) => {
      state.team5Player12LastName = action.payload
    },
    updateTeam5Player13FirstName: (state, action) => {
      state.team5Player13FirstName = action.payload
    },
    updateTeam5Player13LastName: (state, action) => {
      state.team5Player13LastName = action.payload
    },
    updateTeam5Player14FirstName: (state, action) => {
      state.team5Player14FirstName = action.payload
    },
    updateTeam5Player14LastName: (state, action) => {
      state.team5Player14LastName = action.payload
    },
    updateTeam5Player15FirstName: (state, action) => {
      state.team5Player15FirstName = action.payload
    },
    updateTeam5Player15LastName: (state, action) => {
      state.team5Player15LastName = action.payload
    },
    updateTeam5Player16FirstName: (state, action) => {
      state.team5Player16FirstName = action.payload
    },
    updateTeam5Player16LastName: (state, action) => {
      state.team5Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam5Team1PlayerInfo,
  updateTeam5Player1FirstName,
  updateTeam5Player1LastName,
  updateTeam5Player2FirstName,
  updateTeam5Player2LastName,
  updateTeam5Player3FirstName,
  updateTeam5Player3LastName,
  updateTeam5Player4FirstName,
  updateTeam5Player4LastName,
  updateTeam5Player5FirstName,
  updateTeam5Player5LastName,
  updateTeam5Player6FirstName,
  updateTeam5Player6LastName,
  updateTeam5Player7FirstName,
  updateTeam5Player7LastName,
  updateTeam5Player8FirstName,
  updateTeam5Player8LastName,
  updateTeam5Player9FirstName,
  updateTeam5Player9LastName,
  updateTeam5Player10FirstName,
  updateTeam5Player10LastName,
  updateTeam5Player11FirstName,
  updateTeam5Player11LastName,
  updateTeam5Player12FirstName,
  updateTeam5Player12LastName,
  updateTeam5Player13FirstName,
  updateTeam5Player13LastName,
  updateTeam5Player14FirstName,
  updateTeam5Player14LastName,
  updateTeam5Player15FirstName,
  updateTeam5Player15LastName,
  updateTeam5Player16FirstName,
  updateTeam5Player16LastName,

} = team5PlayerInfoSlice.actions
export default team5PlayerInfoSlice.reducer
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { getStripePayments, getProducts } from "@stripe/firestore-stripe-payments";






// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyCDaqq69h4K2fLY78r7QZ-IlsipCnlvM8o",
  authDomain: "big-stage-5d80a.firebaseapp.com",
  projectId: "big-stage-5d80a",
  storageBucket: "big-stage-5d80a.appspot.com",
  messagingSenderId: "1058291588262",
  appId: "1:1058291588262:web:51d17a34f027f1cd86a867",
  measurementId: "G-8GTSFVLRG7"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);



export const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});
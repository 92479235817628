import { createSlice } from '@reduxjs/toolkit'

export const registrationAnswersSlice = createSlice({
  name: 'registrationAnswers',
  initialState: {
    orginizationName: '',
    organizationEmail: '',
    organizationPhoneNumber: '',
    fbTag: true,
    igTag: true,
    optOut: false,
    fbHandle: '',
    igHandle: '',
    teamBrag: '',
    numOfCoaches: 1,
    numOfTeams: 1,
    numOfPlayers: 1,
    coachFirstName1: '',
    coachLastName1: '',
    coachFirstName2: '',
    coachLastName2: '',
    team0NumOfPlayers: 0,
    team1NumOfPlayers: 0,
    team2NumOfPlayers: 0,
    team3NumOfPlayers: 0,
    team4NumOfPlayers: 0,
    team5NumOfPlayers: 0,
    team6NumOfPlayers: 0,
    team7NumOfPlayers: 0,
    team8NumOfPlayers: 0,
    team9NumOfPlayers: 0,
    team10NumOfPlayers: 0,
    team11NumOfPlayers: 0,
    team12NumOfPlayers: 0,
    team13NumOfPlayers: 0,
    team14NumOfPlayers: 0,
    team15NumOfPlayers: 0,

    team0Gender: 'Boys',
    team1Gender: 'Boys',
    team2Gender: 'Boys',
    team3Gender: 'Boys',
    team4Gender: 'Boys',
    team5Gender: 'Boys',
    team6Gender: 'Boys',
    team7Gender: 'Boys',
    team8Gender: 'Boys',
    team9Gender: 'Boys',
    team10Gender: 'Boys',
    team11Gender: 'Boys',
    team12Gender: 'Boys',
    team13Gender: 'Boys',
    team14Gender: 'Boys',
    team15Gender: 'Boys',

    team0Division: '',
    team1Division: '',
    team2Division: '',
    team3Division: '',
    team4Division: '',
    team5Division: '',
    team6Division: '',
    team7Division: '',
    team8Division: '',
    team9Division: '',
    team10Division: '',
    team11Division: '',
    team12Division: '',
    team13Division: '',
    team14Division: '',
    team15Division: '',

    team0SkillLevel: '',
    team1SkillLevel: '',
    team2SkillLevel: '',
    team3SkillLevel: '',
    team4SkillLevel: '',
    team5SkillLevel: '',
    team6SkillLevel: '',
    team7SkillLevel: '',
    team8SkillLevel: '',
    team9SkillLevel: '',
    team10SkillLevel: '',
    team11SkillLevel: '',
    team12SkillLevel: '',
    team13SkillLevel: '',
    team14SkillLevel: '',
    team15SkillLevel: '',




  },
  reducers: {
    updateRegistrationAnswers: (state, action) => {
      state.value = action.payload
    },
    updateRegistrationAnswersOrganizationName: (state, action) => {
      state.orginizationName = action.payload
    },
    updateRegistrationAnswersOrganizationPhoneNumber: (state, action) => {
      state.organizationPhoneNumber = action.payload
    },
    updateRegistrationAnswersOrganizationEmail: (state, action) => {
      state.organizationEmail = action.payload
    },

    updateRegistrationAnswersFbTag: (state, action) => {
      state.fbTag = action.payload
    },
    updateRegistrationAnswersIgTag: (state, action) => {
      state.igTag = action.payload
    },
    updateRegistrationAnswersOptOut: (state, action) => {
      state.optOut = action.payload
    },
    updateRegistrationAnswersFbHandle: (state, action) => {
      state.fbHandle = action.payload
    },
    updateRegistrationAnswersIgHandle: (state, action) => {
      state.igHandle = action.payload
    },
    updateRegistrationAnswersTeamBrag: (state, action) => {
      state.teamBrag = action.payload
    },
    updateRegistrationAnswersNumOfCoaches: (state, action) => {
      state.numOfCoaches = action.payload
    },
    updateRegistrationAnswersNumOfTeams: (state, action) => {
      state.numOfTeams = action.payload
    },
    updateRegistrationAnswersNumOfPlayers: (state, action) => {
      state.numOfPlayers = action.payload
    },
    updateRegistrationAnswersCoachFirstName1: (state, action) => {
      state.coachFirstName1 = action.payload
    },
    updateRegistrationAnswersCoachLastName1: (state, action) => {
      state.coachLastName1 = action.payload
    },
    updateRegistrationAnswersCoachFirstName2: (state, action) => {
      state.coachFirstName2 = action.payload
    },
    updateRegistrationAnswersCoachLastName2: (state, action) => {
      state.coachLastName2 = action.payload
    },
    updateRegistrationAnswersTeam0NumOfPlayers: (state, action) => {
      state.team0NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam1NumOfPlayers: (state, action) => {
      state.team1NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam2NumOfPlayers: (state, action) => {
      state.team2NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam3NumOfPlayers: (state, action) => {
      state.team3NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam4NumOfPlayers: (state, action) => {
      state.team4NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam5NumOfPlayers: (state, action) => {
      state.team5NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam6NumOfPlayers: (state, action) => {
      state.team6NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam7NumOfPlayers: (state, action) => {
      state.team7NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam8NumOfPlayers: (state, action) => {
      state.team8NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam9NumOfPlayers: (state, action) => {
      state.team9NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam10NumOfPlayers: (state, action) => {
      state.team10NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam11NumOfPlayers: (state, action) => {
      state.team11NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam12NumOfPlayers: (state, action) => {
      state.team12NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam13NumOfPlayers: (state, action) => {
      state.team13NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam14NumOfPlayers: (state, action) => {
      state.team14NumOfPlayers = action.payload
    },
    updateRegistrationAnswersTeam15NumOfPlayers: (state, action) => {
      state.team15NumOfPlayers = action.payload
    },



    updateRegistrationAnswersTeam0Gender: (state, action) => {
      state.team0Gender = action.payload
    },
    updateRegistrationAnswersTeam1Gender: (state, action) => {
      state.team1Gender = action.payload
    },
    updateRegistrationAnswersTeam2Gender: (state, action) => {
      state.team2Gender = action.payload
    },
    updateRegistrationAnswersTeam3Gender: (state, action) => {
      state.team3Gender = action.payload
    },
    updateRegistrationAnswersTeam4Gender: (state, action) => {
      state.team4Gender = action.payload
    },
    updateRegistrationAnswersTeam5Gender: (state, action) => {
      state.team5Gender = action.payload
    },
    updateRegistrationAnswersTeam6Gender: (state, action) => {
      state.team6Gender = action.payload
    },
    updateRegistrationAnswersTeam7Gender: (state, action) => {
      state.team7Gender = action.payload
    },
    updateRegistrationAnswersTeam8Gender: (state, action) => {
      state.team8Gender = action.payload
    },
    updateRegistrationAnswersTeam9Gender: (state, action) => {
      state.team9Gender = action.payload
    },
    updateRegistrationAnswersTeam10Gender: (state, action) => {
      state.team10Gender = action.payload
    },
    updateRegistrationAnswersTeam11Gender: (state, action) => {
      state.team11Gender = action.payload
    },
    updateRegistrationAnswersTeam12Gender: (state, action) => {
      state.team12Gender = action.payload
    },
    updateRegistrationAnswersTeam13Gender: (state, action) => {
      state.team13Gender = action.payload
    },
    updateRegistrationAnswersTeam14Gender: (state, action) => {
      state.team14Gender = action.payload
    },
    updateRegistrationAnswersTeam15Gender: (state, action) => {
      state.team15Gender = action.payload
    },





    updateRegistrationAnswersTeam0Division: (state, action) => {
      state.team0Division = action.payload
    },
    updateRegistrationAnswersTeam1Division: (state, action) => {
      state.team1Division = action.payload
    },
    updateRegistrationAnswersTeam2Division: (state, action) => {
      state.team2Division = action.payload
    },
    updateRegistrationAnswersTeam3Division: (state, action) => {
      state.team3Division = action.payload
    },
    updateRegistrationAnswersTeam4Division: (state, action) => {
      state.team4Division = action.payload
    },
    updateRegistrationAnswersTeam5Division: (state, action) => {
      state.team5Division = action.payload
    },

    updateRegistrationAnswersTeam6Division: (state, action) => {
      state.team6Division = action.payload
    },
    updateRegistrationAnswersTeam7Division: (state, action) => {
      state.team7Division = action.payload
    },
    updateRegistrationAnswersTeam8Division: (state, action) => {
      state.team8Division = action.payload
    },
    updateRegistrationAnswersTeam9Division: (state, action) => {
      state.team9Division = action.payload
    },
    updateRegistrationAnswersTeam10Division: (state, action) => {
      state.team10Division = action.payload
    },
    updateRegistrationAnswersTeam11Division: (state, action) => {
      state.team11Division = action.payload
    },
    updateRegistrationAnswersTeam12Division: (state, action) => {
      state.team12Division = action.payload
    },
    updateRegistrationAnswersTeam13Division: (state, action) => {
      state.team13Division = action.payload
    },
    updateRegistrationAnswersTeam14Division: (state, action) => {
      state.team14Division = action.payload
    },
    updateRegistrationAnswersTeam15Division: (state, action) => {
      state.team15Division = action.payload
    },


    updateRegistrationAnswersTeam0SkillLevel: (state, action) => {
      state.team0SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam1SkillLevel: (state, action) => {
      state.team1SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam2SkillLevel: (state, action) => {
      state.team2SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam3SkillLevel: (state, action) => {
      state.team3SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam4SkillLevel: (state, action) => {
      state.team4SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam5SkillLevel: (state, action) => {
      state.team5SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam6SkillLevel: (state, action) => {
      state.team6SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam7SkillLevel: (state, action) => {
      state.team7SkillLevel = action.payload
    },

    updateRegistrationAnswersTeam8SkillLevel: (state, action) => {
      state.team8SkillLevel = action.payload
    },
    updateRegistrationAnswersTeam9SkillLevel: (state, action) => {
      state.team9SkillLevel = action.payload
    },

    updateRegistrationAnswersTeam10SkillLevel: (state, action) => {
      state.team10SkillLevel = action.payload
    },

    updateRegistrationAnswersTeam11SkillLevel: (state, action) => {
      state.team11SkillLevel = action.payload
    },

    updateRegistrationAnswersTeam12SkillLevel: (state, action) => {
      state.team12SkillLevel = action.payload
    },

    updateRegistrationAnswersTeam13SkillLevel: (state, action) => {
      state.team13SkillLevel = action.payload
    },

    updateRegistrationAnswersTeam14SkillLevel: (state, action) => {
      state.team14SkillLevel = action.payload
    },

    updateRegistrationAnswersTeam15SkillLevel: (state, action) => {
      state.team15SkillLevel = action.payload
    },




    updateRegistrationAnswersTeam0CoachFirstName: (state, action) => {
      state.team0CoachFirstName = action.payload
    },
    updateRegistrationAnswersTeam1CoachFirstName: (state, action) => {
      state.team1CoachFirstName = action.payload
    },
    updateRegistrationAnswersTeam2CoachFirstName: (state, action) => {
      state.team2CoachFirstName = action.payload
    },
    updateRegistrationAnswersTeam3CoachFirstName: (state, action) => {
      state.team3CoachFirstName = action.payload
    },
    updateRegistrationAnswersTeam4CoachFirstName: (state, action) => {
      state.team4CoachFirstName = action.payload
    },
    updateRegistrationAnswersTeam5CoachFirstName: (state, action) => {
      state.team5CoachFirstName = action.payload
    },
    updateRegistrationAnswersTeam6CoachFirstName: (state, action) => {
      state.team6CoachFirstName = action.payload
    },
    updateRegistrationAnswersTeam7CoachFirstName: (state, action) => {
      state.team7CoachFirstName = action.payload
    }





  }
})

export const { updateRegistrationAnswers,
  updateRegistrationAnswersOrganizationPhoneNumber, updateRegistrationAnswersOrganizationEmail, updateRegistrationAnswersOrganizationName,
  updateRegistrationAnswersFbTag, updateRegistrationAnswersIgTag, updateRegistrationAnswersOptOut, updateRegistrationAnswersFbHandle,
  updateRegistrationAnswersIgHandle, updateRegistrationAnswersTeamBrag, updateRegistrationAnswersNumOfCoaches, updateRegistrationAnswersNumOfTeams, updateRegistrationAnswersNumOfPlayers,
  updateRegistrationAnswersCoachFirstName1, updateRegistrationAnswersCoachLastName1, updateRegistrationAnswersCoachFirstName2, updateRegistrationAnswersCoachLastName2,
  updateRegistrationAnswersTeam0NumOfPlayers, updateRegistrationAnswersTeam1NumOfPlayers, updateRegistrationAnswersTeam2NumOfPlayers, updateRegistrationAnswersTeam3NumOfPlayers,
  updateRegistrationAnswersTeam4NumOfPlayers, updateRegistrationAnswersTeam5NumOfPlayers, updateRegistrationAnswersTeam6NumOfPlayers, updateRegistrationAnswersTeam7NumOfPlayers,
  updateRegistrationAnswersTeam8NumOfPlayers, updateRegistrationAnswersTeam9NumOfPlayers, updateRegistrationAnswersTeam10NumOfPlayers, updateRegistrationAnswersTeam11NumOfPlayers,
  updateRegistrationAnswersTeam12NumOfPlayers, updateRegistrationAnswersTeam13NumOfPlayers, updateRegistrationAnswersTeam14NumOfPlayers, updateRegistrationAnswersTeam15NumOfPlayers,
  updateRegistrationAnswersTeam0Name, updateRegistrationAnswersTeam1Name, updateRegistrationAnswersTeam2Name, updateRegistrationAnswersTeam3Name,
  updateRegistrationAnswersTeam4Name, updateRegistrationAnswersTeam5Name, updateRegistrationAnswersTeam6Name, updateRegistrationAnswersTeam7Name,
  updateRegistrationAnswersTeam8Name, updateRegistrationAnswersTeam9Name, updateRegistrationAnswersTeam10Name, updateRegistrationAnswersTeam11Name,
  updateRegistrationAnswersTeam12Name, updateRegistrationAnswersTeam13Name, updateRegistrationAnswersTeam14Name, updateRegistrationAnswersTeam15Name,
  updateRegistrationAnswersTeam0Gender, updateRegistrationAnswersTeam1Gender, updateRegistrationAnswersTeam2Gender, updateRegistrationAnswersTeam3Gender,
  updateRegistrationAnswersTeam4Gender, updateRegistrationAnswersTeam5Gender, updateRegistrationAnswersTeam6Gender, updateRegistrationAnswersTeam7Gender,
  updateRegistrationAnswersTeam8Gender, updateRegistrationAnswersTeam9Gender, updateRegistrationAnswersTeam10Gender, updateRegistrationAnswersTeam11Gender,
  updateRegistrationAnswersTeam12Gender, updateRegistrationAnswersTeam13Gender, updateRegistrationAnswersTeam14Gender, updateRegistrationAnswersTeam15Gender,
  updateRegistrationAnswersTeam0Division, updateRegistrationAnswersTeam1Division, updateRegistrationAnswersTeam2Division, updateRegistrationAnswersTeam3Division,
  updateRegistrationAnswersTeam4Division, updateRegistrationAnswersTeam5Division, updateRegistrationAnswersTeam6Division, updateRegistrationAnswersTeam7Division,
  updateRegistrationAnswersTeam8Division, updateRegistrationAnswersTeam9Division, updateRegistrationAnswersTeam10Division, updateRegistrationAnswersTeam11Division,
  updateRegistrationAnswersTeam12Division, updateRegistrationAnswersTeam13Division, updateRegistrationAnswersTeam14Division, updateRegistrationAnswersTeam15Division,
  updateRegistrationAnswersTeam0CoachFirstName, updateRegistrationAnswersTeam1CoachFirstName, updateRegistrationAnswersTeam2CoachFirstName, updateRegistrationAnswersTeam3CoachFirstName,
  updateRegistrationAnswersTeam4CoachFirstName, updateRegistrationAnswersTeam5CoachFirstName, updateRegistrationAnswersTeam6CoachFirstName, updateRegistrationAnswersTeam7CoachFirstName,
  updateRegistrationAnswersTeam8CoachFirstName, updateRegistrationAnswersTeam9CoachFirstName, updateRegistrationAnswersTeam10CoachFirstName, updateRegistrationAnswersTeam11CoachFirstName,
  updateRegistrationAnswersTeam12CoachFirstName, updateRegistrationAnswersTeam13CoachFirstName, updateRegistrationAnswersTeam14CoachFirstName, updateRegistrationAnswersTeam15CoachFirstName,
  updateRegistrationAnswersTeam0CoachLastName, updateRegistrationAnswersTeam1CoachLastName, updateRegistrationAnswersTeam2CoachLastName, updateRegistrationAnswersTeam3CoachLastName,
  updateRegistrationAnswersTeam4CoachLastName, updateRegistrationAnswersTeam5CoachLastName, updateRegistrationAnswersTeam6CoachLastName, updateRegistrationAnswersTeam7CoachLastName,
  updateRegistrationAnswersTeam8CoachLastName, updateRegistrationAnswersTeam9CoachLastName, updateRegistrationAnswersTeam10CoachLastName, updateRegistrationAnswersTeam11CoachLastName,
  updateRegistrationAnswersTeam12CoachLastName, updateRegistrationAnswersTeam13CoachLastName, updateRegistrationAnswersTeam14CoachLastName, updateRegistrationAnswersTeam15CoachLastName,
  updateRegistrationAnswersTeam0SkillLevel,  updateRegistrationAnswersTeam1SkillLevel,  updateRegistrationAnswersTeam2SkillLevel,  updateRegistrationAnswersTeam3SkillLevel,  updateRegistrationAnswersTeam4SkillLevel,
  updateRegistrationAnswersTeam5SkillLevel,  updateRegistrationAnswersTeam6SkillLevel,  updateRegistrationAnswersTeam7SkillLevel,  updateRegistrationAnswersTeam8SkillLevel,
  updateRegistrationAnswersTeam9SkillLevel,  updateRegistrationAnswersTeam10SkillLevel,  updateRegistrationAnswersTeam11SkillLevel,  updateRegistrationAnswersTeam12SkillLevel,
  updateRegistrationAnswersTeam13SkillLevel,updateRegistrationAnswersTeam14SkillLevel,  updateRegistrationAnswersTeam15SkillLevel,
} = registrationAnswersSlice.actions

export default registrationAnswersSlice.reducer
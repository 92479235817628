import React, { useState, useEffect, } from "react";
import { Box, TextField, RadioGroup, MenuItem, Select, FormHelperText, Radio, FormGroup, FormControl, FormLabel, FormControlLabel, Container, Checkbox, Typography, Card, Divider, Button, } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { updateRegistrationState } from '../../store/reducers/registrationState'
import {
  updateRegistrationAnswers,
  updateRegistrationAnswersOrganizationPhoneNumber, updateRegistrationAnswersOrganizationEmail, updateRegistrationAnswersOrganizationName,
  updateRegistrationAnswersFbTag, updateRegistrationAnswersIgTag, updateRegistrationAnswersOptOut, updateRegistrationAnswersFbHandle,
  updateRegistrationAnswersIgHandle, updateRegistrationAnswersTeamBrag, updateRegistrationAnswersNumOfCoaches,
  updateRegistrationAnswersCoachName1, updateRegistrationAnswersCoachName2
} from '../../store/reducers/registrationAnswers'





const RegistrationFormSocial = (props) => {

  const firstStateNumber = props.firstStateNumber

  const registrationState = useSelector(state => state.registrationState)
  const registrationAnswers = useSelector(state => state.registrationAnswers)
  const dispatch = useDispatch();

  const [currentField, setCurrentField] = useState(0);
  const [fbTag, setFbTag] = useState(true)
  const [igTag, setIgTag] = useState(false)
  const [optOut, setOptOut] = useState(false)

  const totalIndexes = 3;


  const nextHandler = () => {

    if (registrationState.value === firstStateNumber) {
      if (fbTag) {
        setCurrentField(1)
        dispatch(updateRegistrationState(registrationState.value + 1))
      } else if (igTag) {
        setCurrentField(2)
        dispatch(updateRegistrationState(registrationState.value + 2))
      } else if (optOut) {
        setCurrentField(3)
        dispatch(updateRegistrationState(registrationState.value + 3))
      } else if (fbTag == false && igTag == false) {
        setCurrentField(3)
        dispatch(updateRegistrationState(registrationState.value + 3))
      }
    } else if (registrationState.value === firstStateNumber + 1) {
      if (igTag) {
        setCurrentField(2)
        dispatch(updateRegistrationState(registrationState.value + 1))
      } else {
        setCurrentField(3)
        dispatch(updateRegistrationState(registrationState.value + 2))
      }
    } else if (registrationState.value === firstStateNumber + 2) {
      setCurrentField(currentField + 1)
      dispatch(updateRegistrationState(registrationState.value + 1))

    }

    else if (registrationState.value === firstStateNumber + 3) {
      dispatch(updateRegistrationState(registrationState.value + 1))
    }

  }


  const previousHandler = () => {

    if (registrationState.value === firstStateNumber) {
      dispatch(updateRegistrationState(registrationState.value - 1))
    } else if (registrationState.value === firstStateNumber + 1) {
      setCurrentField(0)
      dispatch(updateRegistrationState(registrationState.value - 1))
    } else if (registrationState.value === firstStateNumber + 2) {
      if (fbTag) {
        setCurrentField(1)
        dispatch(updateRegistrationState(registrationState.value - 1))
      } else dispatch(updateRegistrationState(registrationState.value - 2))
    } else if (registrationState.value === firstStateNumber + 3) {
      if (igTag) {
        setCurrentField(2)
        dispatch(updateRegistrationState(registrationState.value - 1))
      } else if (fbTag) {
        setCurrentField(1)
        dispatch(updateRegistrationState(registrationState.value - 2))
      } else {
        setCurrentField(0)
        dispatch(updateRegistrationState(registrationState.value = 3))

      }
    }
  }

  const facebookCheckHandler = (bool) => {
    setFbTag(bool)
    setOptOut(false)
    dispatch(updateRegistrationAnswersFbTag(bool))
    dispatch(updateRegistrationAnswersOptOut(false))
  }
  const instagramCheckHandler = (bool) => {
    setIgTag(bool)
    setOptOut(false)
    dispatch(updateRegistrationAnswersIgTag(bool))
    dispatch(updateRegistrationAnswersOptOut(false))
  }

  const optOutHandler = () => {
    setFbTag(false)
    setIgTag(false)
    dispatch(updateRegistrationAnswersFbTag(false))
    dispatch(updateRegistrationAnswersIgTag(false))
    dispatch(updateRegistrationAnswersOptOut(true))
    setOptOut(true)
  }



  const handleFbHandle = (e) => {
    dispatch(updateRegistrationAnswersFbHandle(e.target.value))
  }

  const handleIgHandle = (e) => {
    dispatch(updateRegistrationAnswersIgHandle(e.target.value))
  }

  const handleTeamBrag = (e) => {
    dispatch(updateRegistrationAnswersTeamBrag(e.target.value))
  }


  const conditionalFieldRender = () => {
    if (registrationState.value === firstStateNumber) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant="h6" gutterBottom component="div">Would you like us to share you on Social Media?</Typography>
          <FormGroup row>
            <FormControlLabel control={<Checkbox checked={registrationAnswers.fbTag} onChange={(event) => { facebookCheckHandler(event.target.checked) }} />} label="Yes, Please Tag Us on Facebook!" />
            <FormControlLabel control={<Checkbox checked={registrationAnswers.igTag} onChange={(event) => { instagramCheckHandler(event.target.checked) }} />} label="Yes, Please Tag Us on IG!" />
            <FormControlLabel control={<Checkbox checked={registrationAnswers.optOut} onChange={optOutHandler} />} label="No, Please Don't Mention Us!" />
          </FormGroup>
        </Box>
      )
    } else if (registrationState.value === firstStateNumber + 1) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column',  flex: 1, alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant="h6" gutterBottom component="div">What is your Facebook Handle?</Typography>
          <TextField label='Facebook Account' variant='outlined' margin={'normal'} value={registrationAnswers.fbHandle} onChange={handleFbHandle} />
        </Box>
      )
    } else if (registrationState.value === firstStateNumber + 2) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant="h6" gutterBottom component="div">What is your Instagram Handle?</Typography>
          <TextField label='Instagram Account' variant='outlined' margin={'normal'} value={registrationAnswers.igHandle} onChange={handleIgHandle} />
        </Box>
      )
    } else {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column',  flex: 1, alignItems: 'center', justifyContent: 'center', }}>
          <Typography variant="h6" gutterBottom component="div">Tell us a little about your club</Typography>
          <TextField label='Brag about your team' minRows={4} multiline variant='outlined' margin={'normal'} value={registrationAnswers.teamBrag} onChange={handleTeamBrag} />
        </Box>

      )
    }
  }


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column',
      height: '100%', width: '100%', boxSizing: 'border-box', 
    }}>
      <Container maxWidth='md' sx={{
        display: 'flex', flexDirection: 'column', width: '100%',
        height: '100%', alignItems: 'center', justifyContent: 'space-between',
      }} >
        {conditionalFieldRender()}
        <FormGroup sx={{ width: '100%', marginTop: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }} row>
          <Button onClick={previousHandler} variant="outlined" sx={{ marginRight: 1, }} >Previous</Button>
          <Button onClick={nextHandler} variant="contained" sx={{ marginLeft: 1 }} >Next</Button>
        </FormGroup>





      </Container>
    </Box>
  )
}


export default RegistrationFormSocial;
import { createSlice } from '@reduxjs/toolkit'

export const team1PlayerInfoSlice = createSlice({
  name: 'team1PlayerInfo',
  initialState: {
    team1Player1FirstName: '',
    team1Player1LastName: '',
    team1Player2FirstName: '',
    team1Player2LastName: '',
    team1Player3FirstName: '',
    team1Player3LastName: '',
    team1Player4FirstName: '',
    team1Player4LastName: '',
    team1Player5FirstName: '',
    team1Player5LastName: '',
    team1Player6FirstName: '',
    team1Player6LastName: '',
    team1Player7FirstName: '',
    team1Player7LastName: '',
    team1Player8FirstName: '',
    team1Player8LastName: '',
    team1Player9FirstName: '',
    team1Player9LastName: '',
    team1Player10FirstName: '',
    team1Player10LastName: '',
    team1Player11FirstName: '',
    team1Player11LastName: '',
    team1Player12FirstName: '',
    team1Player12LastName: '',
    team1Player13FirstName: '',
    team1Player13LastName: '',
    team1Player14FirstName: '',
    team1Player14LastName: '',
    team1Player15FirstName: '',
    team1Player15LastName: '',
    team1Player16FirstName: '',
    team1Player16LastName: '',
    team1Player17FirstName: '',
    team1Player17LastName: '',
    team1Player18FirstName: '',
    team1Player18LastName: '',
    team1Player19FirstName: '',
    team1Player19LastName: '',
    team1Player20FirstName: '',
    team1Player20LastName: '',
  },
  reducers: {
    updateTeam1PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam1Player1FirstName: (state, action) => {
      state.team1Player1FirstName = action.payload
    },
    updateTeam1Player1LastName: (state, action) => {
      state.team1Player1LastName = action.payload
    },
    updateTeam1Player2FirstName: (state, action) => {
      state.team1Player2FirstName = action.payload
    },
    updateTeam1Player2LastName: (state, action) => {
      state.team1Player2LastName = action.payload
    },
    updateTeam1Player3FirstName: (state, action) => {
      state.team1Player3FirstName = action.payload
    },
    updateTeam1Player3LastName: (state, action) => {
      state.team1Player3LastName = action.payload
    },
    updateTeam1Player4FirstName: (state, action) => {
      state.team1Player4FirstName = action.payload
    },
    updateTeam1Player4LastName: (state, action) => {
      state.team1Player4LastName = action.payload
    },
    updateTeam1Player5FirstName: (state, action) => {
      state.team1Player5FirstName = action.payload
    },
    updateTeam1Player5LastName: (state, action) => {
      state.team1Player5LastName = action.payload
    },
    updateTeam1Player6FirstName: (state, action) => {
      state.team1Player6FirstName = action.payload
    },
    updateTeam1Player6LastName: (state, action) => {
      state.team1Player6LastName = action.payload
    },
    updateTeam1Player7FirstName: (state, action) => {
      state.team1Player7FirstName = action.payload
    },
    updateTeam1Player7LastName: (state, action) => {
      state.team1Player7LastName = action.payload
    },
    updateTeam1Player8FirstName: (state, action) => {
      state.team1Player8FirstName = action.payload
    },
    updateTeam1Player8LastName: (state, action) => {
      state.team1Player8LastName = action.payload
    },
    updateTeam1Player9FirstName: (state, action) => {
      state.team1Player9FirstName = action.payload
    },
    updateTeam1Player9LastName: (state, action) => {
      state.team1Player9LastName = action.payload
    },
    updateTeam1Player10FirstName: (state, action) => {
      state.team1Player10FirstName = action.payload
    },
    updateTeam1Player10LastName: (state, action) => {
      state.team1Player10LastName = action.payload
    },
    updateTeam1Player11FirstName: (state, action) => {
      state.team1Player11FirstName = action.payload
    },
    updateTeam1Player11LastName: (state, action) => {
      state.team1Player11LastName = action.payload
    },
    updateTeam1Player12FirstName: (state, action) => {
      state.team1Player12FirstName = action.payload
    },
    updateTeam1Player12LastName: (state, action) => {
      state.team1Player12LastName = action.payload
    },
    updateTeam1Player13FirstName: (state, action) => {
      state.team1Player13FirstName = action.payload
    },
    updateTeam1Player13LastName: (state, action) => {
      state.team1Player13LastName = action.payload
    },
    updateTeam1Player14FirstName: (state, action) => {
      state.team1Player14FirstName = action.payload
    },
    updateTeam1Player14LastName: (state, action) => {
      state.team1Player14LastName = action.payload
    },
    updateTeam1Player15FirstName: (state, action) => {
      state.team1Player15FirstName = action.payload
    },
    updateTeam1Player15LastName: (state, action) => {
      state.team1Player15LastName = action.payload
    },
    updateTeam1Player16FirstName: (state, action) => {
      state.team1Player16FirstName = action.payload
    },
    updateTeam1Player16LastName: (state, action) => {
      state.team1Player16LastName = action.payloadTeam1
    },


  }
})

export const {
  updateTeam1Team1PlayerInfo,
  updateTeam1Player1FirstName,
  updateTeam1Player1LastName,
  updateTeam1Player2FirstName,
  updateTeam1Player2LastName,
  updateTeam1Player3FirstName,
  updateTeam1Player3LastName,
  updateTeam1Player4FirstName,
  updateTeam1Player4LastName,
  updateTeam1Player5FirstName,
  updateTeam1Player5LastName,
  updateTeam1Player6FirstName,
  updateTeam1Player6LastName,
  updateTeam1Player7FirstName,
  updateTeam1Player7LastName,
  updateTeam1Player8FirstName,
  updateTeam1Player8LastName,
  updateTeam1Player9FirstName,
  updateTeam1Player9LastName,
  updateTeam1Player10FirstName,
  updateTeam1Player10LastName,
  updateTeam1Player11FirstName,
  updateTeam1Player11LastName,
  updateTeam1Player12FirstName,
  updateTeam1Player12LastName,
  updateTeam1Player13FirstName,
  updateTeam1Player13LastName,
  updateTeam1Player14FirstName,
  updateTeam1Player14LastName,
  updateTeam1Player15FirstName,
  updateTeam1Player15LastName,
  updateTeam1Player16FirstName,
  updateTeam1Player16LastName,

} = team1PlayerInfoSlice.actions

export default team1PlayerInfoSlice.reducer
import React, { useState, } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth, useSigninCheck } from 'reactfire';


const settings = ['Account', 'Logout'];




function Button__Avatar(props) {

  const userState = props.userState
  const userStateSetter = props.userStateSetter
  const auth = useAuth()
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const user = useSelector((state) => state.user);



  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };




  const signOut = (auth) => {
    console.log('auth', auth)
    auth.signOut().then(() => {
      console.log('signed out')
    })

  }




  const settingClickHandler = (setting) => {
    handleCloseUserMenu()
    if (setting == 'Account') {
      navigate('/account')
    }
    if (setting == 'Logout') {
      signOut(auth)
    }
  }


  const getFirstLetterOfFirstName = (name) => {
    return name.charAt(0).toUpperCase()
  }

  const conditionalRender = () => {
    if (auth.currentUser) {
      return (
        <Box sx={{ flexGrow: 0, }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar src="/static/images/avatar/2.jpg" ></Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => settingClickHandler(setting)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )
    } else {
      return (
        <Button onClick={() => navigate('/signin')} variant='contained'>Login</Button>
      )
    }
  }


  return conditionalRender()
}
export default Button__Avatar;
import { createSlice } from '@reduxjs/toolkit'

export const team12PlayerInfoSlice = createSlice({
  name: 'team12PlayerInfo',
  initialState: {
    team12Player1FirstName: '',
    team12Player1LastName: '',
    team12Player2FirstName: '',
    team12Player2LastName: '',
    team12Player3FirstName: '',
    team12Player3LastName: '',
    team12Player4FirstName: '',
    team12Player4LastName: '',
    team12Player5FirstName: '',
    team12Player5LastName: '',
    team12Player6FirstName: '',
    team12Player6LastName: '',
    team12Player7FirstName: '',
    team12Player7LastName: '',
    team12Player8FirstName: '',
    team12Player8LastName: '',
    team12Player9FirstName: '',
    team12Player9LastName: '',
    team12Player10FirstName: '',
    team12Player10LastName: '',
    team12Player11FirstName: '',
    team12Player11LastName: '',
    team12Player12FirstName: '',
    team12Player12LastName: '',
    team12Player13FirstName: '',
    team12Player13LastName: '',
    team12Player14FirstName: '',
    team12Player14LastName: '',
    team12Player15FirstName: '',
    team12Player15LastName: '',
    team12Player16FirstName: '',
    team12Player16LastName: '',
    team12Player17FirstName: '',
    team12Player17LastName: '',
    team12Player18FirstName: '',
    team12Player18LastName: '',
    team12Player19FirstName: '',
    team12Player19LastName: '',
    team12Player20FirstName: '',
    team12Player20LastName: '',


  },
  reducers: {
    updateTeam12PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam12Player1FirstName: (state, action) => {
      state.team12Player1FirstName = action.payload
    },
    updateTeam12Player1LastName: (state, action) => {
      state.team12Player1LastName = action.payload
    },
    updateTeam12Player2FirstName: (state, action) => {
      state.team12Player2FirstName = action.payload
    },
    updateTeam12Player2LastName: (state, action) => {
      state.team12Player2LastName = action.payload
    },
    updateTeam12Player3FirstName: (state, action) => {
      state.team12Player3FirstName = action.payload
    },
    updateTeam12Player3LastName: (state, action) => {
      state.team12Player3LastName = action.payload
    },
    updateTeam12Player4FirstName: (state, action) => {
      state.team12Player4FirstName = action.payload
    },
    updateTeam12Player4LastName: (state, action) => {
      state.team12Player4LastName = action.payload
    },
    updateTeam12Player5FirstName: (state, action) => {
      state.team12Player5FirstName = action.payload
    },
    updateTeam12Player5LastName: (state, action) => {
      state.team12Player5LastName = action.payload
    },
    updateTeam12Player6FirstName: (state, action) => {
      state.team12Player6FirstName = action.payload
    },
    updateTeam12Player6LastName: (state, action) => {
      state.team12Player6LastName = action.payload
    },
    updateTeam12Player7FirstName: (state, action) => {
      state.team12Player7FirstName = action.payload
    },
    updateTeam12Player7LastName: (state, action) => {
      state.team12Player7LastName = action.payload
    },
    updateTeam12Player8FirstName: (state, action) => {
      state.team12Player8FirstName = action.payload
    },
    updateTeam12Player8LastName: (state, action) => {
      state.team12Player8LastName = action.payload
    },
    updateTeam12Player9FirstName: (state, action) => {
      state.team12Player9FirstName = action.payload
    },
    updateTeam12Player9LastName: (state, action) => {
      state.team12Player9LastName = action.payload
    },
    updateTeam12Player10FirstName: (state, action) => {
      state.team12Player10FirstName = action.payload
    },
    updateTeam12Player10LastName: (state, action) => {
      state.team12Player10LastName = action.payload
    },
    updateTeam12Player11FirstName: (state, action) => {
      state.team12Player11FirstName = action.payload
    },
    updateTeam12Player11LastName: (state, action) => {
      state.team12Player11LastName = action.payload
    },
    updateTeam12Player12FirstName: (state, action) => {
      state.team12Player12FirstName = action.payload
    },
    updateTeam12Player12LastName: (state, action) => {
      state.team12Player12LastName = action.payload
    },
    updateTeam12Player13FirstName: (state, action) => {
      state.team12Player13FirstName = action.payload
    },
    updateTeam12Player13LastName: (state, action) => {
      state.team12Player13LastName = action.payload
    },
    updateTeam12Player14FirstName: (state, action) => {
      state.team12Player14FirstName = action.payload
    },
    updateTeam12Player14LastName: (state, action) => {
      state.team12Player14LastName = action.payload
    },
    updateTeam12Player15FirstName: (state, action) => {
      state.team12Player15FirstName = action.payload
    },
    updateTeam12Player15LastName: (state, action) => {
      state.team12Player15LastName = action.payload
    },
    updateTeam12Player16FirstName: (state, action) => {
      state.team12Player16FirstName = action.payload
    },
    updateTeam12Player16LastName: (state, action) => {
      state.team12Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam12Team1PlayerInfo,
  updateTeam12Player1FirstName,
  updateTeam12Player1LastName,
  updateTeam12Player2FirstName,
  updateTeam12Player2LastName,
  updateTeam12Player3FirstName,
  updateTeam12Player3LastName,
  updateTeam12Player4FirstName,
  updateTeam12Player4LastName,
  updateTeam12Player5FirstName,
  updateTeam12Player5LastName,
  updateTeam12Player6FirstName,
  updateTeam12Player6LastName,
  updateTeam12Player7FirstName,
  updateTeam12Player7LastName,
  updateTeam12Player8FirstName,
  updateTeam12Player8LastName,
  updateTeam12Player9FirstName,
  updateTeam12Player9LastName,
  updateTeam12Player10FirstName,
  updateTeam12Player10LastName,
  updateTeam12Player11FirstName,
  updateTeam12Player11LastName,
  updateTeam12Player12FirstName,
  updateTeam12Player12LastName,
  updateTeam12Player13FirstName,
  updateTeam12Player13LastName,
  updateTeam12Player14FirstName,
  updateTeam12Player14LastName,
  updateTeam12Player15FirstName,
  updateTeam12Player15LastName,
  updateTeam12Player16FirstName,
  updateTeam12Player16LastName,

} = team12PlayerInfoSlice.actions
export default team12PlayerInfoSlice.reducer
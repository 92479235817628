import { createSlice } from '@reduxjs/toolkit'

export const team16PlayerInfoSlice = createSlice({
  name: 'team16PlayerInfo',
  initialState: {
    team16Player1FirstName: '',
    team16Player1LastName: '',
    team16Player2FirstName: '',
    team16Player2LastName: '',
    team16Player3FirstName: '',
    team16Player3LastName: '',
    team16Player4FirstName: '',
    team16Player4LastName: '',
    team16Player5FirstName: '',
    team16Player5LastName: '',
    team16Player6FirstName: '',
    team16Player6LastName: '',
    team16Player7FirstName: '',
    team16Player7LastName: '',
    team16Player8FirstName: '',
    team16Player8LastName: '',
    team16Player9FirstName: '',
    team16Player9LastName: '',
    team16Player10FirstName: '',
    team16Player10LastName: '',
    team16Player11FirstName: '',
    team16Player11LastName: '',
    team16Player12FirstName: '',
    team16Player12LastName: '',
    team16Player13FirstName: '',
    team16Player13LastName: '',
    team16Player14FirstName: '',
    team16Player14LastName: '',
    team16Player15FirstName: '',
    team16Player15LastName: '',
    team16Player16FirstName: '',
    team16Player16LastName: '',
    team16Player17FirstName: '',
    team16Player17LastName: '',
    team16Player18FirstName: '',
    team16Player18LastName: '',
    team16Player19FirstName: '',
    team16Player19LastName: '',
    team16Player20FirstName: '',
    team16Player20LastName: '',


  },
  reducers: {
    updateTeam16PlayerInfo: (state, action) => {
      state = action.payload
    },
    updateTeam16Player1FirstName: (state, action) => {
      state.team16Player1FirstName = action.payload
    },
    updateTeam16Player1LastName: (state, action) => {
      state.team16Player1LastName = action.payload
    },
    updateTeam16Player2FirstName: (state, action) => {
      state.team16Player2FirstName = action.payload
    },
    updateTeam16Player2LastName: (state, action) => {
      state.team16Player2LastName = action.payload
    },
    updateTeam16Player3FirstName: (state, action) => {
      state.team16Player3FirstName = action.payload
    },
    updateTeam16Player3LastName: (state, action) => {
      state.team16Player3LastName = action.payload
    },
    updateTeam16Player4FirstName: (state, action) => {
      state.team16Player4FirstName = action.payload
    },
    updateTeam16Player4LastName: (state, action) => {
      state.team16Player4LastName = action.payload
    },
    updateTeam16Player5FirstName: (state, action) => {
      state.team16Player5FirstName = action.payload
    },
    updateTeam16Player5LastName: (state, action) => {
      state.team16Player5LastName = action.payload
    },
    updateTeam16Player6FirstName: (state, action) => {
      state.team16Player6FirstName = action.payload
    },
    updateTeam16Player6LastName: (state, action) => {
      state.team16Player6LastName = action.payload
    },
    updateTeam16Player7FirstName: (state, action) => {
      state.team16Player7FirstName = action.payload
    },
    updateTeam16Player7LastName: (state, action) => {
      state.team16Player7LastName = action.payload
    },
    updateTeam16Player8FirstName: (state, action) => {
      state.team16Player8FirstName = action.payload
    },
    updateTeam16Player8LastName: (state, action) => {
      state.team16Player8LastName = action.payload
    },
    updateTeam16Player9FirstName: (state, action) => {
      state.team16Player9FirstName = action.payload
    },
    updateTeam16Player9LastName: (state, action) => {
      state.team16Player9LastName = action.payload
    },
    updateTeam16Player10FirstName: (state, action) => {
      state.team16Player10FirstName = action.payload
    },
    updateTeam16Player10LastName: (state, action) => {
      state.team16Player10LastName = action.payload
    },
    updateTeam16Player11FirstName: (state, action) => {
      state.team16Player11FirstName = action.payload
    },
    updateTeam16Player11LastName: (state, action) => {
      state.team16Player11LastName = action.payload
    },
    updateTeam16Player12FirstName: (state, action) => {
      state.team16Player12FirstName = action.payload
    },
    updateTeam16Player12LastName: (state, action) => {
      state.team16Player12LastName = action.payload
    },
    updateTeam16Player13FirstName: (state, action) => {
      state.team16Player13FirstName = action.payload
    },
    updateTeam16Player13LastName: (state, action) => {
      state.team16Player13LastName = action.payload
    },
    updateTeam16Player14FirstName: (state, action) => {
      state.team16Player14FirstName = action.payload
    },
    updateTeam16Player14LastName: (state, action) => {
      state.team16Player14LastName = action.payload
    },
    updateTeam16Player15FirstName: (state, action) => {
      state.team16Player15FirstName = action.payload
    },
    updateTeam16Player15LastName: (state, action) => {
      state.team16Player15LastName = action.payload
    },
    updateTeam16Player16FirstName: (state, action) => {
      state.team16Player16FirstName = action.payload
    },
    updateTeam16Player16LastName: (state, action) => {
      state.team16Player16LastName = action.payload
    },

  }
})

export const {
  updateTeam16Team1PlayerInfo,
  updateTeam16Player1FirstName,
  updateTeam16Player1LastName,
  updateTeam16Player2FirstName,
  updateTeam16Player2LastName,
  updateTeam16Player3FirstName,
  updateTeam16Player3LastName,
  updateTeam16Player4FirstName,
  updateTeam16Player4LastName,
  updateTeam16Player5FirstName,
  updateTeam16Player5LastName,
  updateTeam16Player6FirstName,
  updateTeam16Player6LastName,
  updateTeam16Player7FirstName,
  updateTeam16Player7LastName,
  updateTeam16Player8FirstName,
  updateTeam16Player8LastName,
  updateTeam16Player9FirstName,
  updateTeam16Player9LastName,
  updateTeam16Player10FirstName,
  updateTeam16Player10LastName,
  updateTeam16Player11FirstName,
  updateTeam16Player11LastName,
  updateTeam16Player12FirstName,
  updateTeam16Player12LastName,
  updateTeam16Player13FirstName,
  updateTeam16Player13LastName,
  updateTeam16Player14FirstName,
  updateTeam16Player14LastName,
  updateTeam16Player15FirstName,
  updateTeam16Player15LastName,
  updateTeam16Player16FirstName,
  updateTeam16Player16LastName,

} = team16PlayerInfoSlice.actions
export default team16PlayerInfoSlice.reducer
import React from "react";
import { Box, Container, Typography, Card, Button, Paper, } from '@mui/material';
import image from '../images/basketball-court.jpg';
import Tournaments from "./Tournaments";
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

const TournamentDetails = (props) => {

  const tournament = {
    season: 'Fall 2023',
    name: 'March Madness',
    dates: ['03/18/2023', '03/19/2023'],
    time: '8:00 AM',
    location: 'Tournament Location',
    prize: 'Tournament Prize',
    teams: 'Tournament Teams',
    players: 'Tournament Players',
    status: 'Tournament Status',
    registrationEndDate: '03/01/2023',
    registrationEndTime: '12:00 PM',
    registrationFee: 250,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    rules: 'Tournament Rules',
    contact: 'Tournament Contact',
    contactEmail: 'info@bigstagetournaments.com',
    contactPhone: '123-456-7890',

  }

  return (
    <Box sx={{ height: '100vh', width: '100%', }}>
      <Box sx={{ display: 'flex', height: '50%', width: '100%', justifyContent: 'center', alignItems: 'center', background: 'blue', backgroundImage: `url(${image})` }}>
        <Typography color='white' variant='h1'>{tournament.name} Details</Typography>
      </Box>
      <Container maxWidth='xl' sx={{ display: 'flex', height: 'fit-content', width: '100%', p: 4, }}>
        <Grid2 container spacing={4} sx={{ width: '100%', height: '100%', justifyContent: 'space-evenly', display: 'flex', }}>
          <Grid2 item xs={12} sm={12} md={4} lg={3} sx={{}}>
            <Paper sx={{ width: '100%', height: '100%', aspectRatio: '10/9', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} elevation={2}>
              <Typography align="center" variant='h3'>Dates: </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', }}>
                {tournament.dates.map((date) => <Typography sx={{ py: 2, }} align="center" variant='h5'>{date}</Typography>)}
              </Box>
            </Paper>
          </Grid2>
          <Grid2 item xs={12} sm={12} md={4} lg={3} sx={{}}>
            <Paper sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }} elevation={2}>
              <Typography align="center" variant='h3'>Time: </Typography>
              <Typography sx={{ py: 2, }} align="center" variant='h5'>{tournament.time}</Typography>
            </Paper>
          </Grid2>

        </Grid2>

      </Container>

    </Box>
  )
}


export default TournamentDetails;